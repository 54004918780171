<div class="gallery">
    <div class="post-thumbs">
      <app-post-thumb-detail
        class="post-thumb"
        *ngFor="let post of posts$ | async"
        [post]="post"
        (selected)="postSelected($event)">
      </app-post-thumb-detail>
    </div>

</div>
<router-outlet></router-outlet>
