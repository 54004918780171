import { flatMap, map, mergeMap, switchMap, take, tap, filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Email } from '../model/email';
import { Observable } from 'rxjs';
import { ActivityService } from 'app/admin/services/activity.service';
import { LoginService } from 'app/shared/services/login.service';


@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  signature = `
<br/><br/>
-----
<p style="margin: 0px; padding: 0px;">Gallery Help Email            &emsp;|&emsp; <a href="mailto:gallery@pixiteapps.com">gallery@pixiteapps.com</a></p>
<p style="margin: 0px; padding: 0px;">Community Rules               &emsp;|&emsp; <a href="http://pixiteapps.com/pigment-gallery-community-rules/">http://pixiteapps.com/pigment-gallery-community-rules/</a></p>
<p style="margin: 0px; padding: 0px;">Guides & Tutorials            &emsp;|&emsp; <a href="http://pixiteapps.com/blog/category/pigment/">http://pixiteapps.com/blog/category/pigment/</a></p>
<p style="margin: 0px; padding: 0px;">Terms of Use & Copyright      &emsp;|&emsp; <a href="http://pixiteapps.com/terms-of-use/">http://pixiteapps.com/terms-of-use/</a></p>
  `

  constructor(
    private afs: AngularFirestore,
    private loginService: LoginService,
    private activityService: ActivityService,
  ) {}

  sendEmail(recipient_uid, subject, text): Observable<Object> {
    console.log('checking auth for email.');
    return this.loginService.user.pipe(
      tap(user => console.log('sending email on behalf of moderator:', user.user.displayName)),
      filter(user => !!user),
      take(1),
      map(user => ({
        recipient_uid: recipient_uid,
        subject: subject,
        text: text,
        sent_by: user.user.uid,
        sent_date: null,
        created_at: new Date()
      })),
      switchMap(email =>
        this.afs.collection('moderator-emails').add(email)
          .then(() => this.activityService.createActivity('email', email).toPromise())
          .then(() => email)
      )
    )
  }

  emailsSentTo(recipient_uid): Observable<Email[]> {
    return this.afs.collection('moderator-emails', ref => {
      let r = ref.where('recipient_uid', '==', recipient_uid)
        .orderBy('sent_date', 'asc');
      return r;
    }).snapshotChanges().pipe(
      map(items => items.map(this.mapToEmail)));
  }

  private mapToEmail = (item) => {

    let payload = item.payload.doc.data();
    let key = item.payload.doc.id;

    if (item == null || payload == null) {
      return null;
    }

    const email = new Email();
    email.$key = key;
    email.sent_date = payload.sent_date;
    email.subject = payload.subject;
    email.text = payload.text;
    email.sent_by = payload.sent_by;
    email.created_at = payload.created_at;

    return email;
  };

}
