
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ActivityService } from 'app/admin/services/activity.service';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Note } from '../model/note';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  
  constructor(
    private afs: AngularFirestore,
    private activityService: ActivityService,
  ) { }

  
  makeNote(subject_uid, text) {
    const note = {
      subject_uid: subject_uid,
      created_at: new Date(),
      text: text,
    }
    return this.afs.collection('moderator-notes').add(note).then(() => {
      return lastValueFrom(this.activityService.createActivity('create-note', note));
    })
  }

  updateNote(note, text) {
    const ref = this.afs.collection('moderator-notes').doc(note.$key);

    return ref.set({ updated_at: new Date(), text: text }, { merge: true})
      .then(() => lastValueFrom(ref.get()))
      .then(snapshot => {
        if (!!snapshot.data()['subject_uid']) {
          return lastValueFrom(this.activityService
            .createActivity('update-note', snapshot.data()));
        }
      })
  }

  noteAbout(subject_uid): Observable<Note> {
    return this.afs.collection('moderator-notes', ref => {
      let r = ref.where('subject_uid', '==', subject_uid)
                .orderBy('created_at', 'asc').limit(1);
      return r;
    }).snapshotChanges().pipe(
      map(items => this.mapToNote(items[0])));
  }

  private mapToNote = (item) => {

    if(item == null) {
      return null;
    }

    let payload = item.payload.doc.data();
    let key = item.payload.doc.id;

    if (item == null || payload == null) {
      return null;
    }

    const note = new Note();
    note.$key = key;
    note.subject_uid = payload.subject_uid;
    note.created_at = payload.created_at;
    note.updated_at = payload.updated_at;
    note.text = payload.text;

    return note;
  };
}
