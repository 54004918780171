import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { Flagged, FlaggedPostData, FlagReasonOptionList } from 'app/admin/model/flag';
import { FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { FlagsService } from 'app/admin/services/flags.service';
import { Comment } from 'app/shared/model/comment';
import { ProfileStub } from 'app/shared/model/profile';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FlagsListRequest } from '../flags.datasource';
import SortUtilities from 'app/shared/utils/sort-utils'

export enum FlaggedAction {
  ChangeFlag, View, Resolve, Contact, Delete
}

export class FlaggedActionEvent {
  constructor(
    public action: FlaggedAction,
    public flagged: Flagged
  ) {}
}

@Component({
  selector: 'admin-flags-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.4, 1)')),
    ]),
  ],
})
export class FlagsTableComponent implements OnInit, OnDestroy {
  @Output() flaggedAction = new EventEmitter<FlaggedActionEvent>()

  @Input() source: FirestoreDataSource<FlagsListRequest, Flagged>
  @Input() selection: SelectionModel<Flagged>
  @Input() columns = ['select', 'image', 'user', 'description', 'last_flagged', 'status', 'last_reason', 'flag_count', 'actions']
  @Input() sortColumns = ['user', 'last_flagged', 'status', 'last_reason', 'flag_count']

  @ViewChild('sort', { static: true }) sort: MatSort

  constructor(private flagsService: FlagsService) { }
  allFlagReasonsAsObject: FlagReasonOptionList[] = this.flagsService.getAllFlagReasonsForOptionList()
  flagReasons: FlagReasonOptionList[] = []

  canSortStatus = true
  expandedId: string | null = null

  private unsubscribe = new Subject<void>()

  ngOnInit(): void {
    this.source.sort = this.sort

    for (const [, sortable] of this.sort.sortables.entries()) {
      if (this.sortColumns.indexOf(sortable.id) === -1) {
        (sortable as MatSortHeader).disabled = true
      }
    }

    this.source.request$.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: req => {
        // Disable the status sort header if we're filtering by status.

        this.source.data$.forEach((item) => {
          let allReasonsAsObject = [...this.allFlagReasonsAsObject]

          item.forEach((detail) => {
            let isThisReasonInAllReasons: boolean = allReasonsAsObject.some(o => o.value === detail.lastReason)

            if (!isThisReasonInAllReasons) {
              allReasonsAsObject.push({ value: detail.lastReason, isActive: false })
            }
          })

          // Clear out flagReasons; otherwise, it retains values from previous
          // async calls and creates dupes in our dropdown list, which we do
          // not want.
          this.flagReasons = []
          allReasonsAsObject.forEach((item) => {
            this.flagReasons.push(item)
          })
          this.flagReasons.sort(SortUtilities.dynamicSort("value"))
        })
        this.canSortStatus = req.status === undefined

        if (!this.canSortStatus && this.sort.active === 'status') {
          this.sort.active = ''
          this.sort.direction = ''
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  pluckContentOwner(flagged: Flagged): ProfileStub | undefined {
    switch (flagged.type) {
      case 'post': return (flagged.data as FlaggedPostData).owner
      case 'comment': return (flagged.data as Comment).actor
    }
  }

  pluckContentImage(flagged: Flagged): string | undefined {
    switch (flagged.type) {
      case 'post': return (flagged.data as FlaggedPostData).image
      case 'comment': return (flagged.data as Comment).post.image
    }
  }

  pluckContentDescription(flagged: Flagged): string {
    switch (flagged.type) {
      case 'post': return (flagged.data as FlaggedPostData).description
      case 'comment': return (flagged.data as Comment).message
      default: return ''
    }
  }

  onChangeFlag(newValue, flagged: Flagged) {
    const current = new Date()
    flagged.lastReason = newValue
    flagged.lastFlagged = current
    this.flaggedAction.emit(new FlaggedActionEvent(FlaggedAction.ChangeFlag, flagged))
  }

  onView(event: MouseEvent, flagged: Flagged) {
    event.stopPropagation()
    this.flaggedAction.emit(new FlaggedActionEvent(FlaggedAction.View, flagged))
  }

  onResolve(event: MouseEvent, flagged: Flagged) {
    event.stopPropagation()
    this.flaggedAction.emit(new FlaggedActionEvent(FlaggedAction.Resolve, flagged))
  }

  onContact(event: MouseEvent, flagged: Flagged) {
    event.stopPropagation()
    this.flaggedAction.emit(new FlaggedActionEvent(FlaggedAction.Contact, flagged))
  }

  onDelete(event: MouseEvent, flagged: Flagged) {
    event.stopPropagation()
    this.flaggedAction.emit(new FlaggedActionEvent(FlaggedAction.Delete, flagged))
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.source.data.forEach(row => this.selection.select(row));
  }
}
