import { map, mergeMap, switchMap, filter } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {from, Observable, of} from 'rxjs';
import {User} from '../model/user';

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

// Login uses Custom Claims to verify that a user is an admin: https://firebase.google.com/docs/auth/admin/custom-claims

@Injectable()
export class LoginService {

  user: Observable<User>;
  isLoggedIn: Observable<boolean>;
  isAdmin: Observable<boolean>;

  private userClaims: Observable<object>

  constructor(auth: AngularFireAuth) {
    this.isAdmin = auth.authState.pipe(mergeMap(user => {
      if (user) {
        return from(user.getIdToken()).pipe(map(idToken => {
          // Parse the ID token.
          const payload = JSON.parse(b64DecodeUnicode(idToken.split('.')[1]));

          // Confirm the user is an Admin.
          if (payload['role'] === 'admin') {
            return true;
          } else {
            return false;
          }
        }))

      } else {
        return of(false);
      }
    }));

    this.isLoggedIn = auth.authState.pipe(map(user => {
      return user !== undefined;
    }));

    this.user = auth.user.pipe(
      switchMap(user => user ? this.isAdmin.pipe(map(isAdmin => new User(user, isAdmin))) : of(null))
    )

    this.userClaims = auth.idToken.pipe(
      map(token => token ? JSON.parse(b64DecodeUnicode(token.split(".")[1])) : {})
    )
  }

  hasPermission(name: string): Observable<boolean> {
    return this.userClaims.pipe(
      map(claims => claims["permissions"]),
      map(permissions => permissions && !!permissions[name])
    )
  }
}
