import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LinkedProfilesDialogComponent } from 'app/admin/components/linked-profiles-dialog/linked-profiles-dialog.component';
import { UserBlockedComponent } from 'app/admin/components/user-blocked/user-blocked.component';
import { UserBlockingComponent } from 'app/admin/components/user-blocking/user-blocking.component';
import { ProfileSettingsService } from 'app/admin/services/profile-settings.service';
import { LegacyProfile } from 'app/shared/model/profile';
import { CommentsService } from 'app/shared/services/comments.service';
import { LoginService } from 'app/shared/services/login.service';
import { PostsService } from 'app/shared/services/posts.service';
import { ProfilesService } from 'app/shared/services/profiles.service';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';

class ProfileStatus {
  userBlocked: boolean
  deviceBlocked: boolean
}

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit, OnDestroy {

  profile$: Observable<LegacyProfile>
  deviceId$: Observable<string>
  deletedCount$: Observable<number>
  commentCount$: Observable<number>
  blockedCount$: Observable<number>
  blockingCount$: Observable<number>
  linkedProfileCount$: Observable<number>
  profileStatus$: Observable<ProfileStatus>
  previousUsernames$: Observable<string[]>

  shouldDisplayEmail$: Observable<boolean>

  private unsubscribe$: Subject<void> = new Subject<void>()
  private userId$: BehaviorSubject<string> = new BehaviorSubject<string>(null)

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private loginService: LoginService,
    private postsService: PostsService,
    private profilesService: ProfilesService,
    private profileSettingsService: ProfileSettingsService,
    private commentsService: CommentsService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        map(p => p.get('userId'))
      )
      .subscribe(this.userId$)

    this.profile$ = this.userId$.pipe(
      switchMap(id => this.profilesService.findProfile(id)),
      shareReplay()
    )

    this.deviceId$ = this.userId$.pipe(
      switchMap(userId => this.profileSettingsService.getDeviceId(userId)),
      shareReplay(1)
    )

    this.deletedCount$ = this.userId$.pipe(
      switchMap(userId => this.postsService.getDeletedPostsCount(userId)),
      shareReplay(1)
    )

    this.commentCount$ = this.userId$.pipe(
      switchMap(userId => this.profilesService.getCommentCount(userId)),
      shareReplay(1)
    )

    this.blockedCount$ = this.userId$.pipe(
      switchMap(id => this.profilesService.getBlockedProfiles(id)),
      map(blocked => blocked?.length || 0),
      shareReplay()
    )

    this.blockingCount$ = this.userId$.pipe(
      switchMap(id => this.profilesService.getBlockingProfiles(id)),
      map(blocking => blocking?.length || 0),
      shareReplay()
    )

    this.linkedProfileCount$ = this.userId$.pipe(
      switchMap(id => this.profileSettingsService.getLinkedProfiles(id)),
      map(profiles => profiles?.length || 0),
      shareReplay()
    )

    this.previousUsernames$ = this.userId$.pipe(
      switchMap(id => this.profileSettingsService.getPreviousUsernamesObject(id)),
      map(obj => {
        const previous = obj || {}
        const usernames = []
        for (let [, value] of Object.entries(previous)) {
          if (usernames.indexOf(value) === -1) {
            usernames.push(value)
          }
        }
        return usernames
      })
    )

    this.shouldDisplayEmail$ = this.loginService.hasPermission("view_user_email")

    const isProfileEnabled$ = this.userId$.pipe(
      switchMap(userId => this.profilesService.getProfileAuthStatus(userId)),
      map(status => !status)
    )
    const isDeviceEnabled$ = this.userId$.pipe(
      switchMap(userId => this.profileSettingsService.getDeviceId(userId)),
      switchMap(deviceId => this.profileSettingsService.isDeviceEnabled(deviceId))
    )
    this.profileStatus$ = combineLatest([isProfileEnabled$, isDeviceEnabled$])
      .pipe(map(([profileEnabled, deviceEnabled]) => (
        {
          userBlocked: !profileEnabled,
          deviceBlocked: !deviceEnabled
        }
      )))
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  openUserComments() {
    this.profile$
      .pipe(take(1))
      .subscribe(profile => {
        if (!profile) { return }
        this.router.navigate(['comments'], {relativeTo: this.route})
      })
  }

  showBlockedUsers() {
    this.profile$
      .pipe(take(1))
      .subscribe(profile => {
        if (!profile) { return }
        this.dialog.open(UserBlockedComponent, {data: {profile: profile}})
      })
  }

  showBlockingUsers() {
    this.profile$
      .pipe(take(1))
      .subscribe(profile => {
        if (!profile) { return }
        this.dialog.open(UserBlockingComponent, {data: {profile: profile}})
      })
  }

  showLinkedProfiles() {
    this.userId$
      .pipe(take(1))
      .subscribe(id => {
        if (!id) { return }
        this.dialog.open(LinkedProfilesDialogComponent, {data: {userId: id}})
      })
  }
}
