import { Pipe, PipeTransform } from '@angular/core';

/*
 * Removes `_count` from stat name, and capitalize the first letter.
 */
@Pipe({
  name: 'statTitle'
})
export class StatTitlePipe implements PipeTransform {

  transform(value: string, args?: any): any {
    return value
      .substring(0, value.lastIndexOf('_'))
      .split('_')
      .map(part => part.charAt(0).toUpperCase() + part.substring(1))
      .join(' ');
  }

}
