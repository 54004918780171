import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FlagsDataSource, FlagsFilter } from 'app/admin/components/flags/flags.datasource';
import { Flagged, FlaggedPostData, FlagStatus } from 'app/admin/model/flag';
import { FlagsService } from 'app/admin/services/flags.service';
import { Comment } from 'app/shared/model/comment';
import { CommentsService } from 'app/shared/services/comments.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, takeUntil, tap } from 'rxjs/operators';
import { PostStub } from '../../../shared/model/post';
import { ProfileStub } from '../../../shared/model/profile';
import { magicImage } from '../../../shared/pipes/magic-image.pipe';
import { PostsService } from '../../../shared/services/posts.service';
import { EmailComposeComponent } from '../email-compose/email-compose.component';
import { FlaggedAction, FlaggedActionEvent, FlagsTableComponent } from './table/table.component';

@Component({
  selector: 'admin-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss'],
  providers: [FlagsDataSource]
})
export class FlagsComponent implements OnInit, OnDestroy {

  selection = new SelectionModel<Flagged>(true, [])
  resolvableSelectionCount = new BehaviorSubject<number>(0)
  deleteableSelectionCount = new BehaviorSubject<number>(0)

  @ViewChild('table', { static: true }) table: FlagsTableComponent

  filterOpen$: Observable<boolean>
  filterResolved$: Observable<boolean>
  filterDeleted$: Observable<boolean>
  filterNotDeleted$: Observable<boolean>

  private unsubscribe = new Subject<void>()

  constructor(
    private flagsService: FlagsService,
    private postsService: PostsService,
    private commentsService: CommentsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dataSource: FlagsDataSource,
  ) { }

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParamMap
    if (params.has('filter[status]') || params.has('filter[deleted]')) {
      const startFilter: FlagsFilter = {}
      if (params.has('filter[status]')) {
        startFilter.status = params.get('filter[status]') as FlagStatus
      }
      if (params.has('filter[deleted]')) {
        startFilter.deleted = params.get('filter[deleted]') === 'true'
      }
      this.dataSource.filter.next(startFilter)
    }

    this.selection.changed
      .pipe(
        takeUntil(this.unsubscribe),
        tap(selection => {
          const resolvable = selection.source.selected.filter(flagged => flagged.status !== 'resolved').length
          this.resolvableSelectionCount.next(resolvable)
        }),
        tap(selection => {
          const deleteable = selection.source.selected.filter(flagged => !flagged.deleted).length
          this.deleteableSelectionCount.next(deleteable)
        })
      )
      .subscribe()

    const filter = this.dataSource.filter.pipe(shareReplay(1))
    this.filterOpen$ = filter.pipe(map(f => f.status === 'open'))
    this.filterResolved$ = filter.pipe(map(f => f.status === 'resolved'))
    this.filterDeleted$ = filter.pipe(map(f => f.deleted === true))
    this.filterNotDeleted$ = filter.pipe(map(f => f.deleted === false))

    filter
      .pipe(
        takeUntil(this.unsubscribe),
        distinctUntilChanged((a, b) => a.deleted !== b.deleted || a.status !== b.status)
      )
      .subscribe({
        next: value => {
          this.router.navigate(
            [],
            {
              relativeTo: this.activatedRoute,
              replaceUrl: true,
              queryParams: {
                'filter[status]': value.status,
                'filter[deleted]': value.deleted,
              },
              queryParamsHandling: 'merge'
            }
          )
        }
      })
  }

  addFilter(filter: 'open' | 'resolved' | 'deleted' | 'not-deleted') {
    const current = this.dataSource.filter.value
    switch (filter) {
      case 'open':
      case 'resolved':
        current.status = filter
        break
      case 'deleted':
        current.deleted = true
        break
      case 'not-deleted':
        current.deleted = false
        break
    }
    this.dataSource.filter.next(current)
  }

  removeFilter(filter: 'open' | 'resolved' | 'deleted' | 'not-deleted') {
    const current = this.dataSource.filter.value
    switch (filter) {
      case 'open':
      case 'resolved':
        current.status = undefined
        break
      case 'deleted':
      case 'not-deleted':
        current.deleted = undefined
        break
    }
    this.dataSource.filter.next(current)
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  async resolveSelection() {
    const resolvable = this.selection.selected.filter(flagged => flagged.status !== 'resolved')
    if (confirm(`Are you sure you want to resolve ${resolvable.length} flagged items?`)) {
      await Promise.all(resolvable.map(flagged =>
        this.flagsService.resolveFlagged(flagged)
      ))
      this.selection.clear()
    }
  }

  async deleteSelection() {
    const deleteable = this.selection.selected.filter(flagged => !flagged.deleted)
    if (confirm(`Are you sure you want to delete ${deleteable.length} flagged items?`)) {
      await Promise.all(deleteable.map(flagged => {
        switch (flagged.type) {
          case 'post':
            return this.postsService.deletePost(flagged.data as PostStub)
          case 'comment':
            return this.commentsService.deleteComment(flagged.data.id)
        }
      }))
      this.selection.clear()
    }
  }

  onFlaggedAction(event: FlaggedActionEvent) {
    switch (event.action) {
      case FlaggedAction.ChangeFlag:
        this.flagsService.updateFlaggedReason(event.flagged)
        break
      case FlaggedAction.View:
        this.navigateToContent(event.flagged)
        break
      case FlaggedAction.Resolve:
        this.flagsService.resolveFlagged(event.flagged)
        break
      case FlaggedAction.Contact:
        this.contactFlaggedContentOwner(event.flagged)
        break
      case FlaggedAction.Delete:
        this.deleteFlagged(event.flagged)
        break
    }
  }

  private async navigateToContent(flagged: Flagged) {
    const pathComponents = ['/', 'admin', 'posts']
    const queryParams: Params = {}
    switch (flagged.type) {
      case 'post':
        pathComponents.push((flagged.data as PostStub).id)
        break
      case 'comment':
        pathComponents.push((flagged.data as Comment).post.id)
        pathComponents.push('comments')

        if (flagged.deleted) {
          queryParams.filter = 'deleted'
        }
        break
    }
    await this.router.navigate(pathComponents, { queryParams: queryParams })
  }

  private async deleteFlagged(flagged: Flagged): Promise<void> {
    const type = flagged.type
    const owner = type === 'post'
      ? (flagged.data as PostStub).owner?.username
      : (flagged.data as Comment).actor.username
    if (confirm(`Are you sure you want to delete ${owner || 'this user'}'s ${type}?`)) {
      await this.flagsService.deleteFlaggedContent(flagged)
    }
  }

  private async contactFlaggedContentOwner(flagged: Flagged): Promise<void> {
    const type = flagged.type
    let owner: ProfileStub
    let post: PostStub
    switch (type) {
      case 'post':
        post = flagged.data as FlaggedPostData
        owner = post.owner
        break

      case 'comment':
        const comment = flagged.data as Comment
        owner = comment.actor
        post = comment.post
        break

      default:
        console.error('Unknown flagged type:', type)
        return
    }

    const imageUrl = magicImage(post.image, 400)
    const postUrl = `https://app.pigmentapp.co/posts/${post.id}`
    const data = {
      profileId: owner.id,
      subject: `Pixite Gallery - Your ${type.substr(0, 1).toUpperCase()}${type.substr(1)}`,
      text: `
        <p>
          Hi ${owner.username},
        </p>
        <br/>
        <p>
        Regarding your ${type === 'post' ? 'post' : 'comment on this post'}:
        </p>
        <a href="${postUrl}">${postUrl}
          <p>
            <img src="${imageUrl}">
          </p>
        </a>
      `
    }
    EmailComposeComponent.openWindow(data)
  }
}
