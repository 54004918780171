import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatExpansionModule } from '@angular/material/expansion'
import { NgxChartsModule } from '@swimlane/ngx-charts'
import { ProfilesDataSource } from 'app/admin/components/profiles/profiles.datasource'
import { UserCommentsRouterComponent } from 'app/admin/components/user-comments/user-comments-router.component'
import { ConfigService } from 'app/admin/services/config.service'
import { FlagsService } from 'app/admin/services/flags.service'
import { ModerationService } from 'app/admin/services/moderation.service'
import { QuillModule } from 'ngx-quill'
import { SharedModule } from '../shared/shared.module'
import { AccountMenuComponent } from './account-menu.component'
import { AdminComponent } from './admin.component'
import { AdminRoutingModule } from './admin.routes'
import { ActivityComponent } from './components/activity/activity.component'
import { ActivityTableComponent } from './components/activity/table/table.component'
import { AvatarUsernameInlineComponent } from './components/avatar-username-inline/avatar-username-inline.component'
import { CommentsDataSource } from './components/comments/comments.datasource'
import { CommentsTableComponent } from './components/comments/table/table.component'
import { EmptyTableComponent } from './components/common/empty-table/empty-table.component'
import { PaginatorComponent } from './components/common/paginator/paginator.component'
import { TableToolbarComponent, WithEmptySelectionToolbarDirective, WithSelectionToolbarDirective } from './components/common/table-toolbar/table-toolbar.component'
import { LandingComponent } from './components/landing/landing.component'
import { EmailComposeComponent } from './components/email-compose/email-compose.component'
import { EmailsComponent } from './components/emails/emails.component'
import { ChildFlagsTableComponent } from './components/flags/child-flags-table/child-flags-table.component'
import { FlagsComponent } from './components/flags/flags.component'
import { FlagsTableComponent } from './components/flags/table/table.component'
import { LinkedProfilesDialogComponent } from './components/linked-profiles-dialog/linked-profiles-dialog.component'
import { LoginComponent } from './components/login/login.component'
import { NotesComponent } from './components/notes/notes.component'
import { PostActionsOverflowComponent } from './components/post-actions/post-actions-overflow/post-actions-overflow.component'
import { PostActionsComponent } from './components/post-actions/post-actions.component'
import { PostCommentsComponent } from './components/post-detail/comments/comments.component'
import { PostFlagsComponent } from './components/post-detail/flags/flags.component'
import { PostDetailComponent } from './components/post-detail/post-detail.component'
import { PostsComponent } from './components/posts/posts.component'
import { PostsDataSource } from './components/posts/posts.datasource'
import { PostsTableComponent } from './components/posts/table/table.component'
import { ProfileCommentsComponent } from './components/profile-detail/comments/comments.component'
import { EmailRevealComponent } from './components/profile-detail/email-reveal/email-reveal.component'
import { ManageProfileMenuComponent } from './components/profile-detail/manage-profile-menu/manage-profile-menu.component'
import { ProfilePostsComponent } from './components/profile-detail/posts/posts.component'
import { ProfileDetailComponent } from './components/profile-detail/profile-detail.component'
import { ProfilesComponent } from './components/profiles/profiles.component'
import { ProfilesTableComponent } from './components/profiles/table/table.component'
import { RecommendedComponent } from './components/recommended/recommended.component'
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component'
import { UserBlockedComponent } from './components/user-blocked/user-blocked.component'
import { UserBlockingComponent } from './components/user-blocking/user-blocking.component'
import { UserCommentComponent } from './components/user-comments/user-comment/user-comment.component'
import { UserCommentsComponent } from './components/user-comments/user-comments.component'
import { UsernameComponent } from './components/username/username.component'
import { StatTitlePipe } from './pipes/stat-title.pipe'
import { StripHtmlPipe } from './pipes/strip-html.pipe'
import { ReportsService } from './services/reports.service'
import { StatsService } from './services/stats.service';
import { PersistentTableStateDirective } from './directives/persistent-table-state.directive'

// note PostAdminComponent and ProfileAdminComponent are imported in the shared module
// because some of that code uses those. They shoudl still only be visible to admin users.

@NgModule({
  declarations: [
    AdminComponent,
    LoginComponent,
    AccountMenuComponent,
    UnauthorizedComponent,
    LandingComponent,
    ProfilesComponent,
    PostsComponent,
    StatTitlePipe,
    UserCommentsComponent,
    RecommendedComponent,
    UserBlockingComponent,
    UserBlockedComponent,
    EmailComposeComponent,
    EmailsComponent,
    NotesComponent,
    ProfileDetailComponent,
    ManageProfileMenuComponent,
    LinkedProfilesDialogComponent,
    UserCommentComponent,
    UserCommentsRouterComponent,
    EmailRevealComponent,
    PostsTableComponent,
    PostDetailComponent,
    PostActionsComponent,
    PostActionsOverflowComponent,
    PaginatorComponent,
    ProfilePostsComponent,
    ProfilesTableComponent,
    ProfileCommentsComponent,
    CommentsTableComponent,
    AvatarUsernameInlineComponent,
    PostCommentsComponent,
    FlagsComponent,
    PostFlagsComponent,
    TableToolbarComponent,
    WithEmptySelectionToolbarDirective,
    WithSelectionToolbarDirective,
    ActivityComponent,
    ActivityTableComponent,
    UsernameComponent,
    StripHtmlPipe,
    FlagsTableComponent,
    EmptyTableComponent,
    ChildFlagsTableComponent,
    PersistentTableStateDirective
  ],
  imports: [
    CommonModule,
    SharedModule.forRoot(),
    NgxChartsModule,
    AdminRoutingModule,
    QuillModule.forRoot(),
    MatExpansionModule,
  ],
  providers: [
    ReportsService,
    StatsService,
    FlagsService,
    ConfigService,
    ModerationService,
    PostsDataSource,
    ProfilesDataSource,
    CommentsDataSource,
  ],
  exports: [AdminRoutingModule],
})
export class AdminModule {}
