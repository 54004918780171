
<app-header-bar></app-header-bar>
<div *ngIf="profile$ | async as profile">
  <div class="profile-header">
    <img class="thumb" src="{{ profile.thumb }}" />
    <h1 class="username">{{ profile.username }}</h1>

    <button  *ngIf="validAdmin" mat-stroked-button class="admin-button" (click)="manageProfile(profile)">
      Manage User
    </button>

    <div class="info-tabs">
      <div class="tab">
        <p class="value">{{ profile.post_count || "0"}}</p>
        <p>Published</p>
      </div>
      <div class="tab">
        <p class="value">{{ profile.follower_count || "0"}}</p>
        <p>Followers</p>
      </div>
      <div class="tab">
        <p class="value">{{ profile.following_count || "0"}}</p>
        <p>Following</p>
      </div>
    </div>
  </div>

  <div class="post-thumbs" *ngIf="posts">
    <app-post-thumb-detail class="post-thumb" *ngFor="let post of posts" [post]="post" (selected)="postSelected($event)"></app-post-thumb-detail>
    <div class="moreButton" *ngIf="profile.post_count > posts.length">
      <a (click)="showMore()">View More <img src="/assets/img/chevron_down.png" /></a>
    </div>
  </div>


  <div *ngIf="deletedPosts$ | async as posts">
    <div class="deleted-posts" *ngIf="posts.length > 0">
      <h2>Deleted Posts:</h2>
      <div class="post-thumbs">
        <app-post-thumb-detail class="post-thumb" *ngFor="let post of posts" [post]="post" (selected)="postSelected($event)"></app-post-thumb-detail>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
