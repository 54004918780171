import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountersService {

  constructor(private firestore: AngularFirestore) { }

  getCount(
    name: string,
    baseRef: AngularFirestoreCollection = this.firestore.collection('counters')
  ): Observable<number> {
    return baseRef.doc(name).collection('shards')
      .snapshotChanges()
      .pipe(
        map(actions => {
          let total = 0
          actions.forEach(action => {
            total += action.payload.doc.data().count
          })
          return total
        })
      )
  }
}
