<table mat-table
  [dataSource]="source"
  matSort
  matSortActive="created_at"
  matSortDirection="desc"
  appPersistentTableState
  #sort="matSort">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef>Image</th>
    <td mat-cell *matCellDef="let post">
      <img *ngIf="post.image else placeholder" class="list-thumb" src="{{ post.image }}" />
      <ng-template #placeholder>
        <img class="list-thumb" src="../../../assets/img/profile_placeholder.jpg" />
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="owner.username">Username</th>
    <td mat-cell *matCellDef="let post">
      <a (click)="onUsernameClicked($event, post)">{{post.owner_username}} </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let post"> {{post.description}} 
      <span *ngIf="post.image_attribution">
        <br /><b>Attribution:</b> {{post.image_attribution}}
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at">Created</th>
    <td mat-cell *matCellDef="let post"> {{post.created_at | date: 'short'}} </td>
  </ng-container>

  <ng-container matColumnDef="comment_count">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="counts.comments">Comments</th>
    <td mat-cell *matCellDef="let post"> {{post.comment_count}} </td>
  </ng-container>

  <ng-container matColumnDef="like_count">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="counts.likes">Likes</th>
    <td mat-cell *matCellDef="let post"> {{post.like_count}} </td>
  </ng-container>

  <ng-container matColumnDef="popularity">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="counts.popularity">Popularity</th>
    <td mat-cell *matCellDef="let post"> {{post.popularity | number:'1.3-3'}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"
      class="post-row"
      (click)="onPostClicked($event, row)"></tr>
</table>
