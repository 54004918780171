import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { EmailComposeComponent } from 'app/admin/components/email-compose/email-compose.component';
import { FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { Comment } from 'app/shared/model/comment';
import { magicImage } from 'app/shared/pipes/magic-image.pipe';
import { ProfileStub } from '../../../../shared/model/profile';
import { onClick } from '../../../utils/selectable-click-handler';
import { CommentsRequest } from '../comments.datasource';

@Component({
  selector: 'app-admin-comments-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class CommentsTableComponent implements OnInit {
  @Output() commentClicked = new EventEmitter<Comment>()
  @Output() userIdClicked = new EventEmitter<string>()

  @Input() source: FirestoreDataSource<CommentsRequest, Comment>
  @Input() selection: SelectionModel<Comment>
  @Input() columns = ['select', 'post', 'actor', 'message', 'created_at', 'actions']
  @Input() sortColumns = ['actor.username', 'message', 'created_at']

  @ViewChild('sort', { static: true }) sort: MatSort

  constructor() { }

  ngOnInit(): void {
    this.source.sort = this.sort
    for (const [, sortable] of this.sort.sortables.entries()) {
      if (this.sortColumns.indexOf(sortable.id) === -1) {
        (sortable as MatSortHeader).disabled = true
      }
    }
  }

  onCommentClicked($event: MouseEvent, comment: Comment) {
    onClick($event, () => this.commentClicked.emit(comment))
  }

  onActorClicked($event: MouseEvent, actor: ProfileStub) {
    onClick($event, () => this.userIdClicked.emit(actor.id))
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.source.data.forEach(row => this.selection.select(row));
  }

  onEmailClicked(comment: Comment) {
    if (!comment.post || !comment.post.id || !comment.post.image) {
      console.error('Missing post details on comment.')
      return
    }

    EmailComposeComponent.openWindow({
      profile: comment.actor,
      subject: "Pigment Gallery - Your Comment",
      text: `
        Hi ${comment.actor.username},
        <br/>
        <br/>
        Regarding your comment on post:
        <br/>
        <a href="https://app.pigmentapp.co/posts/${comment.post.id}">
          https://app.pigmentapp.co/posts/${comment.post.id}
          <p>
            <img src="${magicImage(comment.post.image, 400)}">
          </p>
        </a>
        <br/>
        <br/>
        ${comment.message}
      `
    })
  }
}
