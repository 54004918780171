import { DocumentData } from '@angular/fire/compat/firestore';
import { Activity } from 'app/shared/model/activity';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Comment } from '../../shared/model/comment';
import { PostStub } from '../../shared/model/post';
import { Profile, ProfileStub } from '../../shared/model/profile';
import { Flag, Flagged, FlaggedPostData } from './flag';
import Timestamp = firebase.firestore.Timestamp
import FieldValue = firebase.firestore.FieldValue

const documentDataOf = (data: DocumentData): DocumentData => {
  const result: DocumentData = {}
  Object.entries(data)
    .filter(([_, value]) => value !== undefined && value !== null)
    .forEach(([key, value]) => {
      result[key] = value
    })
  return result
}

const check = <T, R>(value: T | undefined, func: (value: T) => R): R | undefined => {
  if (value !== undefined && value !== null) {
    return func(value)
  } else {
    return undefined
  }
}

export const profileStubToFirestore = (model: ProfileStub): DocumentData => {
  return documentDataOf({
    user_id: model.id,
    username: model.username,
    image: model.image,
    is_premium: model.premium
  })
}

export const profileStubFromFirestore = (data: DocumentData): ProfileStub => {
  const result: ProfileStub = {}
  check(data.user_id, id => result.id = id)
  check(data.username, username => result.username = username)
  check(data.image, image => result.image = image)
  check(data.is_premium, premium => result.premium = premium)
  return result
}

export const postStubToFirestore = (model: PostStub): DocumentData => {
  return documentDataOf({
    id: model.id,
    image: model.image,
    original_image: model.originalImage,
    owner: model.owner ? profileStubToFirestore(model.owner) : undefined
  })
}

export const postStubFromFirestore = (data: DocumentData): PostStub => {
  const result: PostStub = {}
  check(data.id, id => result.id = id)
  check(data.image, image => result.image = image)
  check(data.original_image, originalImage => result.originalImage = originalImage)
  check(data.owner, owner => result.owner = profileStubFromFirestore(owner))
  return result
}

/*
 * Activity
 */
const activityToFirestore = (model: Activity): DocumentData => {
  return documentDataOf({
    app: model.app,
    type: model.type,
    created_at: model.createdAt
      ? Timestamp.fromDate(model.createdAt)
      : FieldValue.serverTimestamp(),
    target_id: model.targetId,
    target_user_id: model.targetUserId,
    actor: profileStubToFirestore(model.actor),
    __source_id: model.sourceId,
    __migration: model.migration,
    deleted: model.deletedAt ? { date: Timestamp.fromDate(model.deletedAt) } : undefined
  })
}

const activityFromFirestore = (data: DocumentData): Activity => {
  const result: Partial<Activity> = {}
  check(data.id, id => result.id = id)
  result.app = data.app
  result.type = data.type
  result.actor = profileStubFromFirestore(data.actor || {})
  result.targetId = data.target_id
  result.targetUserId = data.target_user_id
  result.createdAt = (data.created_at as Timestamp).toDate()
  check<Timestamp, Date>(data.deleted?.date, timestamp => result.deletedAt = timestamp.toDate())
  check(data.__source_id, sourceId => result.sourceId = sourceId)
  check(data.__migration, migration => result.migration = migration)
  return Activity.create(result)
}

export const commentToFirestore = (model: Comment): DocumentData => {
  return documentDataOf({
    ...activityToFirestore(model),
    message: model.message,
    post_thumb_url: model.post.image
  })
}

export const commentFromFirestore = (data: DocumentData): Comment => {
  return Comment.create({
    ...activityFromFirestore(data),
    message: data.message,
    post: {
      id: data.target_id,
      image: data.post_thumb_url,
      owner: Profile.createStub({ id: data.target_user_id })
    }
  })
}

export const flaggedToFirestore = (model: Flagged): DocumentData => {
  let id: string, contentData: DocumentData
  switch (model.type) {
    case 'post': {
      const data = model.data as FlaggedPostData
      id = data.id
      contentData = (({ image, original_image, owner }) => ({image, original_image, owner}))(postStubToFirestore(data))
      contentData.description = data.description
      break
    }
    case 'comment': {
      const data = model.data as Comment
      id = data.id
      contentData = commentToFirestore(data)
      break
    }
  }

  return documentDataOf({
    content_type: model.type,
    status: model.status,
    last_flagged: Timestamp.fromDate(model.lastFlagged),
    last_reason: model.lastReason,
    flag_count: model.count,
    content_id: id,
    content_deleted: model.deleted,
    content_data: contentData,
  })
}

export const flaggedFromFirestore = (data: DocumentData, id: string): Flagged => {
  let contentData: FlaggedPostData | Comment
  switch (data.content_type) {
    case 'post':
      contentData = { ...postStubFromFirestore({ id: data.content_id, ...data.content_data }), description: data.content_data.description }
      break
    case 'comment':
      contentData = commentFromFirestore({ id: data.content_id, ...data.content_data })
      break
  }

  return {
    id: id,
    type: data.content_type,
    status: data.status,
    deleted: !!data.content_deleted,
    lastFlagged: data.last_flagged.toDate(),
    lastReason: data.last_reason,
    count: data.flag_count,
    data: contentData,
  }
}

export const flagToFirestore = (model: Flag): DocumentData => {
  return documentDataOf({
    content_id: model.contentId,
    reason: model.reason,
    status: model.status,
    timestamp: Timestamp.fromDate(model.timestamp),
    message: model.message,
    actor: profileStubToFirestore(model.actor)
  })
}

export const flagFromFirestore = (data: DocumentData, id: string): Flag => {
  return {
    id: id,
    contentId: data.content_id,
    reason: data.reason,
    message: data.message,
    status: data.status,
    timestamp: data.timestamp.toDate(),
    actor: data.actor ? profileStubFromFirestore(data.actor) : undefined
  }
}
