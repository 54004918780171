<div class="headerBar">
  <div class="innerContainer">
    <a [routerLink]="['/']">
      <img class="logo" src ="/assets/img/logo.png" />
      <img class="logoPhone" src ="/assets/img/pigment_logo_phone.png" />
    </a>

    <a href ="https://itunes.apple.com/us/app/pigment-only-true-coloring/id1062006344?ls=1&mt=8&pt=14691&ct=gallery_website&at=11l4HQ">
      <img class="badge ios" src="/assets/img/download_on_the_app_store.svg" />
    </a>

  </div>
</div>

<div class="headerBarSpacer"></div>
