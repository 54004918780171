import { Environment } from './environment.types'

export const environment: Environment = {
  production: false,
  name: 'stage',
  elasticSearchSuffix: '_staging',
  elasticSearchPagesSuffix: '_stage',
  siteDomain: 'stage.gallery.pigmentapp.co',
  gTagId: 'G-729C1E1QGW',
  firebase: {
    apiKey: 'AIzaSyBmy-rm6-p_cWKbon1yyMYj99TEfZDKjPE',
    authDomain: 'pigment-debug-e2ae7.firebaseapp.com',
    databaseURL: 'https://pigment-debug-e2ae7.firebaseio.com',
    projectId: 'pigment-debug-e2ae7',
    storageBucket: 'pigment-debug-e2ae7.appspot.com',
    messagingSenderId: '241707028156'
  }
};
