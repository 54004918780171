<div class="comment" [ngClass]="{selected: isSelected}">
  <div class='thumbColumn' (click)="userClicked()">
    <img class="profileImage list-thumb"
         src="{{ comment.owner_thumb | magicImage:50 }}"
         onError="this.src='/assets/img/profile_placeholder.jpg';"/>
  </div>
  <div class='messageColumn'>
    <a class="poster" (click)="userClicked()">
      {{ comment.owner_username }}
    </a>

    <a *ngIf="linkToPost" class="poster" href="/{{postID}}">
      {{ limitedComment }}  
    </a>

    <ng-container *ngIf="!linkToPost">
      <ng-container *ngIf="canPostLinks">
        <div style="white-space: pre-wrap" [innerHTML]="limitedComment"></div>
      </ng-container>

      <ng-container *ngIf="!canPostLinks">
        {{ limitedComment }}
      </ng-container>
    </ng-container>

    <a *ngIf="showMoreButton" (click)="expandComment()">... Read More</a>
  </div>
  <div class='adminMenu' *ngIf="showingAdmin">
    <mat-icon (click)="deleteComment()">delete_forever</mat-icon>
    <mat-icon class="email" (click)="sendUserEmail()">mail_outline</mat-icon>
  </div>
  <div style="clear: both"></div>
</div>
