import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Flag, Flagged, FlaggedPostData } from 'app/admin/model/flag';
import { ChildFlagsDataSource } from './child-flags.datasource';
import { FlagsService } from 'app/admin/services/flags.service';
import { ProfileStub } from 'app/shared/model/profile';
import { PostStub } from 'app/shared/model/post';
import { Comment } from '../../../../shared/model/comment';
import { magicImage } from 'app/shared/pipes/magic-image.pipe';
import { EmailComposeComponent } from '../../email-compose/email-compose.component';

@Component({
  selector: 'admin-child-flags-table',
  templateUrl: './child-flags-table.component.html',
  styleUrls: ['./child-flags-table.component.scss'],
  providers: [ChildFlagsDataSource]
})
export class ChildFlagsTableComponent implements OnInit {
  @Input() selection?: SelectionModel<Flag>
  @Input() flaggedId: string
  @Input() columns = ['icon', 'user', 'date', 'status', 'reason', 'actions']

  constructor(
    private flagsService: FlagsService,
    public dataSource: ChildFlagsDataSource
  ) { }

  ngOnInit(): void {
    this.dataSource.flaggedId.next(this.flaggedId)
  }

  onResolve(event: MouseEvent, flag: Flag) {
    event.stopPropagation()
    this.flagsService.resolveFlag(flag)
  }

  async onContact(event: MouseEvent, flag: Flag) {
    event.stopPropagation()
    const flagged = await this.flagsService.getFlaggedForFlag(flag)
    if (!flagged) {
      alert(`Failed to find flagged content with id '${flag.contentId}' for flag: ${flag.id}`)
      return
    }

    const type = flagged.type
    const actor: ProfileStub = flag.actor
    let post: PostStub
    switch (type) {
      case 'post':
        post = flagged.data as FlaggedPostData
        break

      case 'comment':
        const comment = flagged.data as Comment
        post = comment.post
        break

      default:
        console.error('Unknown flagged type:', type)
        return
    }

    const imageUrl = magicImage(post.image, 400)
    const postUrl = `https://app.pigmentapp.co/posts/${post.id}`
    const data = {
      profileId: actor.id,
      subject: `Pixite Gallery - Your ${type.substr(0, 1).toUpperCase()}${type.substr(1)} flag`,
      text: `
        <p>
          Hi ${actor.username},
        </p>
        <br/>
        <p>
        Regarding ${type === 'post' ? 'this post' : 'the comment on this post'} that you flagged:
        </p>
        <a href="${postUrl}">${postUrl}
          <p>
            <img src="${imageUrl}">
          </p>
        </a>
      `
    }
    EmailComposeComponent.openWindow(data)
  }

  onDelete(event: MouseEvent, flag: Flag) {
    event.stopPropagation()
    if (confirm(`Are you sure you want to delete ${flag.actor?.username || 'this user'}'s flag?`)) {
      this.flagsService.deleteFlag(flag)
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    if (!this.selection) {
      return false
    }

    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (!this.selection) {
      return
    }

    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }
}
