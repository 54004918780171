import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { applyFirestoreRequest, FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { LegacyPost } from 'app/shared/model/post';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { StatsService } from '../../services/stats.service';
import { PostsListRequest } from './posts.types';

const mapPost = (item: DocumentChangeAction<unknown>) => {
  const post = new LegacyPost()
  post.$key = item.payload.doc.id
  const data: any = item.payload.doc.data()
  if (data) {
    post.created_at = data.created_at;
    post.owner_id = data.owner.user_id;
    post.owner_username = data.owner?.username;
    post.owner_thumb = data.owner?.thumb;
    post.image = data.image;
    post.image_attribution = data.metadata?.image_attribution;
    post.original_image = data.original_image;
    post.description = data.description;
    
    post.like_count = data.counts?.likes || 0
    post.comment_count = data.counts?.comments || 0
    post.popularity = data.counts?.popularity || 0

    if (data.deleted) {
      post.deleted_date = data.deleted.date
      post.deleted_user = data.deleted.user
    }
  }
  return post
}

const mapPosts = (items: DocumentChangeAction<unknown>[]) => items.map(mapPost)

@Injectable()
export class PostsDataSource extends FirestoreDataSource<PostsListRequest, LegacyPost> {

  deleted$ = new BehaviorSubject<boolean>(false)
  userId$ = new BehaviorSubject<string>(undefined)

  public set userId(v: string) {
    this.userId$.next(v);
  }

  public set deleted(v: boolean) {
    this.deleted$.next(v)
  }

  constructor(
    private firestore: AngularFirestore,
    private profilesService: ProfilesService,
    private statsService: StatsService
  ) {
    super()
  }

  defaultArgs(): Observable<PostsListRequest> {
    return combineLatest([this.userId$, this.deleted$]).pipe(
      map(([userId, deleted]) => ({
        userId: userId,
        deleted: deleted,
        orderBy: this.sort?.active || 'created_at',
        orderDir: this.sort?.direction || 'desc',
      }))
    )
  }

  mapResponse(response: DocumentChangeAction<unknown>[]): LegacyPost[] {
    return mapPosts(response)
  }

  executeQuery(request: PostsListRequest): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(
      request.deleted ? 'deleted_posts' : 'posts',
      ref => {
        const query = request.userId ? ref.where('owner.user_id', '==', request.userId) : ref
        return applyFirestoreRequest(query, request)
      }
    ).snapshotChanges()
  }

  totalItems(): Observable<number> {
    return combineLatest([this.userId$, this.deleted$]).pipe(
      switchMap(([userId, deleted]) => {
        if (deleted) {
          return this.firestore.collection('deleted_posts', ref =>
            userId ? ref.where('owner.user_id', '==', userId) : ref
          ).snapshotChanges().pipe(map(docs => docs.length))
        } else if (userId) {
          return this.profilesService.findProfile(userId)
            .pipe(map(profile => profile?.post_count !== undefined ? profile.post_count : -1))
        } else {
          return this.statsService.getStats()
            .pipe(map(stats => stats?.posts_count !== undefined ? stats.posts_count : -1))
        }
      })
    )
  }
}
