import {Component, Input} from '@angular/core';
import {LegacyComment} from 'app/shared/model/comment';
import {CommentsService} from 'app/shared/services/comments.service';
import {first} from 'rxjs/operators';
import {magicImage} from 'app/shared/pipes/magic-image.pipe';
import {EmailComposeComponent} from 'app/admin/components/email-compose/email-compose.component';
import {PostsService} from 'app/shared/services/posts.service';

@Component({
  selector: 'app-user-comment',
  templateUrl: './user-comment.component.html',
  styleUrls: ['./user-comment.component.scss']
})
export class UserCommentComponent {

  @Input() comment: LegacyComment
  @Input() deleted: boolean

  constructor(private commentsService: CommentsService, private postsService: PostsService) {
  }

  deleteComment() {
    if (confirm("Are you sure you want to delete this comment?")) {
      this.commentsService.deleteLegacyComment(this.comment.postID, this.comment.$key)
    }
  }

  sendUserEmail() {
    const postId = this.comment.postID
    if (!postId) return

    this.postsService.findPost(postId)
      .pipe(first())
      .subscribe(post => {
        EmailComposeComponent.openWindow({
          profileId: this.comment.owner_user_id,
          subject: "Pigment Gallery - Your Comment",
          text: `
          Hi ${this.comment.owner_username},
          <br/>
          <br/>
          Regarding your comment on post:
          <br/>
          <a href="https://app.pigmentapp.co/posts/${post.$key}">
            https://app.pigmentapp.co/posts/${post.$key}
            <p>
              <img src="${magicImage(post.image, 400)}">
            </p>
          </a>
          <br/>
          <br/>
          ${this.comment.message}
          `
        });
      })
  }
}
