import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegacyPost } from '../../model/post';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { PostsService } from '../../services/posts.service';

@Component({
  selector: 'app-post-detail-page',
  templateUrl: './post-detail-page.component.html',
  styleUrls: ['./post-detail-page.component.scss']
})
export class PostDetailPageComponent implements OnInit {

  post$: Observable<LegacyPost>;
  relatedPosts$: Observable<LegacyPost[]>;
  popularPosts$: Observable<LegacyPost[]>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private postSvc: PostsService
  ) {
  }

  ngOnInit() {

    this.popularPosts$ = this.postSvc
      .getPosts(20, 'popularity', null)
      .pipe(
        map(items => items.filter(post => post.image != null)),
        shareReplay()
      );

    this.post$ = this.route.paramMap
      .pipe(
        map(params => params.get('postId')),
        distinctUntilChanged(),
        switchMap(id => of(this.route.snapshot.data['postCommentsData']['post']))
      );

    this.relatedPosts$ = this.post$
      .pipe(
        filter(p => !!p?.page_id),
        switchMap(post =>
          this.postSvc.getPostsByPageID(post.page_id, 12)
            .pipe(
              map(related =>
                related
                  .filter(p => p.$key !== post.$key && p.image != null)
                  .slice(0, 5)
              )
            )
        ),
        shareReplay()
      )
  }

  postSelected(post: LegacyPost) {
    this.postSvc.currentDialogPost = post;
    this.router.navigate(["/posts", post.$key]);
  }
}
