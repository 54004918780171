<table mat-table
  [dataSource]="source"
  matSort
  matSortActive="timestamp"
  matSortDirection="desc"
  appPersistentTableState
  #sort="matSort">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="timestamp">Date</th>
    <td mat-cell *matCellDef="let activity"> {{ activity.timestamp | date: 'short' }} </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let activity"><app-avatar-username-inline [user]="activity.actor" size="small"></app-avatar-username-inline></td>
  </ng-container>

  <ng-container matColumnDef="type">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="type">Type</th>
    <td mat-cell *matCellDef="let activity">
      <div [ngSwitch]="activity.type">
        <div *ngSwitchCase="'email'">Email</div>
        <div *ngSwitchCase="'comment'">Comment</div>
        <div *ngSwitchCase="'update-note'">Note Updated</div>
        <div *ngSwitchCase="'create-note'">Note Created</div>
        <div *ngSwitchCase="'disable-user'">Disabled User</div>
        <div *ngSwitchCase="'enable-user'">Enabled User</div>
        <div *ngSwitchCase="'disable-device'">Disabled Device</div>
        <div *ngSwitchCase="'enable-device'">Enabled Device</div>
        <div *ngSwitchCase="'resolve-flagged-item'">Resolved Flagged Content</div>
        <div *ngSwitchCase="'update-flagged-item'">Updated Flagged Content</div>
        <div *ngSwitchCase="'delete-flagged-item'">Deleted Flagged Content</div>
        <div *ngSwitchCase="'resolve-flag'">Resolved Flag</div>
        <div *ngSwitchCase="'delete-flag'">Deleted Flag</div>
        <div *ngSwitchDefault>{{ activity.type }}</div>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let activity">
      <div [ngSwitch]="activity.type">
        <div *ngSwitchCase="'email'">
          Sent to <app-username [userId]="activity.data.recipient_uid"></app-username>: {{ activity.data.subject }}
        </div>
        <div *ngSwitchCase="'comment'">
          On <app-username [userId]="activity.data.post_owner_user_id"></app-username>'s <a [routerLink]="['/', 'admin', 'posts', activity.data.post_id]">post</a>: {{ activity.data.message }}
        </div>
        <div *ngSwitchCase="'update-note'">
          On <app-username [userId]="activity.data.subject_uid"></app-username>: {{ activity.data.text | stripHtml }}
        </div>
        <div *ngSwitchCase="'create-note'">
          On <app-username [userId]="activity.data.subject_uid"></app-username>: {{ activity.data.text | stripHtml }}
        </div>
        <div *ngSwitchCase="'disable-user'">
          Disabled <app-username [userId]="activity.data.user_id"></app-username>
        </div>
        <div *ngSwitchCase="'enable-user'">
          Enabled <app-username [userId]="activity.data.user_id"></app-username>
        </div>
        <div *ngSwitchCase="'disable-device'">
          Disabled <app-username [userId]="activity.data.user_id"></app-username>'s device
        </div>
        <div *ngSwitchCase="'enable-device'">
          Enabled <app-username [userId]="activity.data.user_id"></app-username>'s device
        </div>
        <div *ngSwitchCase="'resolve-flagged-item'">
          {{ activity.data.flag_count ? activity.data.flag_count : 'All' }} flags for
          <span [ngSwitch]="activity.data.type || ''">
            <a *ngSwitchCase="'post'" [routerLink]="['/', 'admin', 'posts', activity.data.content_id]">Post</a>
            <a *ngSwitchCase="'comment'" [routerLink]="['/', 'admin', 'posts', activity.data.post_id, 'comments']">Comment</a>
            <span *ngSwitchDefault>target item: {{ activity.data.content_id }}</span>
          </span>
        </div>
        <div *ngSwitchCase="'update-flagged-item'">
          Updated flag for
          <span [ngSwitch]="activity.data.type || ''">
            <a *ngSwitchCase="'post'" [routerLink]="['/', 'admin', 'posts', activity.data.content_id, 'flags']">Post</a>
            <a *ngSwitchCase="'comment'" [routerLink]="['/', 'admin', 'posts', activity.data.post_id, 'comments']">Comment</a>
            <span *ngSwitchDefault>target item: {{ activity.data.content_id }}</span>
          </span>
        </div>
        <div *ngSwitchCase="'delete-flagged-item'">
          {{ activity.data.flag_count ? activity.data.flag_count : 'All' }} flags for
          <span [ngSwitch]="activity.data.type || ''">
            <a *ngSwitchCase="'post'" [routerLink]="['/', 'admin', 'posts', activity.data.content_id]">Post</a>
            <a *ngSwitchCase="'comment'" [routerLink]="['/', 'admin', 'posts', activity.data.post_id, 'comments']">Comment</a>
            <span *ngSwitchDefault>target item: {{ activity.data.content_id }}</span>
          </span>
        </div>
        <div *ngSwitchCase="'resolve-flag'">
          <app-username
            *ngIf="activity.data.actor?.id; else anonUser"
            [userId]="activity.data.actor?.id"></app-username>'s flag on
          <app-username [userId]="activity.data.content_owner?.id"></app-username>'s
          <span [ngSwitch]="activity.data.type || ''">
            <a *ngSwitchCase="'post'" [routerLink]="['/', 'admin', 'posts', activity.data.content_id]">post</a>
            <a *ngSwitchCase="'comment'" [routerLink]="['/', 'admin', 'posts', activity.data.post_id, 'comments']">comment</a>
            <span *ngSwitchDefault>target item: {{ activity.data.content_id }}</span>
          </span>
          <ng-template #anonUser>An anonymous user</ng-template>
        </div>
        <div *ngSwitchCase="'delete-flag'">
          <app-username
            *ngIf="activity.data.actor.id; else anonUser"
            [userId]="activity.data.actor?.id"></app-username>'s flag on
          <app-username [userId]="activity.data.content_owner?.id"></app-username>'s
          <span [ngSwitch]="activity.data.type || ''">
            <a *ngSwitchCase="'post'" [routerLink]="['/', 'admin', 'posts', activity.data.content_id]">post</a>
            <a *ngSwitchCase="'comment'" [routerLink]="['/', 'admin', 'posts', activity.data.post_id, 'comments']">comment</a>
            <span *ngSwitchDefault>target item: {{ activity.data.content_id }}</span>
          </span>
          <ng-template #anonUser>An anonymous user</ng-template>
        </div>
        <div *ngSwitchDefault>{{ activity.data || json }}</div>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" class="activity-row"></tr>
</table>
