import { Component, Input, OnInit } from '@angular/core';
import { ProfileStub } from '../../../shared/model/profile';

@Component({
  selector: 'app-avatar-username-inline',
  templateUrl: './avatar-username-inline.component.html',
  styleUrls: ['./avatar-username-inline.component.scss']
})
export class AvatarUsernameInlineComponent implements OnInit {
  @Input() user: ProfileStub
  @Input() size: 'normal' | 'small' = 'normal'

  constructor() { }

  ngOnInit(): void {
  }

}
