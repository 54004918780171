import {first, switchMap} from 'rxjs/operators';
import {Component, OnDestroy} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {LegacyPost} from '../../model/post';
import {PostsService} from '../../services/posts.service';
import {of, Subscription} from 'rxjs';
import {PostDetailDialogComponent} from './post-detail-dialog.component';

@Component({
  selector: 'app-post-dialog-router',
  template: '',
  styles: ['']
})
export class PostDialogRouterComponent implements OnDestroy {

  private closeSubscription?: Subscription
  private dialogRef?: MatDialogRef<PostDetailDialogComponent>;

  constructor(
    public router: Router,
    route: ActivatedRoute,
    public location: Location,
    public dialog: MatDialog,
    postsService: PostsService
  ) {
    let post = postsService.currentDialogPost
    if (post) {
      this.openPostDialog(post)
      return
    }

    const postId = route.snapshot.params['postId']
    if (postId && postId != 'undefined')
    {
      postsService.findPost(postId).pipe(
        // If post is null then maybe this is a deleted post
        switchMap(post => post ? of(post) : postsService.findDeletedPost(postId)),
        first()
      ).subscribe(post => {
        if (!post) {
          console.log('No post found for id', postId)
          this.location.back()
          return
        }
        this.openPostDialog(post)
      })
    }
  }

  ngOnDestroy() {
    this.closeSubscription?.unsubscribe()
    this.dialogRef?.close()
  }

  openPostDialog(post: LegacyPost) {
    this.dialogRef = this.dialog.open(PostDetailDialogComponent, {
      data: {
        post: post,
        showingAdmin: false
      }
    });

    const route = this.router.routerState.snapshot.url
    this.closeSubscription = this.dialogRef.afterClosed()
      .subscribe(() => {
        if (this.router.isActive(route, true)) {
          this.location.back()
        }
      });
  }
}
