import { Injectable, NgZone } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivityService } from 'app/admin/services/activity.service';
import { LegacyComment } from 'app/shared/model/comment';
import { ZoneScheduler } from 'app/shared/services/ZoneScheduler';
import { Observable, SchedulerLike } from 'rxjs';
import { map, observeOn, switchMap } from 'rxjs/operators';
import { PostsService } from '../../shared/services/posts.service';

@Injectable({
  providedIn: 'root'
})
export class ModerationService {

  private readonly inAngularScheduler: SchedulerLike

  constructor(
    private functions: AngularFireFunctions,
    private postsService: PostsService,
    private activityService: ActivityService,
    zone: NgZone
  ) {
    this.inAngularScheduler = new ZoneScheduler(zone)
  }

  createComment(postId: string, message: string): Observable<LegacyComment> {
    const func = this.functions.httpsCallable("commentAsModerator")
    return func({postId: postId, message: message}).pipe(
      switchMap(comment =>
        this.postsService.findPost(postId).pipe(
          map(post => ({
            post_owner_user_id: post.owner_id,
            post_id: postId,
            ...comment
          })),
          switchMap(activityData => this.activityService.createActivity('comment', activityData)),
          map(() => comment)
        )
      ),
      map(result => {
        const comment = new LegacyComment()
        comment.$key = result.id
        comment.postID = postId
        comment.message = result.message
        comment.created_at = result.created_at
        comment.owner_user_id = result.owner.user_id
        comment.owner_username = result.owner.username
        comment.owner_thumb = result.owner.thumb
        return comment
      }),
      observeOn(this.inAngularScheduler)
    )
  }
}
