import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { GtagModule } from 'angular-gtag';
import { environment } from 'environments/environment';
import { ENV, getEnv } from 'environments/environment.provider';
import { AdminModule } from './admin/admin.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';

const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.siteDomain // it is mandatory to set a domain (any domain!), for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  "position": "bottom",
  "theme": "block",
  "palette": {
    "popup": {
      "background": "#f0f0f0",
      "text": "#57504d",
      "link": "#000000"
    },
    "button": {
      "background": "#6166d6",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "opt-in",
  "content": {
    "message": "We use cookies to analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking accept you consent to store on your device the cookies described in our",
    "allow": "Accept",
    "dismiss": "Got it!",
    "deny": "Reject",
    "link": "Privacy Policy",
    "href": "https://www.pixiteapps.com/privacy-policy/",
    "policy": "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    CommonModule,
    AdminModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    GtagModule.forRoot({ trackingId: environment.gTagId, trackPageviews: false })
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: ENV, useFactory: getEnv },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9099] : undefined },
    { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9000] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
  ]
})
export class AppModule { }
