import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData, Query } from '@angular/fire/compat/firestore';
import { flaggedFromFirestore } from 'app/admin/model/firestore.converters';
import { Flagged, FlagStatus } from 'app/admin/model/flag';
import { FlagsService } from 'app/admin/services/flags.service';
import { applyFirestoreRequest, FirestoreDataSource, FirestoreRequest } from 'app/admin/utils/firestore-data-source';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export interface FlagsListRequest extends FirestoreRequest {
  // filters
  contentId?: string

  // Can select to filter only deleted, resolved, or open flagged content
  status?: FlagStatus
  deleted?: boolean
}

export interface FlagsFilter {
  status?: FlagStatus
  // true: only deleted items, false: only not deleted items, undefined: no filter
  deleted?: boolean
}

@Injectable()
export class FlagsDataSource extends FirestoreDataSource<FlagsListRequest, Flagged> {

  filter = new BehaviorSubject<FlagsFilter>({})

  constructor(
    private firestore: AngularFirestore,
    private flagsService: FlagsService,
  ) {
    super()
    this.isFollowing = true
  }

  defaultArgs(): Observable<FlagsListRequest> {
    return of({
      limit: 5
    })
  }

  mapRequest(request: FlagsListRequest): Observable<FlagsListRequest> {
    return this.filter.pipe(
      map(({status, deleted}) => {
        request.status = status
        request.deleted = deleted
        if (status && request.orderBy === 'status') {
          request.orderBy = undefined
          request.orderDir = undefined
        }
        return request
      })
    )
  }

  executeQuery(request: FlagsListRequest): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(
      'flags', ref => {
        let query: Query<DocumentData> = ref
        if (request.status !== undefined && request.status !== null) {
          query = query.where('status', '==', request.status)
        }
        if (request.deleted !== undefined && request.deleted !== null) {
          query = query.where('content_deleted', '==', request.deleted)
        }
        return applyFirestoreRequest(query, request)
      }
    ).snapshotChanges()
  }

  mapResponse(response: DocumentChangeAction<unknown>[]): Flagged[] {
    return response.map(action => flaggedFromFirestore(action.payload.doc.data(), action.payload.doc.id))
  }

  totalItems(): Observable<number> {
    return this.flagsService.getFlaggedCount()
  }
}
