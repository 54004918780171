import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Comment } from 'app/shared/model/comment';
import { CommentsService } from 'app/shared/services/comments.service';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { CommentsDataSource } from '../../comments/comments.datasource';
import { PaginatorComponent } from '../../common/paginator/paginator.component';

const Params = {
  filter: 'filter'
}

enum Filter {
  PUBLISHED = 'published',
  DELETED = 'deleted'
}

@Component({
  selector: 'app-admin-profile-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  providers: [CommentsDataSource]
})
export class ProfileCommentsComponent implements OnInit, OnDestroy {

  Filter = Filter

  filter$ = new BehaviorSubject<Filter>(Filter.PUBLISHED)
  sortColumns = ['actor.username', 'message', 'created_at']
  selection = new SelectionModel<Comment>(true, [])
  canDeleteSelection: boolean
  canRestoreSelection: boolean

  private unsubscribe$ = new Subject<void>()

  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commentsService: CommentsService,
    public dataSource: CommentsDataSource,
    private cd: ChangeDetectorRef,
    private scroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.get(Params.filter) === Filter.DELETED) {
      this.filter$.next(Filter.DELETED)
    }

    this.paginator.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.scroller.scrollToAnchor('table')
    })

    this.route.parent.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        map(params => params.get('userId'))
      )
      .subscribe(this.dataSource.userId$)

    this.filter$
    .pipe(
      takeUntil(this.unsubscribe$),
      tap(value => {
        const queryParams: object = { ...this.route.snapshot.queryParams }

        if (value === Filter.PUBLISHED) {
          delete queryParams[Params.filter]
        } else {
          queryParams[Params.filter] = value
        }

        this.router.navigate([], {relativeTo: this.route, queryParams: queryParams})
      }),
      map(filter => filter === Filter.DELETED)
    )
    .subscribe(this.dataSource.deleted$)

    this.selection.changed
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(selection =>
          this.canDeleteSelection = selection.source.selected.every(comment => !comment.deletedAt)
        ),
        tap(selection =>
          this.canRestoreSelection = selection.source.selected.every(comment => !!comment.deletedAt)
        )
      )
      .subscribe()

    this.dataSource.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cd.detectChanges())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  commentClicked(comment: Comment) {
    this.router.navigate(
      ['admin', 'posts', comment.post.id],
      // TODO Once the admin posts view has support for
      //  direct comment linking, switch to this.
      // ['admin', 'posts', comment.post.id , 'comments' ],
      { queryParams: { highlight: comment.id } }
    )
  }

  userClicked(userId: string) {
    this.router.navigate(['admin', 'profiles', userId])
  }

  deleteAllComments() {
    if (confirm(`Are you sure you want to delete all of this user's comments?`)) {
      this.dataSource.userId$.pipe(
        take(1),
        switchMap(userId => this.commentsService.deleteUserComments(userId))
      ).subscribe({
        complete: () => {
          this.dataSource.paginator.pageIndex = 0
          this.dataSource.refresh()
        }
      })
    }
  }

  restoreAllComments() {
    if (confirm(`Are you sure you want to restore all of this user's comments?`)) {
      this.dataSource.userId$.pipe(
        take(1),
        switchMap(userId => this.commentsService.restoreUserComments(userId))
      ).subscribe({
        complete: () => {
          this.dataSource.paginator.pageIndex = 0
          this.dataSource.refresh()
        }
      })
    }
  }

  deleteSelectedComments() {
    const comments = this.selection.selected
    if (comments === undefined || comments.length === 0) {
      console.error('Attempting to delete comments but none were selected.')
      return
    }

    let description: string
    if (comments.length > 1) {
      description = `${comments.length} selected comments`
    } else {
      description = 'the selected comment'
    }
    if (confirm(`Are you sure you want to delete ${description}?`)) {
      Promise.all(comments.map(c => this.commentsService.deleteComment(c.id))).then(() => {
        this.selection.clear()
        this.dataSource.refresh()
      })
    }
  }

  restoreSelectedComments() {
    const comments = this.selection.selected
    if (comments === undefined || comments.length === 0) {
      console.error('Attempting to restore comments but none were selected.')
      return
    }

    let description: string
    if (comments.length > 1) {
      description = `${comments.length} selected comments`
    } else {
      description = 'the selected comment'
    }
    if (confirm(`Are you sure you want to restore ${description}?`)) {
      combineLatest(comments.map(comment => this.commentsService.restoreComment(comment.id)))
        .subscribe({
          next: () => {
            // TODO Update a progress bar?
          },
          complete: () => {
            this.selection.clear()
            this.dataSource.refresh()
          },
          error: (err) => {
            alert(`There was an error restoring the comments: ${err['message'] ? err.message : err}`)
          }
        })
    }
  }
}
