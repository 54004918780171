import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LegacyPost } from '../../model/post';
import { PostsService } from '../../services/posts.service';

@Component({
  selector: 'app-related-posts',
  templateUrl: './related-posts.component.html',
  styleUrls: ['./related-posts.component.scss', '../gallery-page/gallery-page.component.scss']
})
export class RelatedPostsComponent implements OnInit, OnDestroy {

  private sub: any;
  pageId: String;
  posts$: Observable<LegacyPost[]>;
  nextObject: any;
  prevStartObjects: any[] = [];
  subscription: any;
  statsSubscription: any;

  constructor(
    private postsSvc: PostsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.pageId = params['pageId'];
      this.getPosts();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private getPosts(startObject?) {
    this.posts$ = this.postsSvc.getPostsByPageID(this.pageId, 100);
    this.posts$.subscribe(posts => {
      console.log('found posts: ', posts.length);
    })
  }

  postSelected(post: LegacyPost) {
    this.postsSvc.currentDialogPost = post;
    this.router.navigate(['/posts', post.$key]);
  }
}
