import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertMessageComponent } from 'app/shared/components/alert-message/alert-message.component';
import { DocumentChangeAction } from '@angular/fire/compat/firestore/interfaces';
import { catchError, finalize, retry, tap, take, timeout } from 'rxjs/operators';
import { Email } from 'app/admin/model/email';
import { EmailsService } from '../../../admin/services/emails.service'; 
import { ProfilesService } from '../../../shared/services/profiles.service';
import { ProfileStub } from 'app/shared/model/profile';
import e from 'express';

@Component({
  selector: 'app-email-compose',
  templateUrl: './email-compose.component.html',
  styleUrls: ['./email-compose.component.scss'],
})

export class EmailComposeComponent implements OnInit {
  @ViewChild(AlertMessageComponent) alertMsgComponent: AlertMessageComponent;

  profile: ProfileStub;
  subject: string;
  text: string;
  emailSuccessMsg: string = 'Your email has been sent for delivery. ' +
    'Please note, this does not guarantee the recipient has received the email; ' +
    'it just confirms that our system has successfully processed and sent it.';

  constructor(
    private profileSvc: ProfilesService,
    private emailSvc: EmailsService,
    private firestore: AngularFirestore,
  ) {}

  ngOnInit() {
    const data = window ? window['data'] : undefined;

    // Ensure we have the required parameters
    if (!data || (!data.profile && !data.profileId)) {
      return;
    }

    this.subject = data.subject;
    this.text = data.text + this.emailSvc.signature;

    if (data.profile) {
      this.profile = data.profile;
    } else {
      this.profileSvc.findProfile(data.profileId)
        .pipe(take(1))
        .subscribe({
          next: (profile) => this.profile = profile
        });
    }
  }

  sendPressed() {
    this.alertMsgComponent.showLoading();
    let emailSent: boolean = false;
    let emailSending: boolean = true;

    this.emailSvc
      .sendEmail(this.profile.id, this.subject, this.text)
        .subscribe(() => {
          const docRef = this.firestore.collection('moderator-emails', ref => ref.where('recipient_uid', '==', this.profile.id).orderBy('created_at', 'desc').limit(1));
          
          // Listen for changes to the document
          const subscription = docRef.snapshotChanges().pipe(
            tap((snapshots: DocumentChangeAction<unknown>[]) => {
              if (snapshots.length > 0) {
                const data = snapshots[0].payload.doc.data() as Email;
                // If data.sent_date has a value, then the email was sent
                if (data.sent_date) {
                  this.alertMsgComponent.showSuccess();
                  emailSent = true;
                  emailSending = false;
                  // Stop listening for changes
                  subscription.unsubscribe();
                } else if (!emailSending) {
                  this.alertMsgComponent.showFail();
                  emailSent = false;
                }
              }
            }),
            timeout(10000), // If no changes are detected after 10 seconds, stop listening
            retry(3),
            catchError(err => {
              console.error('An unexpected error occurred when trying to send the email ', err);
              this.alertMsgComponent.showFail();
              emailSent = false;
              emailSending = false;
              subscription.unsubscribe();
              return err;
            })
        ).subscribe();

      // To avoid memory leaks, unsubscribe from the observable 
      // after a certain amount of time has passed
      setTimeout(() => {
        if (!emailSent) {
          subscription.unsubscribe();
          emailSending = false;
          this.alertMsgComponent.showFail();
        }
      }, 30000);
    });
  }

  static openWindow(data: {
    subject: string;
    text: string;
    profile?: ProfileStub;
    profileId?: string;
  }) {
    var params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=300,top=300`;
    var winRef = window.open('/admin/email', 'Email User', params);

    winRef['data'] = data;
    winRef.focus();
    return;
  }

  dismissAlert() {
    this.alertMsgComponent.dismissAlert();
  }

  closeWindow() {
    window.close();
  }
}
