import { Injectable } from '@angular/core';
import { LegacyProfile } from 'app/shared/model/profile';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Search, SearchService } from '../../../shared/services/search.service';
import { ElasticSearchDataSource, ElasticSearchRequest } from '../../utils/elasticsearch-data-source';

class ProfilesListRequest extends ElasticSearchRequest {
}

@Injectable()
export class ProfilesDataSource extends ElasticSearchDataSource<ProfilesListRequest, LegacyProfile> {

  private searchIndex = `profiles${environment.elasticSearchSuffix}`
  queryString$ = new BehaviorSubject<string>('')

  constructor(
    private searchService: SearchService
  ) {
    super()
  }

  defaultArgs(): ProfilesListRequest {
    return new ProfilesListRequest()
  }

  executeQuery(request: ProfilesListRequest): Observable<Search.Results> {
    return this.queryString$
      .pipe(
        map(queryString => createQueryObject(queryString)),
        switchMap(query => this.searchService.search(this.searchIndex, request.apply(query))),
        catchError(e => {
          console.log('Request failed:', e)
          return ([])
        })
      )
  }

  mapResponse(response: Search.Hit[]): LegacyProfile[] {
    return response.map(hit => LegacyProfile.create(hit._id, hit._source))
  }
}

const createQueryObject = (queryString: string) => {
  if (typeof queryString !== 'string' || queryString.length === 0) {
    return {}
  }

  if (queryString.indexOf('*') > -1) {
    return {
      query: {
        bool: {
          'should': [
            {
              'multi_match': {
                'query': queryString,
                'fields': [ 'username', '_id' ],
                'boost': 10.0
              }
            },
            {
              'wildcard': {
                'username': {
                  'value': `${queryString}`,
                  'boost': 8.0
                }
              }
            },
          ]
        }
      }
    }
  } else {
    return {
      query: {
        'bool': {
          'should': [
            {
              'multi_match': {
                'query': queryString,
                'fields': [ 'username', '_id' ],
                'boost': 10.0
              }
            },
            {
              'query_string': {
                'query': queryString,
                'fields': [ 'username', '_id' ],
                'boost': 5.0
              }
            },
            {
              'wildcard': {
                'username': {
                  'value': `${queryString}*`,
                  'boost': 4.0
                }
              }
            },
            {
              'wildcard': {
                'username': {
                  'value': `*${queryString}`,
                  'boost': 2.0
                }
              }
            },
            {
              'wildcard': {
                'username': {
                  'value': `*${queryString}*`,
                  'boost': 1.0
                }
              }
            },
            {
              'match': {
                'username': {
                  'query': queryString,
                  'fuzziness': 'AUTO'
                }
              }
            }
          ]
        }
      }
    }
  }
}
