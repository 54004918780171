<div class="gallery">

    <a href="http://pixiteapps.com/pigment"><img class="logo" src="/assets/img/logo.png" alt="Pigment - true coloring"></a>

    <div class="app_store_badges">
      <a href ="https://itunes.apple.com/us/app/pigment-only-true-coloring/id1062006344?ls=1&mt=8&pt=14691&ct=gallery_website&at=11l4HQ">
        <img class="badge ios" src="/assets/img/download_on_the_app_store.svg" />
      </a>
    </div>

    <div class="post-thumbs">
      <h1 class="gallery-header">Popular Posts from the Gallery</h1>
      <app-post-thumb-detail *ngFor="let post of posts" [post]="post" class="post-thumb" (selected)="postSelected($event)"></app-post-thumb-detail>
      <div *ngIf="posts.length > 0" class="moreButton"><a (click)="showMore()">View More <img src="/assets/img/chevron_down.png" /></a></div>
    </div>

</div>
<router-outlet></router-outlet>
