<div class="post-actions">

  <button mat-icon-button color="primary" aria-label="Email User" (click)="sendUserEmail()" matTooltip="Email User">
    <mat-icon>email</mat-icon>
  </button>

  <button mat-icon-button color="primary" aria-label="Edit Notes" (click)="openNotes()" matTooltip="Edit Notes">
    <mat-icon>notes</mat-icon>
  </button>

  <button mat-icon-button color="warn" aria-label="Delete Post" (click)="deletePost()" *ngIf="post.deleted_date == null" matTooltip="Delete Post">
    <mat-icon>delete</mat-icon>
  </button>

  <button mat-icon-button *ngIf="(flagged$ | async) && (flagged$ | async)?.status != 'resolved'"
    color="primary"
    (click)="markAsResolved()"
    matTooltip="Resolve Flags" >
    <mat-icon>done</mat-icon>
  </button>

  <app-post-actions-overflow [post]="post" aria-label="Additional post actions">
    <mat-icon>more_vert</mat-icon>
  </app-post-actions-overflow>

</div>
