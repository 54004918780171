import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfilesDataSource } from 'app/admin/components/profiles/profiles.datasource';
import { LegacyProfile } from 'app/shared/model/profile';
import { SearchService } from 'app/shared/services/search.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { PaginatorComponent } from '../common/paginator/paginator.component';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss'],
  providers: [ProfilesDataSource]
})
export class ProfilesComponent implements OnInit, OnDestroy {

  @ViewChild('searchInput') searchInput: ElementRef
  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent

  selection = new SelectionModel<LegacyProfile>(true, [])
  searchInputFormControl = new UntypedFormControl()

  private searchTerm$ = new BehaviorSubject<string>(undefined)
  private unsubscribe$ = new Subject<void>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private profilesService: ProfilesService,
    private searchService: SearchService,
    public dataSource: ProfilesDataSource,
    private cd: ChangeDetectorRef,
    private scroller: ViewportScroller
  ) {
  }

  ngOnInit() {
    const queryString = this.route.snapshot.queryParamMap.get('q')
    if (queryString && queryString.length > 0) {
      this.searchInputFormControl.setValue(queryString, {emitEvent: false})
      this.searchTerm$.next(queryString)
    }

    this.paginator.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.scroller.scrollToAnchor('table')
    })

    this.searchInputFormControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(input => typeof input === 'string'),
        debounceTime(500),
        distinctUntilChanged(),
      ).subscribe(this.searchTerm$)

    this.searchTerm$
        .pipe(
          takeUntil(this.unsubscribe$),
          distinctUntilChanged(),
          filter(value => value !== undefined),
          tap(value => {
            const queryParams: object = { ...this.route.snapshot.queryParams }

            if (value.length > 0) {
              queryParams['q'] = value
            } else {
              delete queryParams['q']
            }

            this.router.navigate([], {relativeTo: this.route, queryParams: queryParams})
          })
        ).subscribe(this.dataSource.queryString$)

    // We need to trigger a change on this component when the data updates.
    //  Ideally this would happen internally, but the data table is optimized
    //  for speed, so doesn't automatically watch for updates. The result is
    //  that the table will only update after a timeout or the user clicks.
    this.dataSource.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cd.detectChanges())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  enterClicked() {
    this.searchTerm$.next(this.searchInputFormControl.value)
  }

  profileClicked(profile: LegacyProfile) {
    this.router.navigate([profile.$key], {relativeTo: this.route})
  }

  primeProfileSearch() {
    this.searchService.prime('profiles')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe()
  }

  disableSelectedProfiles() {
    const profiles = this.selection.selected
    if (profiles === undefined || profiles.length === 0) {
      console.error('Attempting to disable profiles but none were selected.')
      return
    }

    let description: string
    if (profiles.length > 1) {
      description = `${profiles.length} selected profiles`
    } else {
      description = 'selected profile'
    }
    if (confirm(`Are you sure you want to disable the ${description}?`)) {
      Promise.all(profiles.map(p => this.profilesService.disableProfile(p.$key))).then()
    }
  }
}
