<mat-card>
  <app-table-toolbar [selection]="selection">
    <ng-container appWithEmptySelectionToolbar>
      <mat-chip-list>
        <mat-chip
          class="filter-picker-trigger"
          [matMenuTriggerFor]="presetMenu"
          [ngSwitch]="filter$ | async">
          <mat-icon>filter_list</mat-icon>
          <span *ngSwitchCase="'published'">Published</span>
          <span *ngSwitchCase="'deleted'">Deleted</span>
        </mat-chip>
      </mat-chip-list>
      <mat-menu #presetMenu="matMenu">
        <button mat-menu-item (click)="filter$.next(Filter.PUBLISHED)">Published</button>
        <button mat-menu-item (click)="filter$.next(Filter.DELETED)">Deleted</button>
      </mat-menu>

      <mat-form-field class="filter" floatLabel="never">
        <input matInput placeholder="Filter" [(ngModel)]="dataSource.filterString">
        <button mat-button *ngIf="dataSource.filterString" matSuffix mat-icon-button aria-label="Clear" (click)="dataSource.filterString=''">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <span class="spacer"></span>

      <ng-container *ngIf="(isModerator$ | async)">
        <button
          mat-icon-button
          type="button"
          (click)="addingComment = !addingComment"
          cdkOverlayOrigin
          #addCommentTrigger="cdkOverlayOrigin"
          aria-label="Add Comment">
          <mat-icon>add</mat-icon>
        </button>
        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="addCommentTrigger"
          [cdkConnectedOverlayOpen]="addingComment"
        >
          <div class="comment-container">
            <app-comment-admin [postId]="postId$ | async" (completed)="onModCommentPosted($event)"></app-comment-admin>
          </div>
        </ng-template>
      </ng-container>

      <button mat-icon-button [matMenuTriggerFor]="overflowMenu" aria-label="Comment Actions">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #overflowMenu="matMenu">
        <ng-container [ngSwitch]="filter$ | async">
          <button mat-menu-item *ngSwitchCase="Filter.PUBLISHED" color="warn" (click)="deleteAllComments()">
            Delete All Comments
          </button>
          <button mat-menu-item *ngSwitchCase="Filter.DELETED" color="warn" (click)="restoreAllComments()">
            Restore All Comments
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>

    <ng-container appWithSelectionToolbar>
      <button mat-raised-button *ngIf="canDeleteSelection" class="delete" color="warn" (click)="deleteSelectedComments()">
        Delete {{ selection.selected.length }} Comments
      </button>
      <button mat-raised-button *ngIf="canRestoreSelection" class="restore" color="warn" (click)="restoreSelectedComments()">
        Restore {{ selection.selected.length }} Comments
      </button>

    </ng-container>
  </app-table-toolbar>

  <app-admin-comments-table
      #table
      id="table"
      class="table"
      [source]="dataSource"
      [selection]="selection"
      [sortColumns]="sortColumns"
      (commentClicked)="commentClicked($event)"
      (userIdClicked)="userClicked($event)">
  </app-admin-comments-table>

  <app-paginator #paginator [source]="dataSource" [selection]="selection" storageKey="postdetail:comments">
  </app-paginator>
</mat-card>

<router-outlet></router-outlet>
