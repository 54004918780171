import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  template: '<html><body>redirecting to <a href="{{ location }}">{{ location }}</a>...</body></html>'
})
export class RedirectComponent implements OnInit {

  location: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.location = this.route.snapshot.data.externalUrl;
    window.location.href = this.location;
  }

}
