<table mat-table
  [dataSource]="source"
  matSort
  matSortActive="follower_count"
  matSortDirection="desc"
  appPersistentTableState
  #sort="matSort">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="thumb">
    <th mat-header-cell *matHeaderCellDef>Thumbnail</th>
    <td mat-cell *matCellDef="let profile">
      <img *ngIf="profile.thumb else placeholder" class="list-thumb" src="{{ profile.thumb }}" />
      <ng-template #placeholder>
        <img class="list-thumb" src="../../../assets/img/profile_placeholder.jpg" />
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="username">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="username.keyword">Username</th>
    <td mat-cell *matCellDef="let profile">{{profile.username || '(not set)'}}</td>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at">Member Since</th>
    <td mat-cell *matCellDef="let profile"> {{profile.created_at | date: 'short'}} </td>
  </ng-container>

  <ng-container matColumnDef="posts">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="post_count">Posts</th>
    <td mat-cell *matCellDef="let profile"> {{profile.post_count || 0}} </td>
  </ng-container>

  <ng-container matColumnDef="follower_count">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="follower_count">Followers</th>
    <td mat-cell *matCellDef="let profile"> {{profile.follower_count || 0}} </td>
  </ng-container>

  <ng-container matColumnDef="following">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="following_count">Following</th>
    <td mat-cell *matCellDef="let profile"> {{profile.following_count || 0}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"
      class="profile-row"
      (click)="onProfileClicked($event, row)"></tr>
</table>
