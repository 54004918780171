import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegacyPost } from '../../model/post';
import { LegacyComment } from '../../model/comment';
import { LoginService } from '../../services/login.service';
import { PostsService, PageDetails } from '../../services/posts.service';
import { CommentsService } from '../../services/comments.service';
import { getEnv } from 'environments/environment.provider';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, of } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { SeoService } from 'app/shared/services/seo.service';
import { MagicImagePipe } from '../../pipes/magic-image.pipe';

declare const gtag: Function;

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit, OnChanges, OnDestroy {

  @Input() post: LegacyPost;
  @Input() selectedCommentID: string;
  @Input() showingAdmin = false;

  admin$: Observable<boolean>;
  firstComment$: Observable<LegacyComment>;
  comments$: Observable<LegacyComment[]>;
  deletedComments$: Observable<LegacyComment[]>;
  commentLimit$: BehaviorSubject<number> = new BehaviorSubject(15);
  defaultCommentLimit: number = 15;
  coloringPageDetails$: Observable<PageDetails>;
  
  private post$: BehaviorSubject<LegacyPost> = new BehaviorSubject(null);
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private seoSvc: SeoService,
    private loginSvc: LoginService,
    private postSvc: PostsService,
    private commentsService: CommentsService) {
  }

  ngOnInit() {
    this.admin$ = this.loginSvc.isAdmin;

    this.firstComment$ = this.post$.pipe(
      map(this.commentsService.firstCommentFromPost)
    );

    this.comments$ = combineLatest([this.post$, this.commentLimit$])
      .pipe(
        switchMap(([post, limit]) => {
          if (limit > this.defaultCommentLimit && limit > this.route.snapshot.data['postCommentsData']['comments'].length) {
            return this.commentsService.getComments(post.$key, limit);
          } else {
            return of(this.route.snapshot.data['postCommentsData']['comments']
              .slice(0, Number(limit)));
          }
        })
      ); 

    // track if we're an admin
    this.deletedComments$ = combineLatest([this.admin$, this.post$])
      .pipe(
        switchMap(([isAdmin, post]) =>
          isAdmin ? this.commentsService.getDeletedComments(post.$key) : EMPTY
        )
      );

    this.post$
      .pipe(takeUntil(this.unsubscribe$))
        .subscribe(post => {
          if (!post.image) {
            return
          }
          const image_width = 400
          const imageURL = new MagicImagePipe().transform(post.image, image_width);
          const site_domain = getEnv().siteDomain;
          const site_protocol = site_domain.includes('localhost') ? 'http://' : 'https://';

          this.seoSvc.generateTags({
            title: `Post by ${this.post.owner_username} on Pigment`,
            description: this.post.description || '',
            image: imageURL,
            url: `${site_protocol}${site_domain}${this.router.url}`,
            image_width: image_width
          });
      });

    this.coloringPageDetails$ = this.post$.pipe(
      switchMap(post => {
        if (post.page_id) {
          return this.postSvc.findPostColoringPageDetails(post.page_id)
        } else {
          return of(null);
        }
      })
    );   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    this.commentLimit$.next(15);
    this.post$.next(this.post);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  userClicked(userID: String) {
    this.router.navigate(['./profiles', userID]);
  }

  managePost(post: LegacyPost) {
    this.router.navigate(['admin', 'posts', post.$key]);
  }

  toggleAdmin() {
    this.showingAdmin = !this.showingAdmin;
  }

  postClicked(post: LegacyPost) {
    this.postSvc.currentDialogPost = post;
    this.router.navigate([post.$key], {relativeTo: this.route});
  }

  showMoreComments() {
    this.commentLimit$.next(this.commentLimit$.value + 50);
  }

  trackColoringPageClick(): void {
    const previousPagePath = localStorage.getItem('previous_page_path');

    gtag('event', 'view_uncolored_link_click', {
      'event_category': 'Navigation',
      'event_label': 'Post "View Uncolored Version" Link Clicked',
      'post_id': this.post.$key,
      'page_referrer': previousPagePath
    });
    // Update the previous page path for the next page view or event
    localStorage.setItem('previous_page_path', this.router.url);
  }
}
