import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { LegacyPost } from 'app/shared/model/post';
import { onClick } from '../../../utils/selectable-click-handler';
import { PostsListRequest } from '../posts.types';

@Component({
  selector: 'app-posts-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class PostsTableComponent implements OnInit {
  @Output() postClicked = new EventEmitter<LegacyPost>()
  @Output() userIdClicked = new EventEmitter<string>()

  @Input() source: FirestoreDataSource<PostsListRequest, LegacyPost>
  @Input() selection: SelectionModel<LegacyPost>
  @Input() columns = ['select', 'image', 'username', 'description', 'created_at', 'comment_count', 'like_count', 'popularity']
  @Input() sortColumns = ['username', 'created_at', 'comment_count', 'like_count', 'popularity']

  @ViewChild('sort', { static: true }) sort: MatSort

  constructor(
  ) { }

  ngOnInit(): void {
    this.source.sort = this.sort
    for (const [, sortable] of this.sort.sortables.entries()) {
      if (this.sortColumns.indexOf(sortable.id) === -1) {
        (sortable as MatSortHeader).disabled = true
      }
    }
  }

  onPostClicked($event: MouseEvent, post: LegacyPost) {
    onClick($event, () => this.postClicked.emit(post))
  }

  onUsernameClicked($event: MouseEvent, post: LegacyPost) {
    onClick($event, () => {
      this.userIdClicked.emit(post.owner_id)
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.source.data.forEach(row => this.selection.select(row));
  }
}
