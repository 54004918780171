export default class StringUtilities {

    static urlify(text_input: string): string { 
      const regex_markdown = this.getRegexForMarkdownLinks();
      
      text_input.replace(regex_markdown, (match, linkText, linkUrl) => {
        if (this.validateURLFormat(linkUrl)) {
          text_input = text_input.replace(match, `<a href="${linkUrl}" target="_blank">${linkText}</a>`);
        }
        return match;
      });

      return text_input;
    }

    static textContainsValidURLMarkdown(text_input: string): boolean {
      const regex_markdown = this.getRegexForMarkdownLinks();
      let hasValidURLMarkdown = false;

      text_input.replace(regex_markdown, (match, linkText, linkUrl) => {
        if (this.validateURLFormat(linkUrl)) {
          hasValidURLMarkdown = true;
        }
        return match;
      });

      return hasValidURLMarkdown;
    }

    static validateURLFormat(url: string): boolean {
      const urlRegex = this.getRegExForValidURLs();
      return urlRegex.test(url);
    }

    static getRegexForMarkdownLinks(): RegExp {    
        // RegEx for finding markdown links, if we are not fretting about nested parentheses:
        // /\[(.*?)\]\((.*?)\)/g;
        
        // RegEx for finding markdown links, even if they have nested parentheses:
        return /\[([^[\]]+)\]\(((?:[^)(]+|\((?:[^)(]+|\([^)(]*\))*\))*)\)/g;
    }

    static getRegExForValidURLs(): RegExp {
      return  /(https:\/\/[^\s]+)/g;
    }
}
