<div class="profiles">
  <mat-card role="main">

    <mat-toolbar>
      <ng-container *ngIf="selection.isEmpty(); else bulkActions">
        <mat-icon class="leading">search</mat-icon>
        <input #searchInput
          matInput
          placeholder="Search Usernames"
          [formControl]="searchInputFormControl"
          (keyup.enter)="enterClicked()">

        <button mat-icon-button [matMenuTriggerFor]="profileOverflowMenu"
                aria-label="Profile actions menu button">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #profileOverflowMenu="matMenu">
          <button mat-menu-item (click)="primeProfileSearch()">Re-index profiles</button>
        </mat-menu>
      </ng-container>

      <ng-template #bulkActions>
        <button mat-raised-button color="warn" (click)="disableSelectedProfiles()">
          Disable {{ selection.selected.length }} Profiles
        </button>
      </ng-template>
    </mat-toolbar>

    <!-- <div class="spinner-container" *ngIf="dataSource.loading$ | async">
      <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->

    <admin-profiles-table
      #table
      id="table"
      class="table"
      [source]="dataSource"
      [selection]="selection"
      (profileClicked)="profileClicked($event)">
    </admin-profiles-table>

    <app-paginator #paginator [source]="dataSource" [selection]="selection" storageKey="profiles"></app-paginator>
  </mat-card>
</div>
