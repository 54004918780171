import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CommentsService} from 'app/shared/services/comments.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {share} from 'rxjs/operators';
import {LegacyProfile} from 'app/shared/model/profile';
import {ProfilesService} from 'app/shared/services/profiles.service';
import {LegacyComment} from 'app/shared/model/comment';

export interface UserCommentsDialogData {
  userId: string
}

@Component({
  selector: 'app-user-comments',
  templateUrl: './user-comments.component.html',
  styleUrls: ['./user-comments.component.scss']
})
export class UserCommentsComponent implements OnInit {

  private readonly userId: string

  profile$: Observable<LegacyProfile>
  comments$: Observable<LegacyComment[]>;
  deletedComments$: Observable<LegacyComment[]>;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) data: UserCommentsDialogData,
    private commentsService: CommentsService,
    private profilesService: ProfilesService
  ) {
    this.userId = data.userId
  }

  ngOnInit() {
    this.profile$ = this.profilesService.findProfile(this.userId)
    this.comments$ = this.commentsService.getUserComments(this.userId, 200).pipe(share())
    this.deletedComments$ = this.commentsService.getUserComments(this.userId, 200, true).pipe(share())
  }

}
