<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div *ngIf="showAlert">
    <div *ngIf="showSuccessMsg" class="alert alert-success">
        <strong>Success!</strong> {{ successMsg }}
        <span (click)="dismissAlert()" class="alert-dismissible">X</span>
    </div>
    <div *ngIf="showFailMsg" class="alert alert-warn">
        <strong>Error:</strong> An unexpected error occurred, process was not completed
        <span (click)="dismissAlert()" class="alert-dismissible">X</span>
    </div>
</div>
