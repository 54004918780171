import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OsService {

  os: string;

  constructor() {
    this.os = this.getMobileOperatingSystem();
  }

  getMobileOperatingSystem() {

    if(typeof window === 'undefined' || typeof navigator === 'undefined' ) {
      return "unknown";
    }

    var userAgent = navigator.userAgent || navigator.vendor;
  
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
  }
}