import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as firebase from 'firebase/compat/app';

@Component({
  selector: 'app-account-menu',
  template: `
    <div *ngIf="afAuth.user | async as user; else showLogin">
      <mat-menu #userMenu="matMenu" xPosition="before">
        <button mat-menu-item (click)="logout();"> Logout</button>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="userMenu">
        <span>{{ user.displayName }}</span>
        <mat-icon>account_circle</mat-icon>
      </button>
    </div>
    <ng-template #showLogin>
      <button mat-raised-button color="accent" (click)="login()">Login with Google</button>
    </ng-template>
  `,
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
  constructor(public afAuth: AngularFireAuth) { }

  ngOnInit() {
  }

  login() {
    this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
  }

  logout() {
    this.afAuth.signOut();
  }

}
