import { Profile, ProfileStub } from './profile';

export interface Activity {
  id: string | undefined;
  app: string;
  type: string;
  actor: ProfileStub;
  targetId: string;
  targetUserId: string;
  createdAt: Date;
  deletedAt?: Date;
  /**
   * If this activity record is being mirrored by a function, the
   * key of the activity record.
   */
  sourceId?: string;
  /**
   * If this activity record is being mirrored as part of a data migration,
   * the timestamp of the migration run will be included here.
   */
  migration?: Date;
}

export const baseActivity: object = {
  id: '',
  app: 'pigment',
  type: '',
  actor: Profile.createStub(),
  targetId: '',
  targetUserId: '',
  createdAt: new Date()
}

export const Activity = {
  create(props: Partial<Activity>): Activity {
    const activity = { ...baseActivity } as Activity;
    if (props.id !== undefined && props.id !== null) {
      activity.id = props.id;
    }
    if (props.app !== undefined && props.app !== null) {
      activity.app = props.app;
    }
    if (props.type !== undefined && props.type !== null) {
      activity.type = props.type;
    }
    if (props.actor !== undefined && props.actor !== null) {
      activity.actor = props.actor;
    }
    if (props.targetId !== undefined && props.targetId !== null) {
      activity.targetId = props.targetId;
    }
    if (props.targetUserId !== undefined && props.targetUserId !== null) {
      activity.targetUserId = props.targetUserId;
    }
    if (props.createdAt !== undefined && props.createdAt !== null) {
      activity.createdAt = props.createdAt;
    }
    if (props.deletedAt !== undefined && props.deletedAt !== null) {
      activity.deletedAt = props.deletedAt;
    }
    if (props.sourceId !== undefined && props.sourceId !== null) {
      activity.sourceId = props.sourceId;
    }
    if (props.migration !== undefined && props.migration !== null) {
      activity.migration = props.migration;
    }
    return activity;
  }
}
