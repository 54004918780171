import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { flagFromFirestore } from 'app/admin/model/firestore.converters';
import { Flag } from 'app/admin/model/flag';
import { applyFirestoreRequest, FirestoreDataSource, FirestoreRequest } from 'app/admin/utils/firestore-data-source';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export interface ChildFlagsListRequest extends FirestoreRequest {
  contentId?: string
}

@Injectable()
export class ChildFlagsDataSource extends FirestoreDataSource<ChildFlagsListRequest, Flag> {

  flaggedId = new BehaviorSubject<string | undefined>(undefined)

  constructor(
    private firestore: AngularFirestore,
  ) {
    super()
    this.isFollowing = true
  }

  defaultArgs(): Observable<ChildFlagsListRequest> {
    return this.flaggedId.pipe(map(id => ({
      contentId: id
    } as ChildFlagsListRequest)))
  }

  executeQuery(request: ChildFlagsListRequest): Observable<DocumentChangeAction<unknown>[]> {
    return !request.contentId ? of([]) : this.firestore.collection(
      this.firestore.firestore.collection('flags').doc(request.contentId).collection('flags'),
      ref => applyFirestoreRequest(ref, request)
    ).snapshotChanges()
  }

  mapResponse(response: DocumentChangeAction<unknown>[]): Flag[] {
    return response.map(action => flagFromFirestore(action.payload.doc.data(), action.payload.doc.id))
  }

  totalItems(): Observable<number> {
    return this.flaggedId.pipe(
      switchMap(id => this.firestore.collection('flags').doc(id).snapshotChanges()),
      map(action => action.payload.get('flag_count') || 0)
    )
  }
}
