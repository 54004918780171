import { Injectable } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Stats} from '../../shared/model/stats';
import {Observable} from 'rxjs';

@Injectable()
export class StatsService {
  private basePath = '/reports/stats';

  constructor(private db: AngularFireDatabase) { }

  getStats(): Observable<Stats> {
    return this.db.object(this.basePath).valueChanges() as Observable<Stats>;
  }

}
