import { Injectable } from '@angular/core';
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction, DocumentSnapshot, QueryFn } from '@angular/fire/compat/firestore';
import { Post } from 'app/shared/model/post';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Comment, LegacyComment } from '../model/comment';
import { LegacyPost } from '../model/post';
import { Profile } from '../model/profile';

const mapFromDocument = (snapshot: DocumentSnapshot<unknown>) => {
  if (!snapshot.exists) {
    return undefined
  }

  const data: any = snapshot.data()
  const actor = data.actor || {}
  const deletedAt = data.deleted && data.deleted.date ? data.deleted.date.toDate() : undefined
  return Comment.create({
    id: snapshot.id,
    app: data.app,
    type: data.type,
    actor: Profile.createStub({
      id: actor.user_id,
      username: actor.username,
      image: actor.image,
      premium: actor.is_premium,
    }),
    post: Post.createStub({
      id: data.target_id,
      image: data.post_thumb_url,
      owner: Profile.createStub({ id: data.target_user_id })
    }),
    message: data.message,
    createdAt: data.created_at.toDate(),
    deletedAt: deletedAt,
  })
}

export const mapCommentFromFirestore = (snapshot: DocumentChangeAction<unknown>) => {
  return mapFromDocument(snapshot.payload.doc as DocumentSnapshot<unknown>)
}

@Injectable()
export class CommentsService {

  constructor(
    private db: AngularFireDatabase,
    protected firestore: AngularFirestore,
  ) {
  }

  firstCommentFromPost(post: LegacyPost) {
    if (post.description) {
      const comment = new LegacyComment();
      comment.$key = null;
      comment.created_at = post.created_at;
      comment.message = post.description;
      comment.owner_user_id = post.owner_id;
      comment.owner_username = post.owner_username;
      comment.owner_thumb = post.owner_thumb;
      return comment;
    }
    return null;
  }

  private mapCommentWithPostId(postId?: string) {
    return (item: SnapshotAction<any>) => {
      if (!item.payload.exists()) {
        return undefined
      }

      const payload = item.payload.val()
      const comment = new LegacyComment()
      comment.postID = postId
      comment.$key = item.key
      comment.created_at = payload.created_at
      comment.message = payload.message
      comment.owner_user_id = payload.owner.user_id
      comment.owner_username = payload.owner.username
      comment.owner_thumb = payload.owner.thumb
      return comment
    }
  }

  private mapComment = (item: DocumentSnapshot<any>): LegacyComment => {
    const comment = new LegacyComment()
    comment.$key = item.id

    const payload = item.data()
    comment.postID = payload["target_id"]
    comment.created_at = payload["created_at"].toDate().getTime()
    comment.message = payload["message"]
    comment.owner_user_id = payload["actor"].user_id
    comment.owner_username = payload["actor"].username
    comment.owner_thumb = payload["actor"].image

    return comment
  }

  getUserComments(userId: string, limit: number, deleted: boolean = false): Observable<LegacyComment[]> {
    const queryFn: QueryFn = (ref => {
      let query = ref
        .where("type", "==", "comment")
        .where("actor.user_id", "==", userId)
        .orderBy("created_at", "desc")
      if (limit) {
        query = query.limit(limit)
      }
      return query
    })

    const collection = deleted
      ? this.firestore.collection("deleted_activity", queryFn)
      : this.firestore.collection("activity", queryFn)

    return collection.snapshotChanges().pipe(
      map(snaps => snaps
        .map(action => action.payload.doc)
        .map(this.mapComment)
      )
    )
  }

  getComments(postId: string, limit: number): Observable<LegacyComment[]> {
    return this.db
      .list(`/comments/${postId}`, ref => ref.orderByKey().limitToFirst(limit))
      .snapshotChanges()
      .pipe(
        map(items => items.map(this.mapCommentWithPostId(postId)))
      )
  }

  getDeletedComments(postId: string): Observable<LegacyComment[]> {
    const basePath = `/deleted/comments/${postId}`;
    return this.db.list(basePath, ref => ref.orderByKey())
      .snapshotChanges().pipe(
        map(items => items.map(this.mapCommentWithPostId(postId)))
      )
  }

  getComment(postId: string, commentId: string, deleted: boolean = false): Observable<LegacyComment> {
    const prefix = deleted ? "/deleted" : ""
    const path = `${prefix}/comments/${postId}/${commentId}`
    return this.db.object(path).snapshotChanges().pipe(
      map(this.mapCommentWithPostId(postId))
    )
  }

  findComment(path: string): Observable<LegacyComment> {
    return this.db.object(path)
      .snapshotChanges().pipe(
        map(this.mapCommentWithPostId(null))
      )
  }

  deleteCommentAtPath(path: string) {
    if (!path.startsWith("/comments/")) {
      alert(`Invalid comment path: ${path}`)
      return
    }

    this.db.object(path).remove()
  }

  deleteLegacyComment(postId: string, commentId: string) {
    this.db.object(`/comments/${postId}/${commentId}`).remove()
  }

  /**
   * Deletes all comments for a given post.
   *
   * @param postId The id of the post.
   * @returns An observable that completes once all comments have been deleted.
   */
  deletePostComments(postId: string): Observable<void> {
    return this.deleteAll(
      this.firestore.collection('activity', query =>
        query
          .where('type', '==', 'comment')
          .where('target_id', '==', postId)
      )
    )
  }

  /**
   * Restores all deleted comments for a given post.
   *
   * @param postId The id of the post.
   * @returns An observable that completes once all comments have been restored.
   */
  restorePostComments(postId: string): Observable<void> {
    return this.restoreAll(
      this.firestore.collection('deleted_activity', query =>
        query
          .where('type', '==', 'comment')
          .where('target_id', '==', postId)
      )
    )
  }

  /**
   * Deletes all comments for a given user.
   *
   * @param userId The id of the user.
   * @returns An observable that completes once all comments have been deleted.
   */
  deleteUserComments(userId: string): Observable<void> {
    return this.deleteAll(
      this.firestore.collection('activity', query =>
        query
          .where('app', '==', 'pigment')
          .where('type', '==', 'comment')
          .where('actor.user_id', '==', userId)
      )
    )
  }

  /**
   * Restores all deleted comments for a given user.
   *
   * @param userId The id of the user.
   * @returns An observable that completes once all comments have been restored.
   */
  restoreUserComments(userId: string): Observable<void> {
    return this.restoreAll(
      this.firestore.collection('deleted_activity', query =>
        query
          .where('app', '==', 'pigment')
          .where('type', '==', 'comment')
          .where('actor.user_id', '==', userId)
      )
    )
  }

  private deleteAll(collection: AngularFirestoreCollection<unknown>): Observable<void> {
    return collection.get().pipe(
      switchMap(snapshot => from(Promise.all(snapshot.docs.map(doc => doc.ref.delete())))),
      map(() => {})
    )
  }

  private restoreAll(collection: AngularFirestoreCollection<unknown>): Observable<void> {
    return collection.get().pipe(
      switchMap(snapshot => {
        return from(Promise.all(
          snapshot.docs.map(doc => {
            const data = doc.data() as any
            delete data.deleted

            return this.firestore.collection('activity').doc(doc.id).set(data)
              .then(() => this.firestore.collection('deleted_activity').doc(doc.id).delete())
          })
        ))
      }),
      map(() => {})
    )
  }

  async deleteComment(commentId: string) {
    return this.firestore.collection('activity').doc(commentId).delete()
  }

  restoreComment(commentId: string): Observable<Comment> {
    return this.firestore.collection('deleted_activity').doc(commentId).get()
      .pipe(
        switchMap(snapshot => {
          const data = snapshot.data() as any
          delete data.deleted
          return this.firestore.collection('activity').doc(commentId).set(data)
        }),
        switchMap(() => this.firestore.collection('deleted_activity').doc(commentId).delete()),
        switchMap(() => this.firestore.collection('activity').doc(commentId).get()),
        map(snapshot => mapFromDocument(snapshot as DocumentSnapshot<unknown>))
      )
  }
}
