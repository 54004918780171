import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { PostDialogRouterComponent } from '../shared/components/post-detail/post-dialog-router.component'
import { AdminGuard } from '../shared/guards/admin.guard'
import { AdminComponent } from './admin.component'
import { ActivityComponent } from './components/activity/activity.component'
import { LandingComponent } from './components/landing/landing.component'
import { EmailComposeComponent } from './components/email-compose/email-compose.component'
import { FlagsComponent } from './components/flags/flags.component'
import { LoginComponent } from './components/login/login.component'
import { NotesComponent } from './components/notes/notes.component'
import { PostCommentsComponent } from './components/post-detail/comments/comments.component'
import { PostFlagsComponent } from './components/post-detail/flags/flags.component'
import { PostDetailComponent } from './components/post-detail/post-detail.component'
import { PostsComponent } from './components/posts/posts.component'
import { ProfileCommentsComponent } from './components/profile-detail/comments/comments.component'
import { ProfilePostsComponent } from './components/profile-detail/posts/posts.component'
import { ProfileDetailComponent } from './components/profile-detail/profile-detail.component'
import { ProfilesComponent } from './components/profiles/profiles.component'
import { RecommendedComponent } from './components/recommended/recommended.component'

const adminRoutes: Routes = [
  {
    path: 'admin/email',
    component: EmailComposeComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin/notes',
    component: NotesComponent,
    canActivate: [AdminGuard],
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'landing', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      {
        path: 'activity',
        component: ActivityComponent,
        canActivate: [AdminGuard]
      },
      {
        path: 'landing',
        component: LandingComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'profiles/:userId',
        component: ProfileDetailComponent,
        canActivate: [AdminGuard],
        children: [
          {path: '', redirectTo: 'posts', pathMatch: 'full'},
          {
            path: 'posts',
            component: ProfilePostsComponent,
            canActivate: [AdminGuard],
          },
          {
            path: 'posts/:postId',
            component: PostDialogRouterComponent,
            canActivate: [AdminGuard],
          },
          {
            path: 'comments',
            component: ProfileCommentsComponent,
            canActivate: [AdminGuard],
          },
        ],
      },
      {
        path: 'profiles',
        component: ProfilesComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'recommended',
        component: RecommendedComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'posts/:postId',
        component: PostDetailComponent,
        canActivate: [AdminGuard],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'comments',
          },
          {
            path: 'comments',
            component: PostCommentsComponent,
            canActivate: [AdminGuard],
          },
          {
            path: 'flags',
            component: PostFlagsComponent,
            canActivate: [AdminGuard],
          }
        ]
      },
      {
        path: 'posts',
        component: PostsComponent,
        canActivate: [AdminGuard],
      },
      {
        path: 'flags',
        component: FlagsComponent,
        canActivate: [AdminGuard],
      }
    ],
  },
]

@NgModule({
  imports: [RouterModule.forChild(adminRoutes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
