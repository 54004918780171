<mat-card>

  <admin-child-flags-table
      #table
      id="table"
      class="table"
      [selection]="selection"
      [columns]="['user','date','reason','status','actions']"
      (userIdClicked)="userClicked($event)">
  </admin-child-flags-table>

  <app-paginator #paginator [selection]="selection" storageKey="postdetail:flags">
  </app-paginator>
</mat-card>

<router-outlet></router-outlet>
