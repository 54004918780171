export interface ProfileStub {
  id?: string
  username?: string
  image?: string
  premium?: boolean
}

const baseProfileStub: object = {}

export const Profile = {
  createStub(props: Partial<ProfileStub> = {}): ProfileStub {
    const stub = { ...baseProfileStub } as ProfileStub
    if (props.id !== undefined && props.id !== null) {
      stub.id = props.id
    }
    if (props.username !== undefined && props.username !== null) {
      stub.username = props.username
    }
    if (props.image !== undefined && props.image !== null) {
      stub.image = props.image
    }
    if (props.premium !== undefined && props.premium !== null) {
      stub.premium = props.premium
    }
    return stub
  }
}

export class LegacyProfile implements ProfileStub {
  $key: string;
  created_at: number;
  username: string;
  thumb: string;
  original_thumb: string;
  post_count: number;
  following_count: number;
  follower_count: number;

  // ProfileStub
  get id(): string {
    return this.$key
  }
  set id(v: string) {
    this.$key = v
  }

  get image() {
    return this.thumb
  }
  set image(v: string) {
    this.thumb = v
  }
  premium?: boolean = undefined

  static create(key: string, item: any): LegacyProfile {
    const profile = new LegacyProfile()
    profile.$key = key
    profile.created_at = item['created_at']
    profile.username = item['username']
    profile.thumb = item['thumb']
    profile.original_thumb = item['original_thumb']
    profile.post_count = item['post_count']
    profile.following_count = item['following_count']
    profile.follower_count = item['follower_count']
    return profile
  }
}
