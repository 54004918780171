<mat-card>
  <app-table-toolbar [selection]="selection">
    <ng-container appWithEmptySelectionToolbar>
      <mat-chip-list>
        <mat-chip
          class="filter-picker-trigger"
          [matMenuTriggerFor]="presetMenu"
          [ngSwitch]="filter$ | async">
          <mat-icon>filter_list</mat-icon>
          <span *ngSwitchCase="'published'">Published</span>
          <span *ngSwitchCase="'deleted'">Deleted</span>
        </mat-chip>
      </mat-chip-list>
      <mat-menu #presetMenu="matMenu">
        <button mat-menu-item (click)="setFilter(Filter.PUBLISHED)">Published</button>
        <button mat-menu-item (click)="setFilter(Filter.DELETED)">Deleted</button>
      </mat-menu>
    </ng-container>

    <ng-container appWithSelectionToolbar>
      <button mat-raised-button *ngIf="canDeleteSelection" class="delete" color="warn" (click)="deleteSelectedPosts()">
        Delete {{ selection.selected.length }} Posts
      </button>
      <button mat-raised-button *ngIf="canRestoreSelection" class="restore" color="warn" (click)="restoreSelectedPosts()">
        Restore {{ selection.selected.length }} Posts
      </button>
    </ng-container>
  </app-table-toolbar>

  <app-posts-table
      #table
      id="table"
      class="table"
      [source]="dataSource"
      [selection]="selection"
      [sortColumns]="sortColumns"
      (postClicked)="postClicked($event)"
      (userIdClicked)="userClicked($event)">
  </app-posts-table>

  <app-paginator #paginator [source]="dataSource" [selection]="selection" storageKey="profiledetail:posts">
  </app-paginator>
</mat-card>

<router-outlet></router-outlet>
