import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { User } from '../shared/model/user';
import { LoginService } from '../shared/services/login.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  @ViewChild('drawer') drawer: MatSidenav;
  drawerClass: string;

  user: User;
  validAdmin: boolean;

  constructor(private router: Router,
              private route: ActivatedRoute,
              public afAuth: AngularFireAuth,
              private loginSvg: LoginService,
              private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.loginSvg.isAdmin.subscribe(isAdmin => {
      this.validAdmin = isAdmin;
      if (!isAdmin) {
        this.router.navigate(['login'], { relativeTo: this.route });
      } else {
        if (this.router.isActive('/admin/login', true)) {
          this.router.navigate(['landing'], { relativeTo: this.route });
        }
      }
    });
    this.loginSvg.user.subscribe(user => {
      this.user = user;
      if (user && !user.isAdmin) {
        this.afAuth.signOut()
          .then(() => {
            const config = new MatSnackBarConfig();
            config.duration = 2000;
            this.snackbar.open(`${user.user.displayName} is not an administrator.`, '', config)
          })
      }
    });
  }

  toggleMenu() {
    if (this.drawerClass === 'mat-sidenav-closed') {
      this.drawerClass = 'mat-sidenav-open';
    } else {
      this.drawerClass = 'mat-sidenav-closed';
    }
  }

  showMenu() {
    this.drawerClass = 'mat-sidenav-open';
  }

  openedChange(opened) {
    this.drawerClass = opened ? 'mat-sidenav-open' : 'mat-sidenav-closed';
  }

  login() {
    this.afAuth.signInWithPopup(new firebase.default.auth.GoogleAuthProvider());
  }

  logout() {
    this.afAuth.signOut();
  }

}
