import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmailsService } from '../../../admin/services/emails.service';
import { Email } from '../../model/email';
import { LegacyProfile } from '../../../shared/model/profile';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {

  profile: LegacyProfile;
  emails$: Observable<Email[]>;
  showAlert: boolean = false;
  showSuccessMsg: boolean = false;
  showFailMsg: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private emailSvc: EmailsService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.profile = this.data.profile;
    this.emails$ = this.emailSvc.emailsSentTo(this.profile.$key);

    this.emails$.subscribe((items) => {
      console.log("Found emails", items);

    })
  }


}
