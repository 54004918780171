<div mat-dialog-content class="emails">

<h2>
  {{ (emails$ | async)?.length || "No" }} Emails sent to: {{ profile.username }}
</h2>

<div *ngIf="emails$ | async as emails">

  <div *ngFor="let email of emails" class="email">
      <h3>Subject: {{email.subject}}</h3>
      <div class="sent-date">
         {{!!email.sent_date ? (email.sent_date.toDate() | date) : 'NOT SENT'}}
      </div>
      <div class="email-body" [innerHTML]="email.text"></div>
  </div>
</div>


</div>
