import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LegacyPost } from 'app/shared/model/post';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PostsService } from '../../../../shared/services/posts.service';
import { PaginatorComponent } from '../../common/paginator/paginator.component';
import { PostsDataSource } from '../../posts/posts.datasource';
import { PostsTableComponent } from '../../posts/table/table.component';

const Params = {
  filter: 'filter'
}

enum Filter {
  PUBLISHED = 'published',
  DELETED = 'deleted'
}

@Component({
  selector: 'admin-profile-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  providers: [PostsDataSource]
})
export class ProfilePostsComponent implements OnInit, OnDestroy {

  Filter = Filter

  filter$ = new BehaviorSubject<Filter>(Filter.PUBLISHED)
  sortColumns = ['created_at', 'comment_count', 'like_count', 'popularity']
  selection = new SelectionModel<LegacyPost>(true, [])
  @ViewChild('table', { static: true }) table: PostsTableComponent
  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent

  canDeleteSelection: boolean
  canRestoreSelection: boolean

  private unsubscribe = new Subject<void>()
  myUserId

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dataSource: PostsDataSource,
    private scroller: ViewportScroller,
    private postsService: PostsService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.dataSource.isFollowing = true
    if (this.route.snapshot.queryParamMap.get(Params.filter) === Filter.DELETED) {
      this.filter$.next(Filter.DELETED)
    }

    this.paginator.paginator.page.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.scroller.scrollToAnchor('table')
    })

    this.route.parent.paramMap
      .pipe(
        takeUntil(this.unsubscribe),
        map(params => params.get('userId'))
      )
      .subscribe(this.dataSource.userId$)

    this.filter$
      .pipe(
        takeUntil(this.unsubscribe),
        tap(value => {
          const queryParams: object = { ...this.route.snapshot.queryParams }

          if (value === Filter.PUBLISHED) {
            delete queryParams[Params.filter]
          } else {
            queryParams[Params.filter] = value
          }

          this.router.navigate([], {relativeTo: this.route, queryParams: queryParams})
        }),
        map(filter => filter === Filter.DELETED)
      )
      .subscribe(this.dataSource.deleted$)

    this.selection.changed
      .pipe(
        takeUntil(this.unsubscribe),
        tap(selection =>
          this.canDeleteSelection = selection.source.selected.every(post => !post.deleted_date)
        ),
        tap(selection =>
          this.canRestoreSelection = selection.source.selected.every(post => !!post.deleted_date)
        )
      )
      .subscribe()

    this.dataSource.data$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.cd.detectChanges())
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  setFilter(filter: Filter) {
    this.filter$.next(filter)
  }

  postClicked(post: LegacyPost) {
    this.router.navigate(['./admin/posts/' + post.$key + '/comments'])
  }

  userClicked(userId: string) {
    this.router.navigate(['./admin/profiles', userId]);
  }

  deleteSelectedPosts() {
    const posts = this.selection.selected
    if (posts === undefined || posts.length === 0) {
      console.error('Attempting to delete posts but none were selected.')
      return
    }

    let description: string
    if (posts.length > 1) {
      description = `${posts.length} selected posts`
    } else {
      description = 'the selected post'
    }
    if (confirm(`Are you sure you want to delete ${description}?`)) {
      Promise.all(posts.map(p => this.postsService.deletePost(p))).then(() => {
        this.selection.clear()
        this.dataSource.refresh()
      })
    }
  }

  restoreSelectedPosts() {
    const posts = this.selection.selected
    if (posts === undefined || posts.length === 0) {
      console.error('Attempting to restore posts but none were selected.')
      return
    }

    let description: string
    if (posts.length > 1) {
      description = `${posts.length} selected posts`
    } else {
      description = 'the selected post'
    }
    if (confirm(`Are you sure you want to restore ${description}?`)) {
      combineLatest(posts.map(post => this.postsService.restorePost(post.$key)))
        .subscribe({
          next: () => {
            // TODO Update a progress bar?
          },
          complete: () => {
            this.selection.clear()
            this.dataSource.refresh()
          },
          error: (err) => {
            alert(`There was an error restoring the posts: ${err['message'] ? err.message : err}`)
          }
        })
    }
  }
}
