import { AfterViewInit, Directive, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[appPersistentTableState]'
})
export class PersistentTableStateDirective implements OnInit, AfterViewInit, OnDestroy {

  static Errors = {
    missingSort: Error(`PersistentTableState must be placed on a MatTable component with a MatSort directive.`)
  }

  private unsubscribe = new Subject<void>()

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // Not really optional, but we do a manual check below to throw a better error message.
    @Optional() private sort: MatSort,
  ) {
    if (!sort) {
      throw PersistentTableStateDirective.Errors.missingSort
    }
  }

  ngOnInit(): void {
    this.initializeSortFromQuery()
  }

  ngAfterViewInit(): void {
    this.sort.sortChange.pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: sort => {
          const params = {}
          if (sort && sort.active && sort.direction) {
            params['sort[key]'] = sort.active
            params['sort[direction]'] = sort.direction
          } else {
            params['sort[key]'] = undefined
            params['sort[direction]'] = undefined
          }

          this.router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge',
            replaceUrl: true
          })
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  /**
   * Sets the initial sort based on the current query params.
   */
  private initializeSortFromQuery() {
    const params = this.route.snapshot.queryParamMap
    if (params.has('sort[key]')) {
      const sortable: MatSortable = {
        id: params.get('sort[key]'),
        start: params.get('sort[direction]') === 'desc' ? 'desc' : 'asc',
        disableClear: true
      }
      this.sort.sort(sortable)
    }
  }
}
