<div mat-dialog-content>
  <h2>{{(comments$ | async)?.length || "No"}} Comments by {{(profile$ | async)?.username}}</h2>

  <mat-expansion-panel class="deleted-comments" *ngIf="deletedComments$ | async as comments">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ comments.length || "" }} Deleted Comments
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-user-comment
      *ngFor="let comment of comments"
      [comment]="comment">
    </app-user-comment>
  </mat-expansion-panel>

  <app-user-comment
    *ngFor="let comment of comments$ | async"
    [comment]="comment">
  </app-user-comment>

</div>
