import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-login',
  template: `<mat-card>
    <mat-card-title>Login Required</mat-card-title>
    <mat-card-content>Log in to access the Pigment Management console.</mat-card-content>
  </mat-card>`,
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
