<h1>Welcome to the Admin Panel!</h1>

<div class="video-container">
    <iframe src="https://www.youtube.com/embed/-69rYBsC_jI"></iframe>
</div>
<hr>
<div class="sectionDiv">
    <h2>Where's My Dashboard?!</h2>
    <p class="attention">
        Looking for the latest analytics?
        Visit our <a href="https://app.amplitude.com/analytics/pixiteapps/space/9ihs5qd8/all" target="_blank">Amplitude Dashboard</a>
    </p>
</div>
