import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PostDetailDialogComponent } from '../../../shared/components/post-detail/post-detail-dialog.component';
import { LegacyPost } from '../../../shared/model/post';
import { PostsService } from '../../../shared/services/posts.service';
import { StatsService } from '../../services/stats.service';
import { Subscription, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent implements OnInit, OnDestroy {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['image', 'username', 'description', 'created_at',  'comment_count', 'like_count', 'popularity', 'remove'];
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)
  posts = new MatTableDataSource<LegacyPost>();
  subscription: Subscription;
  statsSubscription: any;


  constructor(private router: Router,
              private postsSvc: PostsService,
              private statsSvc: StatsService,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.getPosts();
  }

  ngOnDestroy() {
    // close any open dialogs when we navigate away from this page
    this.dialog.closeAll();
    this.subscription.unsubscribe();
  }

  private getPosts() {
    this.subscription = this.postsSvc.getRecommendedPosts()
      .subscribe(posts => {
        this.loading$.next(false)
        this.posts.data = posts;
      })
  }

  removeItem(post: LegacyPost) {
    this.postsSvc.unrecommendUser(post.owner_id);
  }

  postClicked(post: LegacyPost) {
    this.dialog.open(PostDetailDialogComponent, {
      data: {
              post: post,
              showingAdmin: false
            }
    });
  }

  userClicked(userId: String) {
     this.router.navigate(['./admin/profiles', userId]);
  }
}
