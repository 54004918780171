import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireAction, AngularFireDatabase, DatabaseSnapshot } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivityService } from 'app/admin/services/activity.service';
import { ZoneScheduler } from 'app/shared/services/ZoneScheduler';
import { combineLatest, Observable, OperatorFunction, SchedulerLike } from 'rxjs';
import { map, mergeMap, observeOn, tap } from 'rxjs/operators';
import { CountersService } from '../../admin/services/counters.service';
import { LegacyProfile } from '../model/profile';
import { SearchService } from './search.service';
import { environment } from 'environments/environment';

export interface ResumeKey {
  type: 'db' | 'search';

  [propName: string]: any;
}

export class DbResumeKey implements ResumeKey {
  type: 'db';

  constructor(
    public $key: string,
    public value: any
  ) {
  }
}

export class SearchResumeKey implements ResumeKey {
  type: 'search';

  constructor(public startLoc: number) {
  }
}

export interface ProfilesResponse {
  profiles: LegacyProfile[];
  resumeKey?: ResumeKey;
}

@Injectable()
export class ProfilesService {

  private searchIndex = `profiles${environment.elasticSearchSuffix}`;
  private readonly inAngularScheduler: SchedulerLike;

  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private auth: AngularFireAuth,
    private functions: AngularFireFunctions,
    private searchService: SearchService,
    private activityService: ActivityService,
    private countersService: CountersService,
    zone: NgZone
  ) {
    this.inAngularScheduler = new ZoneScheduler(zone);
    this.ref = db.database.ref('/profiles');
  }

  private ref: firebase.default.database.Reference;

  private mapProfile: OperatorFunction<AngularFireAction<DatabaseSnapshot<unknown>>, LegacyProfile> =
    map(item => LegacyProfile.create(item.key, item.payload.val() || {username: 'Profile Creation Blocked'}));

  findProfile(id: string): Observable<LegacyProfile> {
    return this.db.object(this.ref.child(id)).snapshotChanges().pipe(this.mapProfile);
  }

  getProfileAuthStatus(id): Observable<any> {
    const object = this.db.object(`/disabled-users/${id}`);
    return object.snapshotChanges().pipe(map(item => {
      return item.payload.val();
    }));
  }

  getProfileEmailAddress(id): Observable<string> {
    const callable = this.functions.httpsCallable(`getUserEmail`);
    return callable({uid: id}).pipe(observeOn(this.inAngularScheduler));
  }

  deleteProfilePic(id: string): Promise<void> {
    const object = this.db.object(`/profiles/${id}`);
    return object.update({original_thumb: '', thumb: ''})
      .catch(err => {
      console.error('Error deleting profile pic', err);
      });
  }

  disableProfile(id) {
    const object = this.db.object(`/disabled-users/${id}`);
    return this.auth.currentUser
      .then(user => object.set({date: Date.now(), user: user.uid}))
      .then(() => {
        this.activityService.createActivity('disable-user', { user_id: id }).toPromise();
      })
      .catch(error => {
        console.log('Error:', error);
      });
  }

  enableProfile(id) {
    const object = this.db.object(`/disabled-users/${id}`);
    object.remove()
      .then(() => {
        this.activityService.createActivity('enable-user', { user_id: id }).toPromise();
      })
      .catch(error => {
        console.log('Error:', error);
      })
  }

  getProfiles(pageSize: number = 25, resumeKey?: ResumeKey, filter?: string): Observable<ProfilesResponse> {
    if (filter) {
      return this.searchProfiles(filter, pageSize, resumeKey as SearchResumeKey);
    } else {
      return this.loadProfiles(pageSize, resumeKey as DbResumeKey);
    }
  }

  private loadProfiles(pageSize: number, resumeKey?: DbResumeKey): Observable<ProfilesResponse> {
    return this.db
      .list(this.ref, ref => {
        let r = ref.orderByChild('follower_count');
        if (resumeKey) {
          r = r.endAt(resumeKey.value, resumeKey.$key);
        }
        return r.limitToLast(pageSize + 1);
      })
      .snapshotChanges()
      .pipe(
        map(items => {
          const mappedItems = items.map(value =>
            LegacyProfile.create(value.key, value.payload.val() || {username: 'Profile Creation Blocked'})
          );

          // We only get a next key if there are more items to go
          let nextKey = null;
          if (items.length > pageSize) {
            const nextItem = mappedItems.shift();
            nextKey = new DbResumeKey(nextItem.$key, nextItem.follower_count || null);
          }

          return {
            profiles: mappedItems.reverse(),
            resumeKey: nextKey
          }
        })
      );
  }

  private searchProfiles(query: string, pageSize: number = 25, resumeKey?: SearchResumeKey): Observable<ProfilesResponse> {
    const queryData = {
      query: {
        bool: {
          "should": [
            {
              "multi_match": {
                "query": query,
                "fields": [ "username", "_id" ],
                "boost": 10.0
              }
            },
            {
              "query_string": {
                "query": query,
                "fields": [ "username", "_id" ],
                "boost": 5.0
              }
            },
            {
              "wildcard": {
                "username": {
                  "value": `${query}*`,
                  "boost": 3.0
                }
              }
            },
            {
              "wildcard": {
                "username": {
                  "value": `*${query}`,
                  "boost": 2.0
                }
              }
            },
            {
              "wildcard": {
                "username": {
                  "value": `*${query}*`,
                  "boost": 1.0
                }
              }
            },
            {
              "match": {
                "username": {
                  "query": query,
                  "fuzziness": "AUTO"
                }
              }
            }
          ]
        }
      },
      size: pageSize,
      from: resumeKey ? resumeKey.startLoc : 0
    }

    return this.searchService.search(this.searchIndex, queryData).pipe(
      map(results => {
        const total = results.hits.total.value;
        if (total === 0) {
          return { profiles: [] };
        }

        const count: number = results.hits.hits.length;
        const end: number = (resumeKey?.startLoc || 0) + count;
        const profiles = results.hits.hits.map(hit => LegacyProfile.create(hit._id, hit._source));
        return {
          profiles: profiles,
          resumeKey: total > end ? new SearchResumeKey(end) : null
        }
      })
    );
  }

  tryBlockedProfiles(userID) {

    console.log('Looking for blocking users for: ', '/user-blocked/' + userID);

    const blockedRef = this.db.database.ref('/user-blocked').child(userID);
    this.db.list(blockedRef, ref => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        tap(result => console.log('test', result)),
        mergeMap(items => {
          console.log('Found Blocked Users: ', items);

          const arrayOfObservables = items.map(item =>
            this.db.object(this.ref.child(item.key)).snapshotChanges().pipe(this.mapProfile)
          );

          return combineLatest(arrayOfObservables);
        })
      ).subscribe(result => {

      console.log('Result: ', result);
    });
  }

  getBlockedProfiles(userId): Observable<LegacyProfile[]> {
    return this.db
      .list(`/user-blocked/${userId}`, ref => ref.orderByKey())
      .snapshotChanges()
      .pipe(
        map(snapshots => snapshots.map(it => it.key)),
        mergeMap(keys => combineLatest(keys.map(k => this.findProfile(k))))
      );
  }

  getBlockingProfiles(userId): Observable<LegacyProfile[]> {
    return this.db.list(`/user-blocking/${userId}`)
      .snapshotChanges()
      .pipe(
        map(snapshots => snapshots.map(it => it.key)),
        mergeMap(keys => combineLatest(keys.map(k => this.findProfile(k))))
      );
  }

  getCommentCount(userId: string): Observable<number> {
    return this.countersService.getCount(
      'comment',
      this.firestore.collection('profiles_meta').doc(userId).collection('counters')
    );
  }
}
