import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-tabs',
  template: '<ng-content></ng-content>',
  host: {
    'class': 'app-info-tabs'
  },
  styles: [`
    :host {
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-sizing: border-box;
      position: relative;
    }
  `]
})
export class InfoTabsComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

@Component({
  selector: 'app-info-item',
  template: `
    <div mat-ripple [matRippleDisabled]="disabled">
      <p class="app-info-item-value">{{ value || "0"}}</p>
      <p class="app-info-item-name">{{ name }}</p>
    </div>
  `,
  host: {
    'class': 'app-info-item',
    '[attr.disabled]': 'disabled || null',
    '[attr.selected]': 'selected || null'
  },
  styles: [
    `:host {
      flex-grow: 1;
      flex-basis: 0;
    }`,
    `div {
      padding: 0 10px;
      border-radius: 8px;
      transition: 0.3s;
    }`,
    `div:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, 0.03);
    }`,
    `:host[disabled] div:hover {
      cursor: default;
      background-color: rgba(0, 0, 0, 0);
    }`,
    `:host[selected] div {
      background-color: rgba(0, 0, 0, 0.1);
    }`,
    `p.app-info-item-value {
      font-size: 1.3em;
      margin-bottom: -10px;
    }`
  ],
  inputs: ['disabled', 'selected']
})
export class InfoItemComponent implements OnInit {

  @Input() selected: boolean
  @Input() name: string
  @Input() value: number

  constructor(
    private _element: ElementRef<HTMLElement>
  ) {
  }

  ngOnInit(): void {
  }

  @Input()
  get enabled() {
    return !this.disabled
  }

  set enabled(value: boolean) {
    this.disabled = !value
  }

  @Input()
  get disabled() {
    return this._disabled || this.value === 0
  }

  set disabled(value: boolean) {
    this._disabled = value != null && `${value}` !== 'false'
  }

  private _disabled = true

  _getHostElement(): HTMLElement {
    return this._element.nativeElement
  }
}
