import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {LegacyPost} from '../../model/post';
import { PostsService } from '../../services/posts.service';
import { take, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { CommentsService } from '../../services/comments.service';

@Component({
  selector: 'app-post-detail-dialog',
  template: `
    <div mat-dialog-content>
      <app-post-detail *ngIf="post" [post]="post" [selectedCommentID]="selectedCommentID"></app-post-detail>
    </div>
  `,
  styles: [`
    .mat-dialog-content {
      max-height: 85vh;
    }
  `]
})
export class PostDetailDialogComponent implements OnInit {

  post: LegacyPost;
  selectedCommentID: string;

  constructor(
    private postService: PostsService,
    private commentService: CommentsService,
    @Inject(MAT_DIALOG_DATA) private data: {post: LegacyPost | string, commentPath?: string, selectedCommentID?: string}
  ) { }

  ngOnInit() {
    this.selectedCommentID = this.data.selectedCommentID
    if (typeof this.data.post === 'string') {
      this.postService.findPost(this.data.post).pipe(
        switchMap(post => post ? of(post) : this.postService.findDeletedPost(this.data.post as string)),
        take(1)
      ).subscribe(post => {
        this.post = post
      })
    } else {
      this.post = this.data.post
    }
  }

}
