import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { LegacyPost } from '../../../shared/model/post';
import { LegacyProfile } from '../../../shared/model/profile';
import { MagicImagePipe } from '../../../shared/pipes/magic-image.pipe';
import { PostsService } from '../../../shared/services/posts.service';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { EmailComposeComponent } from '../email-compose/email-compose.component';
import { NotesComponent } from '../notes/notes.component';
import { FlagsService } from 'app/admin/services/flags.service';
import { Flagged } from 'app/admin/model/flag';


@Component({
  selector: 'app-post-actions',
  templateUrl: './post-actions.component.html',
  styleUrls: ['./post-actions.component.scss']
})
export class PostActionsComponent implements OnInit {

  flagged$: Observable<Flagged>;

  deletedStatus: string;

  @Input() post: LegacyPost;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private postsService: PostsService,
    private flagsService: FlagsService,
    private profilesService: ProfilesService,
  ) { }

  ngOnInit(): void {
    this.flagged$ = this.flagsService.findFlagged(this.post.$key);

    if (this.post.deleted_date) {

      const date =  new Date(this.post.deleted_date);
      this.deletedStatus = 'Deleted on: ' + date.toLocaleDateString();

      if (this.post.deleted_user) {
        this.profilesService.findProfile(this.post.deleted_user).pipe(first()).subscribe((profile: LegacyProfile) => {
          this.deletedStatus = 'Deleted on: ' + date.toLocaleDateString() + ' by ' + profile.username;
        });
      }
    }
  }

  userClicked(userID: String) {
    this.router.navigate(['./admin/profiles', userID]);
  }

  markAsResolved(flagged) {
    if (confirm('Are you sure to mark these flags as resolved?')) {
      this.flagsService.resolveFlagged(this.post.$key);
      this.dialog.closeAll();
    }
  }

  deletePost(flagged) {
    if (confirm('Are you sure you want to delete this post?')) {
      this.postsService.deletePost(this.post);
      this.dialog.closeAll();
    }
  }

  sendUserEmail() {
    if (this.post.owner_id == null) {
      return;
    }

    var imageURL = new MagicImagePipe().transform(this.post.image, 400);

    var data =  {
      profileId: this.post.owner_id,
      subject: 'Pixite Gallery - Your Post',
      text: `Hi ${this.post.owner_username},<br/><br/>Regarding your post: <br/><a href="https://app.pigmentapp.co/posts/${this.post.$key}">https://app.pigmentapp.co/posts/${this.post.$key}<p><img src="${imageURL}"></p></a>`
    }

    EmailComposeComponent.openWindow(data);
  }

  openNotes() {

    if (this.post.owner_id == null) {
      return;
    }

    var dateString = new Date().toDateString();

    var data =  {
      profileId: this.post.owner_id,
      text: `---<br/>Note on ${dateString} re post: <a href="https://app.pigmentapp.co/posts/${this.post.$key}">https://app.pigmentapp.co/posts/${this.post.$key}</a>`
    }

    NotesComponent.openWindow(data);
  }

  sendFlagEmail(flag) {

    if (flag.user_id == null) {
      return;
    }

    var data =  {
      profileId: flag.user_id,
      subject: 'Pigment Gallery - Your Report',
      text: `Hi ${flag.username},<br/><br/>Regarding your report on post: <br/><a href="https://app.pigmentapp.co/posts/${this.post.$key}">https://app.pigmentapp.co/posts/${this.post.$key}</a>`
    }

    EmailComposeComponent.openWindow(data);
  }

}
