<app-profile-image [imageUrl]="comment?.owner_thumb"></app-profile-image>
<a class="username" [routerLink]="['/admin/profiles', comment.owner_user_id]">
  {{ comment.owner_username }}
</a>
<a class="message" [routerLink]="['/admin/posts', comment.postID]">
  {{ comment.message }}
</a>

<div class="actions">
  <mat-icon (click)="deleteComment()">delete_forever</mat-icon>
  <mat-icon (click)="sendUserEmail()">mail_outline</mat-icon>
</div>
