import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { GalleryAdminUsersService } from './gallery-admin-users.service';

@Injectable({
    providedIn: "root"
})
export class GalleryAdminUsersResolver implements Resolve<string[] | boolean> {
    constructor(
        private galleryAdminUsersSvc: GalleryAdminUsersService) {}
   
    resolve(route: ActivatedRouteSnapshot, 
            state: RouterStateSnapshot): Observable<string[] | boolean> {
        return this.galleryAdminUsersSvc.allowLinksUserIds$.pipe(first());
    }
}
