import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { map, of, Observable } from 'rxjs';
import { PostsService } from './posts.service';
import { first } from 'rxjs/operators';

@Injectable({
    providedIn: "root"
})
export class PostTitlesResolver implements Resolve<string> {
    
    constructor(
        private postSvc: PostsService) {}
   
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): string | Observable<string> | Promise<string> {
        const postId = route.params['postId'];

        if (!postId) {
            return of('Post Page - No Title - Pigment');
        }
        return this.postSvc.findPost(postId).pipe(
            first(),
            map(p => `Post by ${p.owner_username} on Pigment`)
        )
    }
}
