<div class="note-compose">
  <h2>Notes on {{ profile?.username }}</h2>
  <app-alert-message [successMsg]="'Note was successfully saved to database'"></app-alert-message>
  <form class="note-form">
    <quill-editor class="note-text note-full-width" placeholder="Note Text" [(ngModel)]="text" name="text" [style]="{height: '400px'}"></quill-editor>
  </form>
  <br/>
  <button mat-raised-button color="primary" (click)="savePressed()">Save</button>
  <button mat-raised-button color="secondary" (click)="closeWindow()">Close</button>
</div>
