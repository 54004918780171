import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (!value) {
      return '';
    }

    const tmp = document.createElement('div');
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText || value.toString();
  }

}
