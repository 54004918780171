
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { InfoItemComponent, InfoTabsComponent } from 'app/shared/components/profile-detail/info-tabs/info-tabs.component';
import { PostGridComponent } from 'app/shared/components/profile-detail/post-grid/post-grid.component';
import { ProfileImageComponent } from 'app/shared/components/profile-image/profile-image.component';
// these are defined in this module becuase they are used by PostDetail and ProfileDetail components.
import { CommentAdminComponent } from '../admin/components/comment-admin/comment-admin.component';
import { DependenciesModule } from '../dependencies.module';
import { CommentComponent } from './components/comment/comment.component';
import { GalleryPageComponent } from './components/gallery-page/gallery-page.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostDetailPageComponent } from './components/post-detail-page/post-detail-page.component';
import { PostDetailDialogComponent } from './components/post-detail/post-detail-dialog.component';
import { PostDetailComponent } from './components/post-detail/post-detail.component';
import { PostDialogRouterComponent } from './components/post-detail/post-dialog-router.component';
import { PostThumbDetailComponent } from './components/post-thumb-detail/post-thumb-detail.component';
import { ProfileDetailComponent } from './components/profile-detail/profile-detail.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { RelatedPostsComponent } from './components/related-posts/related-posts.component';
import { AdminGuard } from './guards/admin.guard';
import { MagicImagePipe } from './pipes/magic-image.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { CommentsService } from './services/comments.service';
import { LoginService } from './services/login.service';
import { OsService } from './services/os.service';
import { PostsService } from './services/posts.service';
import { PostCommentsResolver } from './services/post-comments.resolver';
import { PostTitlesResolver } from './services/post-titles.resolver';
import { GalleryAdminUsersService } from './services/gallery-admin-users.service';
import { GalleryAdminUsersResolver } from './services/gallery-admin-users.resolver';
import { ProfilesService } from './services/profiles.service';
import { SearchService } from './services/search.service';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';

@NgModule({
  declarations: [
    PageNotFoundComponent,
    RedirectComponent,
    GalleryPageComponent,
    RelatedPostsComponent,
    ProfileDetailComponent,
    PostThumbDetailComponent,
    PostDetailComponent,
    PostDetailPageComponent,
    PostDetailDialogComponent,
    PostDialogRouterComponent,
    CommentComponent,

    RelativeDatePipe,
    MagicImagePipe,

    CommentAdminComponent,
    HeaderBarComponent,
    InfoTabsComponent,
    InfoItemComponent,
    PostGridComponent,
    ProfileImageComponent,
    AlertMessageComponent
  ],
  imports: [
    CommonModule,
    DependenciesModule,
    MatRippleModule,
  ],
  exports: [
    DependenciesModule,

    AlertMessageComponent,
    PageNotFoundComponent,
    RedirectComponent,
    GalleryPageComponent,
    RelatedPostsComponent,
    ProfileDetailComponent,
    PostThumbDetailComponent,
    PostDetailComponent,
    PostDetailPageComponent,
    PostDetailDialogComponent,
    PostDialogRouterComponent,
    CommentComponent,

    RelativeDatePipe,
    MagicImagePipe,

    CommentAdminComponent,
    InfoTabsComponent,
    InfoItemComponent,
    PostGridComponent,
    ProfileImageComponent
  ]
})

  export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
      return {
        ngModule: SharedModule,
        providers: [
          AdminGuard,
          ProfilesService,
          LoginService,
          PostsService,
          PostCommentsResolver,
          PostTitlesResolver,
          CommentsService,
          GalleryAdminUsersService,
          GalleryAdminUsersResolver,
          SearchService,
          OsService
        ]
      };
    };
  }
