<table mat-table
  [dataSource]="source"
  matSort
  matSortActive="created_at"
  matSortDirection="desc"
  appPersistentTableState
  #sort="matSort">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="post">
    <th mat-header-cell *matHeaderCellDef>Image</th>
    <td mat-cell *matCellDef="let comment">
      <img *ngIf="comment.post.image else placeholder" class="list-thumb" src="{{ comment.post.image }}" />
      <ng-template #placeholder>
        <img class="list-thumb" src="../../../assets/img/profile_placeholder.jpg" />
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="actor">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="actor.username">Actor</th>
    <td mat-cell *matCellDef="let comment">
      <a (click)="onActorClicked($event, comment.actor)">
        <app-avatar-username-inline size="small" [user]="comment.actor"></app-avatar-username-inline>
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="message">
    <th mat-header-cell *matHeaderCellDef>Message</th>
    <td mat-cell *matCellDef="let comment"> {{comment.message}} </td>
  </ng-container>

  <ng-container matColumnDef="created_at">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="created_at">Created</th>
    <td mat-cell *matCellDef="let comment"> {{comment.createdAt | date: 'short'}} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let comment">
      <button mat-icon-button (click)="onEmailClicked(comment)">
        <mat-icon>email</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;"
      class="comment-row"
      (click)="onCommentClicked($event, row)"></tr>
</table>
