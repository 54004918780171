import { Activity } from './activity';
import { PostStub } from './post';
import { ProfileStub } from './profile';

const TYPE = 'comment'

export interface Comment extends Activity {
  post: PostStub;
  message: string;
}

const baseComment: object = {
  type: TYPE,
  post: {
    id: '',
    image: '',
    originalImage: '',
    owner: {
      id: '',
      username: '',
      image: ''
    }
  },
  message: ''
}

export const Comment = {
  type: TYPE,

  new(id: string | undefined, actor: ProfileStub, post: PostStub, message: string, createdAt?: Date): Comment {
    return this.create({
      type: TYPE,
      id: id,
      actor: actor,
      post: post,
      message: message,
      createdAt: createdAt
    })
  },

  create(props: Partial<Comment>): Comment {
    if (props.type === undefined || props.type === null) {
      props.type = TYPE
    }

    const comment = { ...baseComment, ...Activity.create(props) } as Comment
    if (props.post !== undefined && props.post !== null) {
      comment.post = props.post;
      comment.targetId = props.post.id;
      comment.targetUserId = props.post.owner?.id;
    }
    if (props.message !== undefined && props.message !== null) {
      comment.message = props.message;
    }
    return comment;
  }
}

export class LegacyComment {
  $key: string;
  postID?: string;
  created_at: number;
  message: string;
  owner_user_id: string;
  owner_username: string;
  owner_thumb: string;
}

export class LegacyCommentBuilder {
  private comment = new LegacyComment()

  constructor($key: string = null) {
    this.comment.$key = $key
  }

  postID(value: string): LegacyCommentBuilder {
    this.comment.postID = value
    return this
  }
  created_at(value: number): LegacyCommentBuilder {
    this.comment.created_at = value
    return this
  }
  message(value: string): LegacyCommentBuilder {
    this.comment.message = value
    return this
  }
  owner_user_id(value: string): LegacyCommentBuilder {
    this.comment.owner_user_id = value
    return this
  }
  owner_username(value: string): LegacyCommentBuilder {
    this.comment.owner_username = value
    return this
  }
  owner_thumb(value: string): LegacyCommentBuilder {
    this.comment.owner_thumb = value
    return this
  }

  build(): LegacyComment {
    return this.comment
  }
}
