import {Component, EventEmitter, Input, Output} from '@angular/core';
import {LegacyPost} from 'app/shared/model/post';

@Component({
  selector: 'app-post-grid',
  templateUrl: './post-grid.component.html',
  styleUrls: ['./post-grid.component.scss']
})
export class PostGridComponent {

  @Input() posts: LegacyPost[] = []
  @Input() hasMorePosts: boolean = false

  @Output() showMore: EventEmitter<number> = new EventEmitter<number>()
  @Output() selected: EventEmitter<LegacyPost> = new EventEmitter<LegacyPost>()

  private limit = 18

  constructor() { }

  showMoreClicked() {
    this.limit += 18
    this.showMore.emit(this.limit)
  }
}
