import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { LegacyComment } from '../model/comment';
import { LegacyPost } from '../model/post';
import { PostsService } from './posts.service';
import { CommentsService } from './comments.service';
import { GalleryAdminUsersService } from './gallery-admin-users.service';
import { Observable, map, forkJoin, first } from 'rxjs';

@Injectable({
    providedIn: "root"
})

export class PostCommentsResolver implements Resolve<any> {
  post$: Observable<LegacyPost>;
  comments$: Observable<LegacyComment[]>;
  allow_links_uids$: Observable<string[]>;
  combinedResults$: any;
  
  constructor(
    private postsSvc: PostsService,
    private commentsSvc: CommentsService,
    private galleryAdminUsersSvc: GalleryAdminUsersService
  ) { }

  resolve(route: ActivatedRouteSnapshot, 
          state: RouterStateSnapshot): any {
    const postId = route.params['postId'];
    
    if (postId && postId != 'undefined') { 
        this.post$ = this.postsSvc.findPost(postId).pipe(first());
        this.comments$ = this.commentsSvc.getComments(postId, 15).pipe(first());
        this.allow_links_uids$ = this.galleryAdminUsersSvc.allowLinksUserIds$.pipe(first());
    }
    this.combinedResults$ = [this.post$, this.comments$, this.allow_links_uids$];
    let join = forkJoin(this.combinedResults$)
        .pipe(
            map((data: any[]) => {
                return {
                    post: data[0],
                    comments: data[1],
                    allow_links_uids: data[2]
                }
            })
        );
    return join;
  }
}
