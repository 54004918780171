import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UserCommentsComponent} from 'app/admin/components/user-comments/user-comments.component';
import {Location} from '@angular/common';
import {map} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-user-comments-router',
  template: '',
  styles: ['']
})
export class UserCommentsRouterComponent implements OnDestroy {

  private closeSubscription: Subscription
  private dialogRef?: MatDialogRef<UserCommentsComponent>

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog)
  {
    const userId = route.snapshot.parent.params["userId"]
    this.dialogRef = this.dialog.open(UserCommentsComponent, {
      width: '600px',
      data: {
        userId: userId
      }
    })

    this.closeSubscription = this.dialogRef.afterClosed().subscribe(result => {
      this.location.back()
    })
  }

  ngOnDestroy(): void {
    this.closeSubscription.unsubscribe()
    this.dialogRef?.close()
  }
}
