import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface GalleryAdminUser {
  id: string;
  allow_links: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GalleryAdminUsersService {
  private galleryAdminUsersCollection: AngularFirestoreCollection<GalleryAdminUser>;
  public allowLinksUserIds$: Observable<string[]>;
  public galleryAdminUsers$: Observable<GalleryAdminUser[]>;

  constructor(private firestore: AngularFirestore) {
    this.galleryAdminUsersCollection = this.firestore.collection<GalleryAdminUser>('gallery_admin_users');
    this.galleryAdminUsers$ = this.galleryAdminUsersCollection.snapshotChanges()
        .pipe(
            map((actions: DocumentChangeAction<GalleryAdminUser>[]) => {
                return actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return {id, ...data};
                });
            })
        );
    this.allowLinksUserIds$ = this.populateAllowLinksUsersArray();
  }

  populateAllowLinksUsersArray() {
    return this.galleryAdminUsers$.pipe(
      map(links_users => links_users.filter(lu => lu.allow_links === true)),
      map(links_ids => links_ids.map(lu => lu.id)));
  }

  checkAllowLinksPermissions(userIdToCheck: string): Observable<boolean> {
    return this.allowLinksUserIds$.pipe(
      map(allow_links_users => allow_links_users.includes(userIdToCheck))); 
  }
}
