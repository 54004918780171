<div class="recommendations mat-elevation-z4">
  <mat-table #table class="hasMenu" [dataSource]="posts">

    <ng-container matColumnDef="image">
      <mat-header-cell *matHeaderCellDef>Image</mat-header-cell>
      <mat-cell *matCellDef="let post" (click)="postClicked(post)"><img class="list-thumb pointer" src="{{post.image}}" /></mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
      <mat-cell *matCellDef="let post" (click)="userClicked(post.owner_id)" class="pointer"> {{post.owner_username}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let post"> {{post.description}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="created_at">
      <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
      <mat-cell *matCellDef="let post"> {{post.created_at | date:'medium'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="comment_count">
      <mat-header-cell *matHeaderCellDef>Comments</mat-header-cell>
      <mat-cell *matCellDef="let post"> {{post.commentCount}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="like_count">
      <mat-header-cell *matHeaderCellDef>Likes</mat-header-cell>
      <mat-cell *matCellDef="let post"> {{post.like_count}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="popularity">
      <mat-header-cell *matHeaderCellDef>Popularity</mat-header-cell>
      <mat-cell *matCellDef="let post"> {{post.popularity | number:'1.3-3'}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>Remove</mat-header-cell>
      <mat-cell *matCellDef="let post"><button mat-icon-button color="warn" (click)="removeItem(post)" ><mat-icon>remove_circle_outline</mat-icon></button></mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <div class="spinner-container" *ngIf="loading$ | async">
    <mat-spinner></mat-spinner>
  </div>

</div>
