<div class="posts">
  <mat-card>
    <mat-toolbar>
      <mat-chip-list>
        <mat-chip [matMenuTriggerFor]="presetMenu">Presets</mat-chip>
      </mat-chip-list>
      <mat-menu #presetMenu="matMenu">
        <button *ngFor="let preset of presets" mat-menu-item (click)="selectPreset(preset)">{{ preset.viewValue }}</button>
      </mat-menu>

      <span class="spacer"></span>
      <button mat-icon-button
          (click)="toggleFollowingUpdates()"
          aria-label="Profile actions menu button">
          <mat-icon *ngIf="isFollowingUpdates; else start_following">pause</mat-icon>
          <ng-template #start_following>
            <mat-icon>play_arrow</mat-icon>
          </ng-template>
      </button>
    </mat-toolbar>

    <app-posts-table
        #table
        id="table"
        class="table"
        [source]="dataSource"
        [selection]="selection"
        (postClicked)="postClicked($event)"
        (userIdClicked)="userClicked($event)">
    </app-posts-table>

    <app-paginator #paginator [source]="dataSource" [selection]="selection" storageKey="posts"></app-paginator>
  </mat-card>
</div>

<!-- <router-outlet></router-outlet> -->
