import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction, DocumentData, Query } from '@angular/fire/compat/firestore';
import { ActivityService, COLLECTION_NAME, fromFirestore } from 'app/admin/services/activity.service';
import { applyFirestoreRequest, FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AdminActivity } from '../../model/admin-activity';
import { ActivityListRequest } from './activity.types';

@Injectable()
export class ActivityDataSource extends FirestoreDataSource<ActivityListRequest, AdminActivity> {

  types$ = new BehaviorSubject<string[]>([]);
  users$ = new BehaviorSubject<string[]>([]);

  constructor(
    private firestore: AngularFirestore,
    private activityService: ActivityService,
  ) {
    super();
  }

  defaultArgs(): Observable<ActivityListRequest> {
    return combineLatest([this.users$, this.types$]).pipe(
      map(([users, types]) => ({
        userIds: users.length > 0 ? users : undefined,
        types: types.length > 0 ? types : undefined
      }))
    );
  }

  mapResponse(response: DocumentChangeAction<unknown>[]): AdminActivity[] {
    return response.map(change => fromFirestore(change.payload.doc));
  }

  executeQuery(request: ActivityListRequest): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(
      COLLECTION_NAME,
      ref => {
        let query: Query<DocumentData> = ref;

        if (request.userIds?.length === 1) {
          query = query.where('actor.id', '==', request.userIds[0]);
        }

        if (request.types?.length >= 1) {
          query = query.where('type', 'in', request.types);
        }

        query = query.limit(100);

        return applyFirestoreRequest(query, request);
      }
    ).snapshotChanges();
  }

  totalItems(): Observable<number> {
    return this.defaultArgs().pipe(
      switchMap(request => {
        if (!request.types || request.types.length === 0) {
          if (!request.userIds || request.userIds.length === 0) {
            // If we're not filtering for types or users, we simply get the global count
            return this.activityService.getActivityCount();
          } else if (request.userIds.length === 1) {
            // If we're only fitlering for a single user, we get that user's count
            return this.activityService.getActivityCount(request.userIds[0]);
          }
        }

        // Worst case we have to count the results of the query
        return this.executeQuery(request).pipe(
          map(docs => docs.length)
        )
      })
    );
  }
}
