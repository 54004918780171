import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {pluck} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private basePath = "gallery"

  constructor(private firestore: AngularFirestore) {
  }

  config(name: string): Observable<unknown> {
    return this.firestore.collection("_config").doc(this.basePath).valueChanges()
      .pipe(pluck(name))
  }
}
