<div *ngIf="profile$ | async as profile">
  <div class="profile-header">
    <img class="thumb" src="{{ profile.thumb }}"/>
    <div class="detail-container">
      <h2 class="username"><span class="at">@</span>{{ profile.username }}</h2>
      <div class="detail">Formerly Known As: {{ (previousUsernames$ | async)?.join(', ') }}</div>
      <div class="detail">User Id: {{ profile.$key }}</div>
      <div class="detail">
        Device Id: <a [routerLink]="[]" (click)="showLinkedProfiles()">{{ deviceId$ | async }}</a>
      </div>
      <div class="detail" *ngIf="shouldDisplayEmail$ | async">
        Email: <app-email-reveal [userId]="profile.$key"></app-email-reveal>
      </div>
      <div class="detail">Member Since: {{ profile.created_at | date:'short' }}</div>
      <div class="detail">
        Status:
        <ng-container *ngIf="profileStatus$ | async as status; else statusUnknown">
          <ng-container *ngIf="status.userBlocked && status.deviceBlocked">User and Device Disabled</ng-container>
          <ng-container *ngIf="status.userBlocked && !status.deviceBlocked">User Disabled</ng-container>
          <ng-container *ngIf="!status.userBlocked && status.deviceBlocked">Device Disabled</ng-container>
          <ng-container *ngIf="!status.userBlocked && !status.deviceBlocked">Enabled</ng-container>
        </ng-container>
        <ng-template #statusUnknown>Unknown</ng-template>
      </div>
    </div>

    <app-manage-profile-menu [profile]="profile$ | async" aria-label="Profile actions menu button">
      <mat-icon>more_vert</mat-icon>
    </app-manage-profile-menu>
  </div>

  <nav mat-tab-nav-bar>
    <a mat-tab-link
        [routerLink]="'posts'"
        routerLinkActive #publishedActive="routerLinkActive"
        [active]="publishedActive.isActive">
        {{ profile.post_count }} Published
    </a>
    <a mat-tab-link
        [routerLink]="'comments'"
        routerLinkActive #commentsActive="routerLinkActive"
        [active]="commentsActive.isActive">
        {{ commentCount$ | async }} Comments
    </a>
    <a mat-tab-link disabled>{{ profile.follower_count }} Followers</a>
    <a mat-tab-link disabled>{{ profile.following_count }} Following</a>

    <a mat-tab-link
        (click)="showBlockedUsers()">
        {{ (blockedCount$ | async) || 0 }} Blocked
    </a>
    <a mat-tab-link
        (click)="showBlockingUsers()">
        {{ (blockingCount$ | async) || 0 }} Blocking
    </a>
    <a mat-tab-link
        (click)="showLinkedProfiles()">
        {{ (linkedProfileCount$ | async) || 0 }} Linked Profiles
    </a>
  </nav>

  <router-outlet></router-outlet>
</div>
