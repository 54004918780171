<div class="post-holder">
  <div class="post">
    <div class="profileInfo pointer" cdkFocusInitial>
      <div (click)="userClicked(post.owner_id)">
        <a target="_blank">
          <img class="profileImage" src="{{ post.owner_thumb | magicImage:100 }}"
              onError="this.src='/assets/img/profile_placeholder.jpg';"/>
        </a>
        <div class="profileName">{{ post.owner_username }}</div>
      </div>

      <button  *ngIf="admin$ | async" mat-stroked-button class="post-admin-button" (click)="managePost(post)">
        Manage Post
      </button>
    </div>

    <div class="columnHolder">
      <div class="leftColumn">
        <img class="postImage" src="{{ post.image | magicImage:1000 }}"/>
      </div>

      <div class="rightColumn">

        <button *ngIf="admin$ | async" mat-icon-button class="admin-button" (click)="toggleAdmin()">
          <mat-icon>settings_power</mat-icon>
        </button>

        <div class="image-footer">

          <p class="counts">
            <img src="/assets/img/comments_icon.png" alt="comments"/>{{post.comment_count || "0"}}
            &nbsp;
            <img src="/assets/img/likes_icon.png" alt="likes"/>{{post.like_count || "0"}}
          </p>
        </div>

        <div class="scrollContainer">
          <div class="profileInfo pointer hideOnNarrow" cdkFocusInitial>
            <div (click)="userClicked(post.owner_id)">
              <a target="_blank">
                <img class="profileImage" src="{{ post.owner_thumb | magicImage:100 }}"
                    onError="this.src='/assets/img/profile_placeholder.jpg';"/>
              </a><br/>
              <div class="profileName">{{ post.owner_username }}</div>
            </div>
            <button  *ngIf="admin$ | async" mat-stroked-button class="post-admin-button" (click)="managePost(post)">
              Manage Post
            </button>
            <br/>
          </div>
            
          <div id="coloringPageNarrowScreen" class="coloring-page" *ngIf="coloringPageDetails$ | async as coloringPageDetails">
            <div class="buttons">
              <a [href]="coloringPageDetails.path" class="button primary" role="button" (click)="trackColoringPageClick($event)">View the uncolored version</a>
            </div>
          </div>
         
          <div class="comments">
            <app-comment *ngIf="firstComment$ | async as comment" [comment]="comment"></app-comment>
            <div class="img-attribution" *ngIf="post.image_attribution">
              Attribution: {{ post.image_attribution }}
            </div>
            <app-comment
              *ngFor="let comment of comments$ | async"
              [comment]="comment"
              [showingAdmin]="showingAdmin"
              [postID]="post.$key"
              [isSelected]="comment.$key === selectedCommentID"></app-comment>
            <div class="moreButton" *ngIf="(comments$ | async)?.length == (commentLimit$ | async)">
              <a (click)="showMoreComments()">Read More <img src="/assets/img/chevron_down.png"/></a>
            </div>
            <div class="comment-admin" *ngIf="showingAdmin">
              <app-comment-admin [postId]="post.$key"></app-comment-admin>
            </div>
          </div>

          <div *ngIf="deletedComments$ | async as comments">
            <div class="deleted-comments comments" *ngIf="comments.length > 0">
              <h2>Deleted Comments:</h2>
              <app-comment *ngFor="let comment of comments" [comment]="comment"></app-comment>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="coloringPageWideScreen" class="coloring-page" *ngIf="coloringPageDetails$ | async as coloringPageDetails">
      <div class="buttons">
        <a [href]="coloringPageDetails.path" class="button primary" role="button" (click)="trackColoringPageClick()">View the uncolored version</a>
      </div>
    </div>

    </div>
</div>
