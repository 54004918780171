import { CollectionViewer } from '@angular/cdk/collections'
import { DataSource as CdkDataSource } from '@angular/cdk/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { Search } from 'app/shared/services/search.service'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import { map, switchMap, takeUntil, tap } from 'rxjs/operators'
import { DataSource } from './firestore-data-source'

export class ElasticSearchRequest {
  // filter
  queryString?: string

  // sort
  orderBy?: string
  orderDir?: 'desc' | 'asc'

  // pagination
  pageIndex?: number
  pageSize?: number

  apply(query: any = {}): any {
    if (this.orderBy && this.orderBy.length > 0) {
      const filter = {}
      filter[this.orderBy] = this.orderDir || 'desc'

      query.sort = [
        filter,
        '_score'
      ]
    }

    if (this.pageSize) {
      query.size = this.pageSize

      if (this.pageIndex) {
        query.from = this.pageIndex * this.pageSize
      }
    }

    return query
  }
}

export abstract class ElasticSearchDataSource<Request extends ElasticSearchRequest, DataType = any>
  extends DataSource<DataType> {
  // This simply allows observers to be re-triggered when
  //  internal state changes, like a paginator or sorter
  //  is changed.
  private internalStateSubject = new BehaviorSubject<void>(null)

  private currentPage = 0
  private currentPageSize = 10
  private currentTotal = new BehaviorSubject(0)

  get paginator() {
    return this._paginator
  }
  set paginator(value: MatPaginator) {
    if (this._paginator) {
      this._paginator.page.unsubscribe()
    }
    this._totalSubscription?.unsubscribe()

    this._paginator = value
    this.internalStateSubject.next()
    this._totalSubscription = this.totalItems().subscribe(count => value.length = count)
    this._paginator.page.subscribe(this.internalStateSubject)
  }
  private _totalSubscription?: Subscription
  private _paginator?: MatPaginator

  get sort() {
    return this._sort
  }
  set sort(value: MatSort) {
    if (this._sort) {
      this._sort.sortChange.unsubscribe()
    }
    this._sort = value
    this._sort.sortChange.subscribe(this.internalStateSubject)
    this.internalStateSubject.next()
  }
  private _sort: MatSort

  protected readonly _disconnect$ = new Subject<void>()

  constructor(
  ) {
    super()
  }

  refresh() {
    this.internalStateSubject.next()
  }

  abstract defaultArgs(): Request

  private createRequest(): Request {
    const req = this.defaultArgs()

    if (this.sort) {
      const { active, direction } = this.sort
      // TODO If the sort changes, then the paging is no longer accurate. In
      //  that case, we should run multiple queries until we end up at our
      //  desired page.

      req.orderDir = direction === 'desc' ? 'desc' : 'asc'
      if (active) {
        req.orderBy = active
      }
    }

    req.pageIndex = this._paginator ? this._paginator?.pageIndex : this.currentPage
    req.pageSize = this._paginator?.pageSize || this.currentPageSize

    return req
  }

  public mapRequest(request: Request): Request {
    return request
  }

  abstract executeQuery(request: Request): Observable<Search.Results>

  abstract mapResponse(response: Array<Search.Hit>): Array<DataType>

  public totalItems(): Observable<number> {
    return this.currentTotal
  }

  connect(_collectionViewer: CollectionViewer): Observable<Array<DataType>> {
    return this.internalStateSubject.pipe(
      takeUntil(this._disconnect$),
      map(() => this.createRequest()),
      map(req => this.mapRequest(req)),
      switchMap(req =>
        this.executeQuery(req).pipe(
          tap(searchResult => this.currentTotal.next(searchResult.hits.total.value)),
          map(searchResult => this.mapResponse(searchResult.hits.hits)),
          map(res => ({ request: req, response: res })),
        )
      ),
      map(({ request, response }) => {

        // Update our page tracking controls
        this.currentPage = request.pageIndex
        this.currentPageSize = request.pageSize
        this._data$.next(response)

        return response
      })
    )
  }

  disconnect(_collectionViewer: CollectionViewer) {
    this._disconnect$.next()
    this._disconnect$.complete()
  }
}
