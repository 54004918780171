import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {ProfilesService} from 'app/shared/services/profiles.service';

@Component({
  selector: 'app-email-reveal',
  template: `
    <ng-container *ngIf="showEmail$ | async; else revealEmail">
      <ng-container *ngIf="email$ | async as email; else loading">{{ email }}</ng-container>
      <ng-template #loading>Loading...</ng-template>
    </ng-container>
    <ng-template #revealEmail><a (click)="revealUserEmailAddress()">Reveal Email</a></ng-template>
  `,
  styles: [
      `:host {
      color: inherit;
    }`,
      `a {
      text-decoration: underline;
      cursor: pointer;
    }`
  ]
})
export class EmailRevealComponent implements OnInit {

  @Input() userId: string

  showEmail$ = new BehaviorSubject<boolean>(false)
  email$: Observable<string>

  constructor(private profilesService: ProfilesService) {
  }

  ngOnInit(): void {
    // this.email$ = this.profilesService.getProfileEmailAddress(this.userId)
    this.email$ = this.showEmail$.pipe(
      filter(showEmail => !!showEmail),
      switchMap(() => this.profilesService.getProfileEmailAddress(this.userId)),
      catchError((err, caught) => {
        console.error(err)
        return of("No user record found.")
      })
    )
  }

  revealUserEmailAddress() {
    this.showEmail$.next(true)
  }

}
