<app-header-bar></app-header-bar>
<div class="postDetailPage">
  <div class="post">
    <app-post-detail *ngIf="post$ | async as post" [post]="post"></app-post-detail>
  </div>

  <div *ngIf="(popularPosts$ | async)?.length > 0">
    <h1 class="gallery-header">More of what's popular in the Gallery</h1>
    <div class="post-thumbs">
      <app-post-thumb-detail
        *ngFor="let post of popularPosts$ | async"
        [post]="post"
        (selected)="postSelected($event)"
        class="post-thumb"
      ></app-post-thumb-detail>
      <div class="moreButton">
        <a [routerLink]="['/']">View More <img src="/assets/img/chevron_right.png"/></a>
      </div>
    </div>
  </div>

  <div *ngIf="(relatedPosts$ | async)?.length > 0">
    <h1 class="gallery-header">Other versions of this design</h1>
    <div class="post-thumbs">
      <app-post-thumb-detail
        *ngFor="let post of relatedPosts$ | async"
        [post]="post"
        (selected)="postSelected($event)"
        class="post-thumb"
      ></app-post-thumb-detail>
    </div>
  </div>
</div>
