import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModerationService } from 'app/admin/services/moderation.service';
import { LoginService } from 'app/shared/services/login.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-comment-admin',
  templateUrl: './comment-admin.component.html',
  styleUrls: ['./comment-admin.component.scss']
})
export class CommentAdminComponent implements OnInit {

  isModerator$: Observable<boolean>
  isLoading$ = new BehaviorSubject<boolean>(false)

  @Output() completed = new EventEmitter<boolean>()

  @Input() postId: string;
  commentText: string;

  constructor(
    private loginSvc: LoginService,
    private moderationService: ModerationService,
  ) {
  }

  ngOnInit() {
    this.isModerator$ = this.loginSvc.hasPermission("moderator")
  }

  postComment() {
    this.isLoading$.next(true)

    this.moderationService.createComment(this.postId, this.commentText)
      .pipe(first())
      .subscribe({
        next: comment => {
          console.log("Posted moderator comment:", comment.$key)
          this.commentText = ""
        },
        error: error => {
          console.error("Failed to post comment", error)
          alert(`Failed to post comment: ${error}`)
        },
        complete: () => {
          this.isLoading$.next(false)
          this.completed.next(true)
        }
      })
  }
}
