import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSortHeader } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LegacyPost } from 'app/shared/model/post';
import { Observable, Subject } from 'rxjs';
import { filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { PostsDataSource } from './posts.datasource';
import { PostsTableComponent } from './table/table.component';
import { PaginatorComponent } from '../common/paginator/paginator.component';

interface Preset {
  value: string;
  order: 'asc' | 'desc';
  viewValue: string;
}

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
  providers: [PostsDataSource],
})
export class PostsComponent implements OnInit, OnDestroy {
  presets: Preset[] = [
    {value: 'created_at', order: 'desc', viewValue: 'Recents'},
    {value: 'counts.popularity', order: 'desc', viewValue: 'Popular'},
  ];

  selection = new SelectionModel<LegacyPost>(true, []);

  public get isFollowingUpdates() : boolean {
    return this.dataSource.isFollowing;
  }

  @ViewChild('table', { static: true }) table: PostsTableComponent;
  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent;
  @ViewChild(PostsTableComponent) postsTable: PostsTableComponent;

  private readonly ADMIN_POSTS_PATH = '/admin/posts';
  private readonly ADMIN_POSTS_FIRST_PAGE = 0;
  
  private userId$: Observable<string>;
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  private destroy$: Subject<void> = new Subject<void>();
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dataSource: PostsDataSource,
    private dialog: MatDialog,
    private scroller: ViewportScroller
  ) {
    this.setupRouterSubscription();
  }

  ngOnInit() {
    const userId = this.route.snapshot.paramMap.get('userId');
    this.dataSource.userId = userId;
    
    this.paginator.paginator.page.pipe(
      takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.scroller.scrollToAnchor('table')
        });
        
    this.userId$ = this.route.paramMap.pipe(
      map(paramMap => paramMap.get('userId')),
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    // close any open dialogs when we navigate away from this page
    this.dialog.closeAll();
    this.unsubscribe.next(true);
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupRouterSubscription() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.unsubscribe)
    ).subscribe(event => this.handleNavigation(event as NavigationEnd));
  }

  handleNavigation(event: NavigationEnd): void {
    if (event.urlAfterRedirects === this.ADMIN_POSTS_PATH) {
      this.paginator.setPageIndexAndGo(this.ADMIN_POSTS_FIRST_PAGE);
    }
  }

  selectPreset(preset: Preset) {
    this.table.sort.sort({ id: null, start: preset.order, disableClear: false })
    this.table.sort.sort({ id: preset.value, start: preset.order, disableClear: true })

    // Needed to update the header arrow until https://github.com/angular/components/issues/10242 is fixed.
    const header = this.table.sort.sortables.get(preset.value) as MatSortHeader
    header._setAnimationTransitionState({ toState: 'active' })
  }

  toggleFollowingUpdates() {
    this.dataSource.isFollowing = !this.isFollowingUpdates
  }

  postClicked(post: LegacyPost) {
    this.router.navigate([post.$key], {relativeTo: this.route})
  }

  userClicked(userId: string) {
    this.router.navigate(['./admin/profiles', userId]);
  }
}
