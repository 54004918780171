import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AlertMessageComponent } from 'app/shared/components/alert-message/alert-message.component';
import { LegacyProfile } from '../../../shared/model/profile';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { Note } from '../../model/note';
import { NotesService } from '../../services/notes.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
  @ViewChild(AlertMessageComponent) alertMsgComponent: AlertMessageComponent;

  profile: LegacyProfile;
  note$: Observable<Note>;
  note: Note;
  data: object;
  text: string;

  constructor(
    private notesSvc: NotesService,
    private profileSvc: ProfilesService
  ) {}

  static openWindow(data) {
    var params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=800,height=800,left=300,top=300`;
    var winRef = window.open('/admin/notes', 'Notes on User', params);

    winRef['data'] = data;
    winRef.focus();
    return;
  }

  ngOnInit() {
    if (window != null) {
      this.data = window['data'];
    }

    if (this.data == null) {
      return;
    }

    this.profile = this.data['profile'];
    var profileID: string;

    var appendText = this.data['text'];

    if (this.profile == null) {
      profileID = this.data['profileId'];
      this.profileSvc.findProfile(profileID).subscribe((profile) => {
        this.profile = profile;
      })
    } else {
      profileID = this.profile.$key;
    }

    this.note$ = this.notesSvc.noteAbout(profileID);

    this.note$.subscribe((note) => {
      this.note = note;
      if (note != null) {
        this.text = note.text;
      } else {
        this.text = '';
      }

      if (appendText != null) {
        this.text += appendText;
        appendText = null;
      }
    });
  }

  async savePressed() {
    let save_result;

    this.alertMsgComponent.showLoading();
    
    if (this.note != null) {
      save_result = await this.notesSvc.updateNote(this.note, this.text);
    } else {
      save_result = await this.notesSvc.makeNote(this.profile.$key, this.text);
    }
    
    if (!!save_result?.data?.subject_uid) {
      this.alertMsgComponent.showSuccess();
    } else {
      this.alertMsgComponent.showFail();
    }
  }

  dismissAlert() {
    this.alertMsgComponent.dismissAlert();
  }

  closeWindow() {
    window.close();
  }
}
