<table mat-table [dataSource]="dataSource">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="icon">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef><mat-icon>flag</mat-icon></td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>Flagging User</th>
    <td mat-cell *matCellDef="let flag">
      <ng-container *ngIf="flag.actor.id && flag.actor as actor else anonymous">
        <a [routerLink]="['/','admin','profiles',actor.id]">
          <app-avatar-username-inline [user]="actor" size="small"></app-avatar-username-inline>
        </a>
      </ng-container>
      <ng-template #anonymous>
        <app-avatar-username-inline [user]="{ username: 'Anonymous User' }" size="small"></app-avatar-username-inline>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef>Date Flagged</th>
    <td mat-cell *matCellDef="let flag"> {{ flag.timestamp | date: 'short' }} </td>
  </ng-container>

  <ng-container matColumnDef="reason">
    <th mat-header-cell *matHeaderCellDef>Reason</th>
    <td mat-cell *matCellDef="let flag"> {{ flag.reason }} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef>Status</th>
    <td mat-cell *matCellDef="let flag"> {{ flag.status }} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let flag">
      <button
        mat-icon-button
        matTooltip="Resolve Flag"
        aria-label="Resolve flag"
        [disabled]="flag.status === 'resolved'"
        (click)="onResolve($event, flag)">
        <mat-icon>done</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Contact User"
        aria-label="Contact user about flag"
        (click)="onContact($event, flag)"
        [disabled]="!flag.actor || !flag.actor.id">
        <mat-icon>email</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Delete Flag" aria-label="Delete flag" (click)="onDelete($event, flag)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns" class="flag-header-row"></tr>
  <tr mat-row *matRowDef="let row; columns: columns;" class="flag-row"></tr>
</table>
