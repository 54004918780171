import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { applyFirestoreRequest, FirestoreRequest } from 'app/admin/utils/firestore-data-source';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import { map, switchMap, take, filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { LegacyPost, Post, PostStub } from '../model/post';
import { LegacyProfile } from '../model/profile';
import { SearchService, Search } from './search.service';

export interface PageDetails {
  path: string;
  tags: string[];
}

const mapPost = (item: DocumentChangeAction<unknown>): LegacyPost => {
  const post = new LegacyPost();
  post.$key = item.payload.doc.id;
  const data: any = item.payload.doc.data()
  if (data) {
    post.created_at = data.created_at;
    post.owner_id = data.owner.user_id;
    post.owner_username = data.owner?.username;
    post.owner_thumb = data.owner?.thumb;
    post.image = data.image;
    post.image_attribution = data.image_attribution;
    post.original_image = data.original_image;
    post.description = data.description;
    post.popularity = data.popularity;
  }
  return post;
}

const mapPosts = (items: DocumentChangeAction<unknown>[]) => items.map(mapPost)

@Injectable()
export class PostsService {

  currentDialogPost: LegacyPost; // this is used to pass a post to a dialog service so it doesn't need to do a new fetch on the db

  private basePath = '/posts';

  constructor(private db: AngularFireDatabase,
              private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private searchService: SearchService) { 
  }

  private mapToPost = (item) => {
    let payload;
    let key;
    
    if (!!item.payload.doc) { // if this is a firestore object then we pull the data a little differently
      payload = item.payload.doc.data();
      key = item.payload.doc.id;
    } else {
      payload = item.payload.val();
      key = item.key;
    }

    if (item == null || payload == null) {
      return null;
    }

    const post = new LegacyPost();
    post.$key = key;
    post.created_at = payload.created_at;
    post.owner_id = payload.owner.user_id;
    post.owner_username = payload.owner.username;
    post.owner_thumb = payload.owner.thumb;
    post.image = payload.image;
    post.original_image = payload.original_image;
    post.description = payload.description;
    post.popularity = payload.popularity;

    // TODO review with Ryan
    // if payload has counts object use that to source comment and like counts
    // else try looking at RDB://post-counts (legacy)
    if (payload.counts) {
      post.like_count = payload.counts.likes
      post.comment_count = payload.counts.comments
    }
    else {
      // track the counts for this post
      post.counts_subscription = this.db.object('/post-counts/' + post.$key).snapshotChanges();
      post.counts_subscription.subscribe(it => {
        const countPayload = it.payload.val();
        if (countPayload != null) {
          post.comment_count = countPayload.comments;
          post.like_count = countPayload.likes;
        }
      });
    }

    if (payload.deleted) {
      post.deleted_date = typeof payload.deleted.date.toDate === 'function' ? payload.deleted.date.toDate() : payload.deleted.date;
      post.deleted_user = payload.deleted.user;
    }

    if (post.image == null) {
      // this.storage.ref(payload.original_image).getDownloadURL().subscribe(url => {
      //   post.image = url;
      // });
    }

    if (payload.description) {
      post.firstcomment_thumb = post.owner_thumb;
      post.firstcomment_message = post.description;
      post.firstcomment_username = post.owner_username;

    } else if (payload.comments) {
      const firstcomment = payload.comments[Object.keys(payload.comments)[0]];
      if (firstcomment && firstcomment.owner) {
        post.firstcomment_thumb = firstcomment.owner.thumb;
        post.firstcomment_message = firstcomment.message;
        post.firstcomment_username = firstcomment.owner.username;
      }
    }

    if (payload.metadata) {
      post.page_id = payload.metadata.page_id;
      post.image_attribution = payload.metadata.image_attribution;
    }

    return post;
  };

  findPostAt(path): Observable<LegacyPost> {
    return this.db.object(path).snapshotChanges().pipe(map(this.mapToPost))
  }

  findPost(id): Observable<LegacyPost> {
    return this.findPublishedPost(id).pipe(
      switchMap(post => post ? of(post) : this.findDeletedPost(id))
    )
  }

  findPublishedPost(id): Observable<LegacyPost> {
    return this.db.object(this.basePath + `/${id}`)
      .snapshotChanges().pipe(
        map(this.mapToPost))
  }

  findPostColoringPageDetails(pageId: string): Observable<PageDetails | null> {
    const searchIndex = `pages${environment.elasticSearchPagesSuffix}`;
    const query = {
      query: {
        match: {
          _id: pageId
        }
      }
    };
    
    return this.searchService.search(searchIndex, query)
      .pipe(
        switchMap((results: Search.Results) => {
          if (results.hits.total.value > 0) {
            const hit = results.hits.hits[0];
            const domain = environment.siteDomain.indexOf('localhost') > -1 ? 'https://stage.gallery.pigmentapp.co' : `https://${environment.siteDomain}`;
            const path = this.cleanUrlPath(`${domain}${hit._source.path}`);
            return of({
              path: path,
              tags: hit._source.tags
            });
          } else {
            return of(null);
          }
        })
    );
  }

  // Retrieve deleted post from Firestore, not RDB
  findDeletedPost(id: string): Observable<LegacyPost> {
    return this.afs.doc(`deleted_posts/${id}`)
      .snapshotChanges().pipe(
        map(doc => {
          let data = {};
          data = doc.payload.data();
          const id: string = doc.payload.id;
          const post: Post = data ? { id, ...data } as Post : null;

          return post ? this.mapToPost({ payload: { doc: doc.payload } }): null;
        })
      );
  }

  findPostsByUserId(userID, limit): Observable<LegacyPost[]> {
    return this.afs.collection(
      'posts',
      ref => ref.where('owner.user_id', '==', userID)
        .orderBy('created_at', 'desc')
        .limit(limit)
    ).snapshotChanges().pipe(
      map(items => items.map(this.mapToPost).filter(post => post.image != null))
    )
  }

  findPostsByUserIdPaged(userId: string, request: FirestoreRequest = {}): Observable<LegacyPost[]> {
    return this.afs
      .collection(
        'posts',
        ref => applyFirestoreRequest(ref.where('owner.user_id', '==', userId), request)
      )
      .snapshotChanges()
      .pipe(map(mapPosts))
  }

  async deleteUserPosts(userId: string): Promise<void> {
    // Delete all user posts from the real-time database
    await this.db.object(`/user-posts/${userId}`).set({});

    // Next, delete all user posts from the firestore collection
    const fsPostsSnap = await firstValueFrom(this.afs
      .collection('posts', ref => ref
        .where('owner.user_id', '==', userId))
        .get());
  
    const batch = this.afs.firestore.batch();

    fsPostsSnap.docs.forEach(doc => {
      batch.delete(doc.ref);
    });

    return batch.commit();
  }

  deletePosts(posts: LegacyPost[]): Promise<void> {
    return this.deletePostsFromTriggeredPaths(posts);
  }

  deletePost(post: PostStub): Promise<void> {
    const ownerId = post.owner?.id;
    if (!ownerId) {
      console.log('No owner for post:', post);
      return;
    }

    return this.deletePostsFromTriggeredPaths([post]);
  }

  restorePost(postId: string): Observable<LegacyPost> {
    return this.db.object(`/deleted/posts/${postId}`).snapshotChanges()
      .pipe(
        take(1),
        filter(snapshot => snapshot.payload.exists()),
        switchMap(async snapshot => {
          const data = snapshot.payload.val() as any
          delete data.deleted
          await this.db.object(`/user-posts/${data.owner.user_id}/${postId}`).set(data);
          return data;
        }),
        switchMap(data => this.db.object(`/deleted/posts/${postId}`).remove().then(() => data)),
        // We also have to remove the post from the firestore deleted posts collection.
        switchMap(data => this.afs.collection('deleted_posts').doc(postId).delete().then(() => data)),
        switchMap(data => this.db.object(`/user-posts/${data.owner.user_id}/${postId}`)
          .snapshotChanges().pipe(take(1))
        ),
        map(snapshot => this.mapToPost(snapshot))
      )
  }

  deletePostsFromTriggeredPaths(posts: (LegacyPost | PostStub)[]): Promise<void> {
    const deletePromises: Promise<void>[] = posts.map(post => {
      const isLegacyPost = post instanceof LegacyPost;
      const ownerId = isLegacyPost ? post.owner_id : post.owner?.id;
      const postId = isLegacyPost ? post.$key : post.id;

      return this.postExistsInRDB(ownerId, postId).then(exists => {
        if (exists) {
          return this.db.object(`/user-posts/${ownerId}/${postId}`).remove();
        } else {
          return this.afs.doc(`/posts/${postId}`).delete();        
        }
      });
    });

    return Promise.all(deletePromises)
      .then(() => console.log(`Deleted post(s)`))
      .catch(e => console.log(`Failed to delete post(s): ${e.message}`));
  }

  postExistsInRDB(ownerId, postId): Promise<boolean> {
    const post$ = this.db.object(`/user-posts/${ownerId}/${postId}`).snapshotChanges().pipe(take(1));
    return firstValueFrom(post$)
      .then(snapshot => !!snapshot.payload.val())
      .catch(error => {
        console.error('postExistsInRDB - Error checking for post:', error);
        return false;
      });
  };

  getDeletedPostsCount(userId?: string): Observable<number> {
    return this.afs
      .collection('deleted_posts', ref =>
        userId ? ref.where('owner.user_id', '==', userId) : ref
      )
      .snapshotChanges()
      .pipe(map(items => items.length))
  }

  findDeletedPostsByUserId(userID): Observable<LegacyPost[]> {
    console.log(`Looking for deleted posts from the firestore collection: /deleted_posts where owner.user_id == ${userID}`);

    return this.afs.collection('deleted_posts', ref => ref
        .where('owner.user_id', '==', userID)
        .orderBy('created_at', 'desc'))
      .snapshotChanges()
      .pipe(
        map(items => items.map(this.mapToPost)));
  }

  getPostsByPageID(pageID, limit): Observable<LegacyPost[]> {
    return this.afs.collection('posts', ref => {
      return ref.where('metadata.page_id', '==', pageID)
        .orderBy('likes', 'desc')
        .limit(limit);
    })
      .snapshotChanges().pipe(
        map(items => items.map(this.mapToPost).filter(post => post.image != null)));
  }

  getPosts(pageSize, sortKey, startObject?): Observable<LegacyPost[]> {
    return this.afs
      .collection('posts', ref => {
        let r = ref.orderBy(sortKey, 'desc').limit(pageSize + 1);

        if (startObject) {
          r = r.startAt(startObject[sortKey]);
        }
        return r;
      })
      .snapshotChanges()
      .pipe(
        map(items => items.map(this.mapToPost))
      );
  }

  getPostsPaged(request: FirestoreRequest = {}): Observable<LegacyPost[]> {
    return this.afs
      .collection(
        'posts',
        ref => applyFirestoreRequest(ref, request)
      )
      .snapshotChanges()
      .pipe(map(mapPosts))
  }

  getMostRecentPost(userID: string): Observable<LegacyPost> {
    return this.db.list(`/user-posts/${userID}`, ref => ref.orderByKey().limitToLast(1))
      .snapshotChanges()
      .pipe(
        switchMap(posts => {
          if (posts.length > 0) {
            return of(this.mapToPost(posts[0]))
          } else {
            // If the user has no posts, create a dummy post from the profile
            return this.db.object<LegacyProfile>(`/profiles/${userID}`).snapshotChanges().pipe(
              map(value => {
                const post = new LegacyPost()
                post.owner_id = value.key
                post.description = 'User has no posts.'

                if (value.payload.exists()) {
                  const payload = value.payload.val()
                  post.owner_thumb = payload['thumb']
                  post.owner_username = payload['username']
                }

                return post
              })
            )
          }
        })
      );
  }

  unrecommendUser(userID) {
    // look up suggestions, remove the one that matches this userID
    console.log('removing user from recommended list: ', userID);
    const r = this.db.list(`/follow-suggestions/`, ref => {
      return ref.orderByKey();
    });

    r.snapshotChanges().pipe(take(1)).subscribe(items => {
      const filtered = items.filter(item => item.payload.val() === userID);
      if (filtered.length > 0) {
        const item = filtered[0];
        console.log('Found user item: ', item);
        const key = item.key;
        const object = this.db.object(`/follow-suggestions/${key}`);
        object.remove()
      }

    })
  }

  recommendUser(userID) {

    const r = this.db.list(`/follow-suggestions/`, ref => {
      return ref.orderByKey();
    });

    r.push(userID)

  }

  getRecommendedPosts(): Observable<LegacyPost[]> {
    return this.db.list(`/follow-suggestions/`, r => r.orderByKey())
      .snapshotChanges()
      .pipe(
        switchMap(snapshots => {
          if (snapshots.length === 0) return of([])

          return combineLatest(
            snapshots.map(snap => this.getMostRecentPost(snap.payload.val() as string))
          )
        })
      );
  }

  getCommentCount(postId: string): Observable<number> {
    return this.afs.collection('posts').doc(postId)
      .snapshotChanges()
      .pipe(map(snapshot => snapshot.payload.get('counts.comments')))
  }

  private cleanUrlPath(url: string): string {
    // Remove dash followed by a number
    url = url.replace(/-\d+$/, '');
    // Remove period followed by any character (.jpg, .png, .html, etc)
    url = url.replace(/\.[^/.]+$/, '');
    return url;
  }
}
