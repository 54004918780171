import { Component, OnInit, Input } from '@angular/core';
import { LegacyPost } from '../../../../shared/model/post';
import { PostsService } from '../../../../shared/services/posts.service';

@Component({
  selector: 'app-post-actions-overflow',
  templateUrl: './post-actions-overflow.component.html',
  styleUrls: ['./post-actions-overflow.component.scss']
})
export class PostActionsOverflowComponent implements OnInit {
  @Input() post: LegacyPost;
  wasRecommended = false;

  constructor(
    private postsService: PostsService
  ) {}

  ngOnInit(): void { }

  recommendUser() {
    this.postsService.recommendUser(this.post.owner_id);
    this.wasRecommended = true;
  }

}
