import { PostStub } from 'app/shared/model/post';
import { ProfileStub } from 'app/shared/model/profile';
import { Comment } from 'app/shared/model/comment';

export type FlaggedPostData = PostStub & { description?: string }
export type FlagStatus = 'open' | 'resolved'

export interface Flagged {
  id: string
  type: 'post' | 'comment'
  status: FlagStatus
  deleted: boolean
  lastFlagged: Date
  lastReason: string
  count: number
  data: FlaggedPostData | Comment
}

export interface Flag {
  id: string
  contentId: string
  reason: string
  message?: string
  status: FlagStatus
  timestamp: Date
  actor?: ProfileStub
}

export interface FlagReason {
  id?: string
}

export interface FlagReasonSub {
  id?: string
  displayName?: string
  isActive: boolean
}

export interface FlagReasonOptionList {
  value: string
  isActive: boolean
}
