import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {LegacyPost} from 'app/shared/model/post';
import {PostsService} from 'app/shared/services/posts.service';
import {OsService} from 'app/shared/services/os.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gallery-page',
  templateUrl: './gallery-page.component.html',
  styleUrls: ['./gallery-page.component.scss']
})
export class GalleryPageComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ['image', 'username', 'description', 'created_at', 'comment_count', 'like_count', 'popularity'];
  postSubscription: Subscription;
  posts: LegacyPost[] = [];
  nextObject: any;
  prevStartObjects: any[] = [];
  subscription: any;
  statsSubscription: any;

  pageIndex = 0;
  postLimit = 60;
  postPageSize = 60;
  sortColumn = 'popularity';

  os: string;

  constructor(
    private postsSvc: PostsService,
    private osSvc: OsService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.postLimit = this.postPageSize;
    this.getPosts();
    this.os = this.osSvc.os;
  }

  setSort(sortColumn) {
    this.prevStartObjects = [];
    this.nextObject = [];
    this.sortColumn = sortColumn;
    this.postLimit = this.postPageSize;

    this.getPosts();
  }

  private getPosts(startObject?) {

    if (this.postSubscription != null) {
      this.postSubscription.unsubscribe();
    }

    const posts$ = this.postsSvc.getPosts(this.postLimit, this.sortColumn, startObject);
    this.postSubscription = posts$.subscribe(posts => {
      // update posts and unsubscribe once we've gotten a reasonable number (gets around cached posts coming back first)
      if (posts.length >= this.postLimit / 2) {
        this.posts = posts.filter(post => post.image != null);
        if (this.postSubscription != null) {
          this.postSubscription.unsubscribe();
        }
      }
    })
  }

  postSelected(post: LegacyPost) {
    this.postsSvc.currentDialogPost = post;
    this.router.navigate(['/posts', post.$key]);
  }

  showMore() {
    this.postLimit = this.postLimit += this.postPageSize;
    this.getPosts();
  }

}
