import {Pipe, PipeTransform} from '@angular/core';

export function magicImage(url: string, size: number, crop: boolean = false): string {
  return `${url}=s${size}${crop ? '-c' : ''}`
}

@Pipe({name: 'magicImage'})
export class MagicImagePipe implements PipeTransform {
  transform(url: string, size: number, crop: boolean = false): string {
    return magicImage(url, size, crop)
  }
}
