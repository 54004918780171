import { SelectionModel } from '@angular/cdk/collections';
import { ViewportScroller } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Flag } from 'app/admin/model/flag';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PaginatorComponent } from '../../common/paginator/paginator.component';
import { ChildFlagsDataSource } from '../../flags/child-flags-table/child-flags.datasource';
import { ChildFlagsTableComponent } from '../../flags/child-flags-table/child-flags-table.component';

@Component({
  selector: 'app-admin-post-flags',
  templateUrl: './flags.component.html',
  styleUrls: ['./flags.component.scss'],
  providers: [ChildFlagsDataSource]
})
export class PostFlagsComponent implements OnInit {

  sortColumns = ['actor.username', 'reason']
  selection = new SelectionModel<Flag>(true, [])

  private unsubscribe$ = new Subject<void>()

  @ViewChild('table', { static: true }) table: ChildFlagsTableComponent
  @ViewChild('paginator', { static: true }) paginator: PaginatorComponent

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private scroller: ViewportScroller
  ) { }

  ngOnInit(): void {
    this.paginator.source = this.table.dataSource

    this.route.parent.paramMap
      .pipe(
        takeUntil(this.unsubscribe$),
        map(params => params.get('postId'))
      )
      .subscribe({
        next: postId => this.table.flaggedId = postId
      })

    this.table.dataSource.data$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.cd.detectChanges())

    this.paginator.paginator.page.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.scroller.scrollToAnchor('table')
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  userClicked(userId: string) {
    this.router.navigate(['admin', 'profiles', userId])
  }
}
