import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnInit {
  @Input() showAlert: boolean = false;
  @Input() showSuccessMsg: boolean = false;
  @Input() showFailMsg: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() successMsg: string;
  
  constructor() { }

  ngOnInit(): void {
  }

  hideAlerts() {
    this.showAlert = false;
    this.showSuccessMsg = false;
    this.showFailMsg = false;
  }

  showLoading() {
    this.isLoading = true;
    this.hideAlerts();
  }

  showSuccess() {
    this.isLoading = false;
    this.showSuccessMsg = true;
    this.showFailMsg = false;
    this.showAlert = true;
  }

  showFail() {
    this.isLoading = false;
    this.showFailMsg = true;
    this.showSuccessMsg = false;
    this.showAlert = true;
  }

  dismissAlert() {
    this.showAlert = false;
  }
}
