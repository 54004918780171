export default class SortUtilities {
  // Dynamic Sort! Code for dynamicSort and dynamicSortMultiple was mercilessly stolen from:
  //    https://stackoverflow.com/questions/1129216/sort-array-of-objects-by-string-property-value
  // There is a more complicated "Angular-style" version found here:
  //    https://stackoverflow.com/questions/68278850/how-to-extend-a-keyof-type-so-that-it-includes-modified-versions-of-the-keys-e/68279093#68279093
  static dynamicSort(property) {
      // Example call: ObjectName.sort(SortUtilities.dynamicSort('propertyName'))
      // E.G., from a component: this.myObject.sort(SortUtilities.dynamicSort("myProperty"))
      let sortOrder = 1;
      if (property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
      return function (a,b) {
          let result = SortUtilities.sortByBase(a[property], b[property])
          return result * sortOrder;
      }
  }

  static dynamicSortMultiple() {
    // Example call: MyObject.sort(SortUtilities.dynamicSortMultiple('prop1', '-prop2'))
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while(result === 0 && i < numberOfProperties) {
            result = SortUtilities.dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
  }

  static sortByBase(a, b) {
    return a.localeCompare(b, 'en', { sensitivity: 'base' })
  }
}
