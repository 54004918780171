import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailComposeComponent } from 'app/admin/components/email-compose/email-compose.component';
import { EmailsComponent } from 'app/admin/components/emails/emails.component';
import { NotesComponent } from 'app/admin/components/notes/notes.component';
import { UserBlockedComponent } from 'app/admin/components/user-blocked/user-blocked.component';
import { UserBlockingComponent } from 'app/admin/components/user-blocking/user-blocking.component';
import { Flagged } from 'app/admin/model/flag';
import { FlagsService } from 'app/admin/services/flags.service';
import { NotesService } from 'app/admin/services/notes.service';
import { ProfileSettingsService } from 'app/admin/services/profile-settings.service';
import { LegacyProfile } from 'app/shared/model/profile';
import { PostsService } from 'app/shared/services/posts.service';
import { ProfilesService } from 'app/shared/services/profiles.service';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-manage-profile-menu',
  template: `
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Profile actions menu button">
      <ng-content></ng-content>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="openNotes()">Show Notes</button>
      <button mat-menu-item (click)="openEmails()">Show Emails</button>
      <button mat-menu-item (click)="sendUserEmail()">New Email</button>
      <mat-divider></mat-divider>
      <button mat-menu-item class="danger" (click)="deletePosts()">Delete All Posts</button>
      <mat-divider></mat-divider>
      <button mat-menu-item
              (click)="markAsResolved()"
              *ngIf="(flagged$ | async) && (flagged$ | async)?.status !== 'resolved'">
        Mark as Resolved
      </button>
      <button mat-menu-item class="danger" *ngIf="(hasProfilePic$ | async)" (click)="deleteProfilePic()">
        Delete Profile Pic
      </button>
      <button mat-menu-item class="danger" *ngIf="!(disabledStatus$ | async)" (click)="disableUser()">
        Disable User
      </button>
      <button mat-menu-item *ngIf="!!(disabledStatus$ | async)" (click)="enableUser()">
        Enable User
      </button>

      <button mat-menu-item class="danger" *ngIf="(isDeviceEnabled$ | async)" (click)="setDeviceEnabled(false)">
        Disable Device
      </button>
      <button mat-menu-item *ngIf="!(isDeviceEnabled$ | async)" (click)="setDeviceEnabled(true)">
        Enable Device
      </button>
    </mat-menu>
  `,
  styles: [
    '*.danger { color: #f44336; }'
  ]
})
export class ManageProfileMenuComponent implements OnInit, OnChanges {

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild(MatMenu) menu: MatMenu;

  @Input() profile: LegacyProfile;

  flagged$: Observable<Flagged>;
  disabledStatus$: Observable<string>;
  hasProfilePic$: Observable<boolean>;
  isDeviceEnabled$: Observable<boolean>;

  private profile$: BehaviorSubject<LegacyProfile> = new BehaviorSubject(null);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private flagsService: FlagsService,
    private profilesService: ProfilesService,
    private profileSettingsService: ProfileSettingsService,
    private postsService: PostsService,
    private notesService: NotesService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.flagged$ = this.profile$
      .pipe(
        switchMap(profile => !!profile ? this.flagsService.findFlagged(profile.$key) : EMPTY)
      );
    this.disabledStatus$ = this.profile$
      .pipe(
        switchMap(profile =>
          !!profile ? this.profilesService.getProfileAuthStatus(profile.$key) : of(null)
        ),
        switchMap(status => {
          if (!status || !status.user) {
            return of([status, null]);
          } else {
            return this.profilesService.findProfile(status.user)
              .pipe(
                first(),
                map(profile => [status, profile])
              )
          }
        }),
        map(([status, profile]) => {
          if (!status || !status.date) {
            return null;
          }

          const date = new Date(status.date);
          const disabledBy = profile && profile.username ? `by ${profile.username}` : '';
          return `Disabled ${disabledBy} on: ${date.toLocaleDateString()}`;
        }),
        shareReplay(1)
      );
    this.hasProfilePic$ = this.profile$
      .pipe(
        map(profile => {
          return !!profile?.thumb
        }),
        shareReplay(1)
      );
    this.isDeviceEnabled$ = this.profile$
        .pipe(
          filter(profile => profile !== undefined && profile !== null),
          switchMap(profile => this.profileSettingsService.getDeviceId(profile.$key)),
          switchMap(deviceId => this.profileSettingsService.isDeviceEnabled(deviceId)),
          shareReplay(1)
        );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.profile$.next(changes.profile.currentValue as LegacyProfile);
  }

  openUserBlocked() {
    if (!this.profile) return;

    this.dialog.open(UserBlockedComponent, {
      data: {
        profile: this.profile
      }
    });
  }

  openUserBlocking() {
    if (!this.profile) return;

    this.dialog.open(UserBlockingComponent, {
      data: {
        profile: this.profile
      }
    });
  }

  sendUserEmail() {
    if (!this.profile) return;

    EmailComposeComponent.openWindow({
      profile: this.profile,
      subject: `Pigment Gallery - ${this.profile.username}`,
      text: `Hi ${this.profile.username},<br/>`
    });
  }

  openEmails() {
    if (!this.profile) return;

    this.dialog.open(EmailsComponent, {
      data: {
        profile: this.profile
      }
    });
  }

  openNotes() {
    if (!this.profile) return;

    NotesComponent.openWindow({
      profile: this.profile,
      note$: this.notesService.noteAbout(this.profile.$key)
    });
  }

  markAsResolved() {
    if (!this.profile) return;
  }

  async deletePosts() {
    console.log(`Deleting posts for profile: ${this.profile}`);
    if (!this.profile) return;

    if (!confirm(`Are you sure you want to delete all of ${this.profile.username || 'this user'}'s posts?`)) {
      return;
    }

    try {
      await this.postsService.deleteUserPosts(this.profile.$key);
      alert('Complete');
    } catch (reason) {
      alert(`There was an error deleting the user's posts.\n\n${reason}`);
    }
  }

  deleteProfilePic() {
    if (!this.profile) return;
    if (confirm(`Are you sure you want to delete ${this.profile.username || 'the user'}'s profile pic?`)) {
      this.profilesService.deleteProfilePic(this.profile.$key);
    }
  }

  disableUser() {
    if (!this.profile) return;
    if (confirm(`Are you sure you want to disable ${this.profile.username || 'the user'}'s profile?`)) {
      this.profilesService.disableProfile(this.profile.$key);
    }
  }

  enableUser() {
    if (!this.profile) return;
    if (confirm(`Are you sure you want to enable ${this.profile.username || 'the user'}'s profile?`)) {
      this.profilesService.enableProfile(this.profile.$key);
    }
  }

  setDeviceEnabled(enabled: boolean) {
    if (!this.profile) return;
    if (confirm(`Are you sure you want to ${enabled ? 'enable' : 'ban'} ${this.profile.username || 'the user'}'s device?`)) {
      this.profileSettingsService
        .getDeviceId(this.profile.$key)
        .pipe(take(1))
        .subscribe(deviceId => this.profileSettingsService.setDeviceEnabled(deviceId, enabled));
    }
  }
}
