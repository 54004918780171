import { InjectionToken } from "@angular/core";
import { Environment } from './environment.types';
import { environment } from './environment';


export const ENV = new InjectionToken<Environment>('env')

export function getEnv(): Environment {
  return environment
}
