<mat-sidenav-container>
  <mat-sidenav *ngIf="validAdmin" mode="side" opened="true" [ngClass]="drawerClass" (opened)="openedChange(true)" (closed)="openedChange(false)" #drawer>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/']">
        <mat-icon matListIcon>public</mat-icon>
        <span>Home</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['landing']">
        <mat-icon matListIcon>home</mat-icon>
        <span>Admin Home</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['profiles']">
        <mat-icon matListIcon>people</mat-icon>
        <span>Profiles</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['recommended']">
        <mat-icon matListIcon>people</mat-icon>
        <span>Recommended</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['posts']">
        <mat-icon matListIcon>photo_library</mat-icon>
        <span>Posts</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['flags']">
        <mat-icon matListIcon>flag</mat-icon>
        <span>Flags</span>
      </a>
      <a mat-list-item routerLinkActive="active" [routerLink]="['activity']">
        <mat-icon matListIcon>receipt</mat-icon>
        <span>Activity</span>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-toolbar color="primary">
    <button mat-icon-button *ngIf="validAdmin" (click)="toggleMenu();">
      <mat-icon onclick="">menu</mat-icon>
    </button>
    <span>Pigment Admin</span>

    <span class="toolbar-fill-space"></span>

    <app-account-menu></app-account-menu>
  </mat-toolbar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>


</mat-sidenav-container>
