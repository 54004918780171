
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(
    private functions: AngularFireFunctions
  ) {
  }

  prime(type: string): Observable<any> {
    const callable = this.functions.httpsCallable(`search_prime_${type}`)
    return callable({})
  }

  search(index: string, query: any): Observable<Search.Results> {
    const request = {
      index: index,
      body: query
    }
    return this.functions.httpsCallable('search')(request)
  }
}

export namespace Search {
  export class Results {
    hits: HitList
    errors: boolean
    timed_out: boolean
    took: number
    error?: string
  }

  export class HitList {
    hits: Hit[]
    max_score: number
    total: HitListTotal
  }

  export class Hit {
    _id: string
    _index: string
    _score: number
    _source: any
    _type: string
  }

  export class HitListTotal {
    relation: string
    value: number
  }
}
