import { Observable } from 'rxjs';
import { Profile, ProfileStub } from './profile';

export interface PostStub {
  id?: string;
  image?: string;
  originalImage?: string;
  owner?: ProfileStub;
}

export interface PostCounts {
  comments: number;
  likes: number;
  popularity: number;
}

export interface Post extends PostStub {
  description?: string;
  createdAt: Date;
  counts: PostCounts;
  hashtagsAll?: string[];
  hashtagsComments?: string[];
  hashtagsDescription?: string[];
  imageAttribution?: string;
  pageId?: string;
  projectFile?: string;

  deleted?: DeleteData;
}

export interface DeleteData {
  date: Date;
  user?: string;
}

const basePostStub: object = {}

const basePost = () => ({
  description: '',
  createdAt: new Date(),
  counts: {
    comments: 0,
    likes: 0,
    popularity: 0,
  },
  ...basePostStub,
})

export const Post = {
  createStub(props: Partial<PostStub>): PostStub {
    const stub = { ...basePostStub } as PostStub;
    if (props.id !== undefined && props.id !== null) {
      stub.id = props.id;
    }
    if (props.image !== undefined && props.image !== null) {
      stub.image = props.image;
    }
    if (props.originalImage !== undefined && props.originalImage !== null) {
      stub.originalImage = props.originalImage;
    }
    if (props.owner !== undefined && props.owner !== null) {
      stub.owner = props.owner;
    }
    return stub;
  },

  createPost(props: Partial<Post>): Post {
    const post = basePost() as Post;
    if (props.id !== undefined && props.id !== null) {
      post.id = props.id;
    }
    if (props.image !== undefined && props.image !== null) {
      post.image = props.image;
    }
    if (props.originalImage !== undefined && props.originalImage !== null) {
      post.originalImage = props.originalImage;
    }
    if (props.owner !== undefined && props.owner !== null) {
      post.owner = props.owner;
    }
    if (props.description !== undefined && props.description !== null) {
      post.description = props.description;
    }
    if (props.hashtagsAll !== undefined && props.hashtagsAll !== null) {
      post.hashtagsAll = props.hashtagsAll;
    }
    if (props.hashtagsComments !== undefined && props.hashtagsComments !== null) {
      post.hashtagsComments = props.hashtagsComments;
    }
    if (props.hashtagsDescription !== undefined && props.hashtagsDescription !== null) {
      post.hashtagsDescription = props.hashtagsDescription;
    }
    if (props.imageAttribution !== undefined && props.imageAttribution !== null) {
      post.imageAttribution = props.imageAttribution;
    }
    if (props.createdAt !== undefined && props.createdAt !== null) {
      post.createdAt = props.createdAt;
    }
    if (props.counts !== undefined && props.counts !== null) {
      if (props.counts.comments !== undefined && props.counts.comments !== null) {
        post.counts.comments = props.counts.comments;
      }
      if (props.counts.likes !== undefined && props.counts.likes !== null) {
        post.counts.likes = props.counts.likes;
      }
      if (props.counts.popularity !== undefined && props.counts.popularity !== null) {
        post.counts.popularity = props.counts.popularity;
      }
    }
    if (props.pageId !== undefined && props.pageId !== null) {
      post.pageId = props.pageId;
    }
    if (props.projectFile !== undefined && props.projectFile !== null) {
      post.projectFile = props.projectFile;
    }
    if (props.deleted !== undefined && props.deleted !== null) {
      post.deleted = props.deleted;
    }
    return post;
  },
}

export class LegacyPost implements PostStub {
  $key: string;
  created_at: number;
  owner_id: string;
  owner_username: string;
  owner_thumb: string;
  image: string;
  image_attribution: string;
  original_image: string;
  description: string;
  comment_count: number;
  firstcomment_thumb: string;
  firstcomment_username: string;
  firstcomment_message: string;
  page_id: string;
  like_count: number;
  popularity: number;
  deleted_date: string;
  deleted_user: string;
  counts_subscription: Observable<any>;

  get id(): string | undefined {
    return this.$key;
  }

  get originalImage(): string | undefined {
    return this.original_image;
  }

  get owner(): ProfileStub | undefined {
    return Profile.createStub({
      id: this.owner_id,
      username: this.owner_username,
      image: this.owner_thumb
    });
  }
}
