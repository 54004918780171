<div fxLayout="row" fxLayoutAlign="space-between start">
  <div class="bottom-controls">
    <ng-content></ng-content>
  </div>

  <mat-paginator
      #paginator
      [length]="1000"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25, 50, 100, 500]">
  </mat-paginator>
</div>
