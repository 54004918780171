<div *ngIf="post$ | async as post; else postNotFound">
  <div class="post-header">
    <a href="{{ post.image }}" target="_blank"><img class="post-thumb" src="{{ post.image }}"/></a>
    <div class="detail-container">
      <a (click)="usernameClicked($event, post)">
        <app-avatar-username-inline size="normal" [user]="postOwner$ | async"></app-avatar-username-inline>
      </a>

      <div class="detail">Description: {{ post.description }}</div>
      <div class="detail">Attribution: {{ post.image_attribution }}</div>
      <div class="detail">Posted: {{ post.created_at | date:'short' }}</div>
      <div class="detail muted">Post Id: {{ post.$key }}</div>
      <div *ngIf="flagged$ | async as flagged" class="detail highlight">Flag Status: {{ flagged.status | titlecase }} ({{ flagged.lastFlagged | date:'yyyy-MM-dd' }}) </div>
      <div *ngIf="post.deleted_date">
        <div class="detail highlight">Deleted On: {{ post.deleted_date | date:'short' }}
          <ng-container *ngIf="post.deleted_user"> by {{ post.deleted_user }} </ng-container> </div>
      </div>
      <div class="detail muted">Popularity: {{ post.popularity | number:'1.3-3' }}</div>
    </div>
    <div>
      <app-post-actions [post]="post"></app-post-actions>
    </div>
  </div>
  <nav mat-tab-nav-bar>
    <a mat-tab-link
        [routerLink]="'comments'"
        routerLinkActive #commentsActive="routerLinkActive"
        [active]="commentsActive.isActive">
        {{ commentCount$ | async }} Comments
    </a>
    <a mat-tab-link
      [routerLink]="'flags'"
      routerLinkActive #flagsActive="routerLinkActive"
      [active]="flagsActive.isActive">
      {{ (flagged$ | async)?.flag_count || 0 }} Flags
    </a>
    <a mat-tab-link disabled> {{ (post.like_count) ? post.like_count : 0 }} Likes</a>
  </nav>

  <router-outlet></router-outlet>
</div>
<ng-template #postNotFound>
  <div>Post not found.</div>
</ng-template>
