<div class="flags">
  <mat-card>
    <app-table-toolbar [selection]="selection">
      <ng-container appWithEmptySelectionToolbar>
        <mat-chip-list>
          <mat-chip
            class="filter-picker-trigger"
            [matMenuTriggerFor]="filters">
            <mat-icon>filter_list</mat-icon>
            <span>Filters</span>
          </mat-chip>
          <mat-chip *ngIf="filterOpen$ | async" removable="true" (removed)="removeFilter('open')">
            Open
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterResolved$ | async" removable="true" (removed)="removeFilter('resolved')">
            Resolved
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterDeleted$ | async" removable="true" (removed)="removeFilter('deleted')">
            Deleted
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="filterNotDeleted$ | async" removable="true" (removed)="removeFilter('not-deleted')">
            Not Deleted
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>
        <mat-menu #filters="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="filterStatus">Status</button>
          <button mat-menu-item [matMenuTriggerFor]="filterDeleted">Deleted</button>
        </mat-menu>
        <mat-menu #filterStatus="matMenu">
          <button mat-menu-item [disabled]="filterOpen$ | async" (click)="addFilter('open')">Open</button>
          <button mat-menu-item [disabled]="filterResolved$ | async" (click)="addFilter('resolved')">Resolved</button>
        </mat-menu>
        <mat-menu #filterDeleted="matMenu">
          <button mat-menu-item [disabled]="filterDeleted$ | async" (click)="addFilter('deleted')">Deleted</button>
          <button mat-menu-item [disabled]="filterNotDeleted$ | async" (click)="addFilter('not-deleted')">Not Deleted</button>
        </mat-menu>
      </ng-container>

      <ng-container appWithSelectionToolbar>
        <button mat-raised-button
          class="action resolve"
          color="warn"
          (click)="resolveSelection()"
          [disabled]="(resolvableSelectionCount | async) === 0"
          [matTooltip]="(resolvableSelectionCount | async) === 0 ? 'All selected items are resolved.' : 'Resolve all selected items'">
          Resolve {{ resolvableSelectionCount | async }} Flagged Items
        </button>
        <button mat-raised-button
          class="action delete"
          color="warn"
          (click)="deleteSelection()"
          [disabled]="(deleteableSelectionCount | async) === 0"
          [matTooltip]="(deleteableSelectionCount | async) === 0 ? 'All selected items are deleted.' : 'Delete all selected items'">
          Delete {{ deleteableSelectionCount | async }} Flagged Items
        </button>
      </ng-container>
    </app-table-toolbar>

    <admin-flags-table
      #table
      [source]="dataSource"
      [selection]="selection"
      (flaggedAction)="onFlaggedAction($event)">
    </admin-flags-table>

    <app-paginator
      #paginator
      [source]="dataSource"
      [selection]="selection"
      storageKey="flags">
    </app-paginator>
  </mat-card>
</div>
