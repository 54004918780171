import { map, mergeMap, switchMap, take } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { AngularFireDatabase, SnapshotAction } from '@angular/fire/compat/database'
import { combineLatest, Observable } from 'rxjs'
import { LegacyProfile } from 'app/shared/model/profile'
import { ActivityService } from 'app/admin/services/activity.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileSettingsService {
  constructor(
    private db: AngularFireDatabase,
    private activityService: ActivityService,
  ) {}

  getPreviousUsernamesObject(userId): Observable<Object> {
    console.log(
      `looking for settings at /settings/${userId}/ubiquitous_user_id`
    )

    // first fetch the settings object
    return this.db
      .object(`/settings/${userId}/previous_usernames`)
      .snapshotChanges()
      .pipe(map((snapshot) => snapshot.payload.val()))
  }

  getDeviceId(userId: string): Observable<string> {
    return this.db
      .object<string>(`/settings/${userId}/ubiquitous_user_id`)
      .valueChanges()
  }

  getLinkedProfiles(userId: string): Observable<LegacyProfile[]> {
    return this.getDeviceId(userId)
      .pipe(switchMap((deviceId) => this.getProfilesForUid(deviceId)))
  }

  isDeviceEnabled(deviceId: string): Observable<boolean> {
    return this.db.object(`/disabled-devices/${deviceId}`)
      .snapshotChanges()
      .pipe(map(snap => !snap.payload.exists()))
  }

  setDeviceEnabled(deviceId: string, enabled: boolean): Promise<void> {
    const ref = this.db.object(`/disabled-devices/${deviceId}`)
    const action = enabled ? ref.remove() : ref.set('banned')
    return action.then(() =>
      this.getProfilesForUid(deviceId).pipe(
        take(1),
        switchMap(profiles => {
          return this.activityService.createActivity(
            enabled ? 'enable-device' : 'disable-device',
            { device_id: deviceId, user_id: profiles[0].id }
          )
        }),
        map(() => undefined)
      ).toPromise()
    )
  }

  private getProfile(key: string): Observable<LegacyProfile> {
    return this.db
      .object(`/profiles/${key}`)
      .snapshotChanges()
      .pipe(map(ProfileSettingsService.snapshotToProfile))
  }

  private getProfilesForUid(uid: string): Observable<LegacyProfile[]> {
    return this.db
      .list(`/settings`, (ref) =>
        ref
          .orderByChild('ubiquitous_user_id')
          .startAt(uid)
          .endAt(uid + '\uf8ff')
      )
      .snapshotChanges()
      .pipe(
        map((snapshots) => snapshots.map((it) => it.key)),
        mergeMap((keys) => combineLatest(keys.map((k) => this.getProfile(k))))
      )
  }

  private static snapshotToProfile(snapshot: SnapshotAction<any>): LegacyProfile {
    return LegacyProfile.create(
      snapshot.key,
      snapshot.payload.val() || { username: 'Profile Creation Blocked' }
    )
  }
}
