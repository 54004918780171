
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {Report} from '../model/report';
import {Observable} from 'rxjs';

@Injectable()
export class ReportsService {

  private basePath = '/reports';

  reports: Observable<Report[]> = null;
  report: Observable<Report> = null;

  constructor(private db: AngularFireDatabase) { }

  private mapToReport = (item) => {
    const payload = item.payload.val();
    const report = new Report();
    report.$key = item.key;
    report.report_date = new Date(Number.parseInt(item.key));
    report.like = payload.like;
    report.follow = payload.follow;
    report.comment = payload.comment;
    report.mention_comment = payload.mention_comment;
    report.mention_post = payload.mention_post;
    report.user = payload.users;
    report.post = payload.posts;
    report.au_comments_daily = payload.au_comments_daily;
    report.au_comments_weekly = payload.au_comments_weekly;
    report.au_comments_monthly = payload.au_comments_monthly;
    report.au_posts_daily = payload.au_posts_daily;
    report.au_posts_weekly = payload.au_posts_weekly;
    report.au_posts_monthly = payload.au_posts_monthly;
    return report;
  };

  //optionally takes number n and queries reports/ for latest n records
  getReportList(limit?): Observable<Report[]> {
    return this.db.list(this.basePath, ref => {
      let r = ref.orderByKey();
      if (limit) {
        r = r.limitToLast(limit)
      }
      return r
    })
    .snapshotChanges().pipe(
    map(items => items
      .filter(item => !isNaN(Number.parseInt(item.key)))
      .map(this.mapToReport)))
  }

  //takes java data objects and queries reports/ for specified date range
  getReportListByDates(startDate, endDate): Observable<Report[]> {
    return this.db.list(this.basePath, ref => {
      return ref.orderByKey().startAt(String(startDate.getTime())).endAt(String(endDate.getTime()));
    })
      .snapshotChanges().pipe(
      map(items => items
        .filter(item => !isNaN(Number.parseInt(item.key)))
        .map(this.mapToReport)))
  }

  getReport(key: string): Observable<{}> {
    const reportPath = `${this.basePath}/${key}`;
    this.report = this.db.object(reportPath).valueChanges().pipe(map(this.mapToReport))
    return this.report;
  }

}
