<div mat-dialog-content>
  <h2>Linked Profiles</h2>

  <table mat-table [dataSource]="linkedProfiles$ | async" *ngIf="(linkedProfileCount$ | async) > 0 else none">
    <ng-container matColumnDef="thumbnail">
      <th mat-header-cell *matHeaderCellDef>Thumbnail</th>
      <td mat-cell *matCellDef="let profile"><img class="profile-thumbnail" src="{{ profile.thumb }}" /></td>
    </ng-container>

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef>Username</th>
      <td mat-cell *matCellDef="let profile">{{ profile.username }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['thumbnail', 'username']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['thumbnail', 'username'];" (click)="profileSelected(row)"></tr>
  </table>

  <ng-template #none>
    <p>This user doesn't have other profiles.</p>
  </ng-template>
</div>
