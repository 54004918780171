import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DataSource } from 'app/admin/utils/firestore-data-source';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {
  @Input() source: DataSource
  @Input() selection: SelectionModel<any>
  @Input() storageKey?: string

  @Input() pageSize: number

  @ViewChild('paginator', { static: true }) paginator: MatPaginator

  private unsubscribe: Subject<void> = new Subject()

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.source.paginator = this.paginator

    const prefKey = `${this.storageKey ? this.storageKey : '_default'}:pageSize`
    if (!this.route.snapshot.queryParamMap.has('pageSize')) {
      const storedPageSize = localStorage.getItem(prefKey)
      if (storedPageSize) {
        try {
          this.pageSize = this.paginator.pageSize = parseInt(storedPageSize, 10)
        } catch (e) {
          console.log('Failed to parse stored page size:', storedPageSize)
          localStorage.removeItem(prefKey)
        }
      }
    }

    this.route.queryParamMap.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      if (params.has('pageIndex')) {
        try {
          const pageIndex = parseInt(params.get('pageIndex'), 10)
          if (pageIndex !== this.paginator.pageIndex) {
            this.paginator.pageIndex = pageIndex
          }
        } catch (e) {

        }
      }
    })

    this.paginator.page
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        try {
          localStorage.setItem(prefKey, '' + this.paginator.pageSize)
        } catch (e) {
          console.error('Failed to save page size preference.', e)
        }

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            pageIndex: '' + this.paginator.pageIndex
          },
          queryParamsHandling: 'merge'
        })

        this.selection.clear()
      })
  }

  setPageIndexAndGo(pIndex: number) {
    // Set the pagIndex of the paginator to the given value
    // (this updates the internal state of the paginator). 
    this.paginator.pageIndex = pIndex;
    
    // Emit the page event to trigger the data source to load,
    // and take user to the given pageIndex.
    this.paginator.page.emit({
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      length: this.paginator.length
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
