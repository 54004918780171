/**
 * Handles click events if the user isn't selecting text on the target view.
 *
 * @param $event The MouseEvent received from the click.
 * @param action The action to perform is the click should be handled.
 */
export function onClick($event: MouseEvent, action: () => void) {
  $event.stopPropagation()

  // Don't send a click event if the user is selecting text
  if ($event.view.getSelection().toString().length > 0) {
    return
  }

  action()
}
