import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/compat/firestore';
import { applyFirestoreRequest, FirestoreDataSource, FirestoreRequest } from 'app/admin/utils/firestore-data-source';
import { Comment } from 'app/shared/model/comment';
import { BehaviorSubject, Observable, of, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { mapCommentFromFirestore } from '../../../shared/services/comments.service';
import { ProfilesService } from 'app/shared/services/profiles.service';
import { PostsService } from '../../../shared/services/posts.service';

export interface CommentsRequest extends FirestoreRequest {
  userId?: string
  postId?: string
  deleted?: boolean
}

@Injectable()
export class CommentsDataSource extends FirestoreDataSource<CommentsRequest, Comment> {

  userId$ = new BehaviorSubject<string>(undefined)
  postId$ = new BehaviorSubject<string>(undefined)
  deleted$ = new BehaviorSubject<boolean>(false)

  // Temporary client-side filter string
  private _filterString?: string = ''
  get filterString(): string { return this._filterString }
  set filterString(value: string) {
    this._filterString = value
    this.refresh()
  }

  public constructor(
    private firestore: AngularFirestore,
    private profilesService: ProfilesService,
    private postsService: PostsService,
  ) {
    super()
    this.isFollowing = true
  }

  defaultArgs(): Observable<CommentsRequest> {
    return of({ orderBy: 'created_at', orderDir: 'desc' })
  }

  mapRequest(request: CommentsRequest): Observable<CommentsRequest> {
    return combineLatest([this.userId$, this.postId$, this.deleted$]).pipe(
      map(([userId, postId, deleted]) => ({ userId: userId, postId: postId, deleted: deleted, ...request }))
    )
  }

  executeQuery(request: CommentsRequest): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection(
      request.deleted ? 'deleted_activity' : 'activity',
      ref => {
        let query = ref.where('app', '==', 'pigment').where('type', '==', 'comment')

        if (request.userId) {
          query = query.where('actor.user_id', '==', request.userId)
        }

        if (request.postId) {
          query = query.where('target_id', '==', request.postId)
        }

        return applyFirestoreRequest(query, request)
      }
    ).snapshotChanges()
  }

  mapResponse(response: DocumentChangeAction<unknown>[]): Comment[] {
    const comments = mapComments(response)
    if (this._filterString) {
      return comments.filter(comment =>
        comment.message.toLowerCase().indexOf(this._filterString) > -1
      )
    }
    return comments
  }

  totalItems(): Observable<number> {
    return combineLatest([this.userId$, this.postId$, this.deleted$])
      .pipe(
        switchMap(([userId, postId, deleted]) => {
          if (userId && !deleted) {
            return this.profilesService.getCommentCount(userId)
          } else if (postId && !deleted) {
            return this.postsService.getCommentCount(postId)
          } else {
            return this.executeQuery({ userId: userId, postId: postId, deleted: deleted })
              .pipe(map(docs => docs.length))
          }
        })
      )
  }
}

const mapComments = (items: DocumentChangeAction<unknown>[]) => items.map(mapCommentFromFirestore)
