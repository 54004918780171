import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GalleryPageComponent } from './shared/components/gallery-page/gallery-page.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { PostDetailPageComponent } from './shared/components/post-detail-page/post-detail-page.component';
import { PostDialogRouterComponent } from './shared/components/post-detail/post-dialog-router.component';
import { ProfileDetailComponent } from './shared/components/profile-detail/profile-detail.component';
import { RelatedPostsComponent } from './shared/components/related-posts/related-posts.component';
import { PostTitlesResolver } from './shared/services/post-titles.resolver';
import { PostCommentsResolver } from './shared/services/post-comments.resolver';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'profiles/:userId',
    component: ProfileDetailComponent,
    title: 'Profile Page on Pigment',
    children: [{ path: ':postId', component: PostDialogRouterComponent }],
  },
  {
    path: 'pages/:pageId',
    component: RelatedPostsComponent,
    children: [{ path: ':postId', component: PostDialogRouterComponent }],
  },
  {
    path: 'posts/:postId',
    component: PostDetailPageComponent,
    title: PostTitlesResolver,
    resolve: { 
      postCommentsData: PostCommentsResolver
    },
  },
  {
    path: '',
    component: GalleryPageComponent,
    title: 'Pigment Gallery',
    children: [{ path: ':postId', component: PostDialogRouterComponent }],
  },
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
]

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
  })]
})
export class AppRoutingModule {}
