import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ProfileSettingsService} from 'app/admin/services/profile-settings.service';
import {LegacyProfile} from 'app/shared/model/profile';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Router} from '@angular/router';

export interface LinkedProfilesDialogData {
  userId: string
}

@Component({
  selector: 'app-linked-profiles-dialog',
  templateUrl: './linked-profiles-dialog.component.html',
  styleUrls: ['./linked-profiles-dialog.component.scss']
})
export class LinkedProfilesDialogComponent implements OnInit {

  linkedProfiles$: Observable<LegacyProfile[]>
  linkedProfileCount$: Observable<number>

  constructor(
    private router: Router,
    private profileSettingsService: ProfileSettingsService,
    @Inject(MAT_DIALOG_DATA) private data: LinkedProfilesDialogData,
    public dialogRef: MatDialogRef<LinkedProfilesDialogComponent>
  ) { }

  ngOnInit(): void {
    this.linkedProfiles$ = this.profileSettingsService.getLinkedProfiles(this.data.userId)
      .pipe(shareReplay())

    this.linkedProfileCount$ = this.linkedProfiles$.pipe(map(it => it.length))
  }

  profileSelected(row: LegacyProfile) {
    this.dialogRef.close()
    this.router.navigate(['/admin/profiles', row.$key])
  }

}
