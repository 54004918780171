import { Injectable } from '@angular/core'
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore'
import { flaggedFromFirestore, flaggedToFirestore } from 'app/admin/model/firestore.converters'
import { FlaggedPostData } from 'app/admin/model/flag'
import { ActivityService } from 'app/admin/services/activity.service'
import { Comment } from 'app/shared/model/comment'
import { PostStub } from 'app/shared/model/post'
import { CommentsService } from 'app/shared/services/comments.service'
import { Observable, combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { PostsService } from '../../shared/services/posts.service'
import { FlagActivityType } from '../model/admin-activity'
import { Flag, Flagged, FlagReason, FlagReasonSub, FlagReasonOptionList } from '../model/flag'
import { CountersService } from './counters.service'

export const COLLECTION_NAME = 'flags'
export const COLLECTION_REASONS = 'flags_reasons'

@Injectable({
  providedIn: 'root'
})
export class FlagsService {
  private collection: AngularFirestoreCollection<Flagged>
  private collection_all_reasons: AngularFirestoreCollection<FlagReason>

  constructor(
    firestore: AngularFirestore,
    private postsService: PostsService,
    private commentsService: CommentsService,
    private activityService: ActivityService,
    private counters: CountersService,
  ) {
    this.collection = firestore.collection(COLLECTION_NAME)
    this.collection_all_reasons = firestore.collection<FlagReason>(COLLECTION_REASONS)
  }

  getFlaggedCount(): Observable<number> {
    return this.counters.getCount('flagged')
  }

  getAllFlagReasonsForOptionList(): FlagReasonOptionList[] {
    // "ios_reasons" are borrowed from Pigment IOS, and
    // are hard-coded in the PIGSocial/Shared Views/FlagReasons.swift
    // file in that repo; whenever changes are made there, we will
    // need to update the ios_reasons sub-collection to reflect those changes
    // (and vice versa)
    const ios_reasons = this.getFlagReasonsSubCollection('ios_reasons')
    // "moderator_reasons" have been specifically requested by
    // Lynn Nash for Gallery-Admin-Only purposes
    const moderator_reasons = this.getFlagReasonsSubCollection('moderator_reasons')

    const all_reasons = combineLatest<any[]>(ios_reasons, moderator_reasons)
      .pipe(map(arr => arr.reduce((acc, cur) => acc.concat(cur) ) ),
    )

    let jsonReasons = []
    all_reasons.forEach((arr) => {
      arr.forEach((r) => {
        jsonReasons.push({ value: r.id, isActive: true })
      })
    })
    return jsonReasons
  }

  getFlagReasonsSubCollection(docId: string): Observable<FlagReasonSub[] | undefined> {
    let flag_reasons = this.collection_all_reasons
      .doc(docId)
      .collection('reasons', ref => ref.where('is_active', '==', true))
      .snapshotChanges().pipe(map(changes => {
        return changes.map(a => {
          const data = a.payload.doc.data() as FlagReasonSub
          data.id = a.payload.doc.id
          return data
        });
      }))
    return flag_reasons
  }

  findFlagged(contentId: string): Observable<Flagged | undefined> {
    return this.collection.doc(contentId).valueChanges({ idField: 'id' })
  }

  async getFlaggedForFlag(flag: Flag): Promise<Flagged | undefined> {
    return this.collection.doc(flag.contentId)
      .get()
      .toPromise()
      .then(snapshot => flaggedFromFirestore(snapshot.data(), snapshot.id))
  }

  /**
   * Resolves a flagged item.
   *
   * @param flagged The flagged item that should be resolved.
   */
  async resolveFlagged(flaggedOrId: Flagged | string): Promise<void> {
    let id: string
    if (typeof(flaggedOrId) === 'string') {
      id = flaggedOrId
    } else {
      id = (flaggedOrId as Flagged).id
    }
    return this.collection.doc(id).update({ status: 'resolved' })
      .then(() => typeof(flaggedOrId) === 'string'
        ? this.findFlagged(flaggedOrId).toPromise()
        : flaggedOrId as Flagged
      )
      .then(flagged => this.logActivity('resolve-flagged-item', flagged))
  }

  async updateFlaggedReason(flagged: Flagged): Promise<void> {
    return this.collection
      .doc(flagged.id)
      .update(flaggedToFirestore(flagged))
      .then(() => this.logActivity('update-flagged-item', flagged))
  }

  async deleteFlaggedContent(flaggedOrId: Flagged | string): Promise<void> {
    let flagged: Flagged
    if (typeof(flaggedOrId) === 'string') {
      flagged = await this.findFlagged(flaggedOrId).toPromise()
    } else {
      flagged = flaggedOrId
    }

    switch (flagged.type) {
      case 'post':
        await this.postsService.deletePost(flagged.data as PostStub)
        break
      case 'comment':
        await this.commentsService.deleteComment(flagged.data.id)
        break
    }
    return this.logActivity('delete-flagged-item', flagged)
  }

  async resolveFlag(flag: Flag): Promise<void> {
    return this.collection
      .doc(flag.contentId)
      .collection('flags')
      .doc(flag.id)
      .update({ status: 'resolved' })
      .then(() => this.logActivity('resolve-flag', flag))
  }

  /**
   * Deletes a flag from the backend.
   *
   * @param flag The flag to delete.
   */
  async deleteFlag(flag: Flag): Promise<void> {
    return this.collection.doc(flag.contentId).collection('flags').doc(flag.id).delete()
      .then(() => this.logActivity('delete-flag', flag))
  }

  private async logActivity(activity: FlagActivityType, item: Flagged | Flag): Promise<void> {
    let data: any
    if ('type' in item) {
      data = flaggedActivityData(item)
    } else {
      const flagged = await this.getFlaggedForFlag(item)
      data = flagActivityData(item, flagged)
    }
    await this.activityService.createActivity(activity, data).toPromise()
  }
}

function flaggedActivityData(flagged: Flagged): any {
  const result: any = {
    type: flagged.type,
    content_id: flagged.id,
    flag_count: flagged.count
  }
  switch (flagged.type) {
    case 'post':
      const post = flagged.data as FlaggedPostData
      result.content_image = post.image
      result.content_text = post.description
      result.content_owner = post.owner
      break

    case 'comment':
      const comment = flagged.data as Comment
      result.post_id = comment.post.id
      result.content_text = comment.message
      result.content_owner = comment.actor
      break
  }
  return result
}

function flagActivityData(flag: Flag, flagged: Flagged): any {
  const result: any = {
    type: flagged.type,
    content_id: flag.contentId,
    actor: flag.actor
  }
  switch (flagged.type) {
    case 'post':
      const post = flagged.data as FlaggedPostData
      result.content_image = post.image
      result.content_text = post.description
      result.content_owner = post.owner
      break

    case 'comment':
      const comment = flagged.data as Comment
      result.post_id = comment.post.id
      result.content_text = comment.message
      result.content_owner = comment.actor
      break
  }
  return result
}
