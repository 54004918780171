import { Component, Inject, Renderer2, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterState, TitleStrategy } from '@angular/router';
import { LoginService } from './shared/services/login.service';
import { NgcCookieConsentService, 
         NgcInitializingEvent,
         NgcInitializationErrorEvent, 
         NgcStatusChangeEvent,
         NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subject, Subscription, takeUntil } from 'rxjs';

import { getEnv } from 'environments/environment.provider';

declare const gtag: Function; // <------------Important: the declaration for gtag is required!

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: string = 'app';
  private destroy$: Subject<void> = new Subject<void>();
  private userIsAdmin: boolean = false;
  
  // For Cookie Consent: keep refs to subscriptions to be able 
  // to unsubscribe later
  private popupOpenSubscription!: Subscription;
  private popupCloseSubscription!: Subscription;
  private initializingSubscription!: Subscription;
  private initializedSubscription!: Subscription;
  private initializationErrorSubscription!: Subscription;
  private statusChangeSubscription!: Subscription;
  private revokeChoiceSubscription!: Subscription;
  private noCookieLawSubscription!: Subscription;
  
  readonly COOKIE_CONSENT_NAME: string = 'cookieconsent_status';
  readonly COOKIE_CONSENT_DOMAIN: string;
  readonly COOKIE_DOMAIN: string = '.pigmentapp.co';
  readonly COOKIE_STAGE_DOMAIN: string = 'stage.gallery.pigmentapp.co';
  readonly COOKIE_PROD_DOMAIN: string = '.gallery.pigmentapp.co';
  readonly COOKIE_LOCAL_DOMAIN: string = 'localhost';
  readonly COOKIE_GOOGLE_DOMAIN: string = '.www.google-analytics.com';

  readonly COOKIE_CONSENT_ALLOW: string;
  readonly COOKIE_CONSENT_DENY: string;
  readonly GTAG_CONSENT_ALLOW: string = 'granted';
  readonly GTAG_CONSENT_DENY: string = 'denied';

  readonly GTM_ID: string = getEnv().gTagId;
  
  constructor(
    private loginSvc: LoginService,
    private router: Router,
    private titleStrategy: TitleStrategy,
    private cookieSvc: NgcCookieConsentService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private platformId: Object) {
      if (isPlatformBrowser(this.platformId)) {
        this.COOKIE_CONSENT_ALLOW = this.cookieSvc.getStatus().allow;
        this.COOKIE_CONSENT_DENY = this.cookieSvc.getStatus().deny;
        this.COOKIE_CONSENT_DOMAIN = window.location.hostname;

        this.injectGoogleTag();
        this.injectCookieConsent();
        this.hideCookieConsentForAdmins();
      }
    }

  private injectCookieConsent(): void {
    // Installation Instructions for ngx-cookieconsent:
    // -- from npm: https://www.npmjs.com/package/ngx-cookieconsent
    // -- from Medium: https://akhilabhinav.medium.com/implementing-cookie-consent-policy-in-angular-f0a8f003d7da
    
    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.cookieSvc.popupOpen$.subscribe(
      () => {
        // you can use this.cookieSvc.getConfig() to do stuff...
      });

    this.popupCloseSubscription = this.cookieSvc.popupClose$.subscribe(
      () => {
        // you can use this.cookieSvc.getConfig() to do stuff...
      });

    this.initializingSubscription = this.cookieSvc.initializing$.subscribe(
      (event: NgcInitializingEvent) => {
        // the cookieconsent is initilializing... Not yet safe to call methods like `NgcCookieConsentService.hasAnswered()`
        console.log(`initializing: ${JSON.stringify(event)}`);
      });
    
    this.initializedSubscription = this.cookieSvc.initialized$.subscribe(
      () => {
        // the cookieconsent has been successfully initialized.
        // It's now safe to use methods on NgcCookieConsentService that require it, like `hasAnswered()` for eg...
        console.log(`initialized: ${JSON.stringify(event)}`);
      });

    this.initializationErrorSubscription = this.cookieSvc.initializationError$.subscribe(
      (event: NgcInitializationErrorEvent) => {
        // the cookieconsent has failed to initialize... 
        console.log(`initializationError: ${JSON.stringify(event.error?.message)}`);
      });

    this.statusChangeSubscription = this.cookieSvc.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        const cookiesAccepted: boolean = event.status === this.COOKIE_CONSENT_ALLOW;
        this.setCookieConsent(cookiesAccepted);
        // Now go and deal with the third party cookies based on the user's choice
        this.dealWithCookies(cookiesAccepted);
        // Update the Google Tag with the user's choice
        this.updateGtagConsent(cookiesAccepted);
      });

    this.revokeChoiceSubscription = this.cookieSvc.revokeChoice$.subscribe(
      () => {
        // Update the cookieconsent_status expire date to a time in the past,
        // to force the Cookie Consent popup to appear again.
        this.setCookieConsent(false, true);
      });

      this.noCookieLawSubscription = this.cookieSvc.noCookieLaw$.subscribe(
      (event: NgcNoCookieLawEvent) => {
        // you can use this.cookieSvc.getConfig() to do stuff...
      });
  }

  private injectGoogleTag(): void {
    // Get the status of the user's consent
    const userConsentStatus: string = this.getCookieValue(this.COOKIE_CONSENT_NAME);
    // Keep or remove cookies based on the user's previous choice (if it exists)
    // by calling dealWithCookies, which will handle it for us.
    this.dealWithCookies(userConsentStatus === this.COOKIE_CONSENT_ALLOW);

    // Inject the Google Tag Manager script
    const gtmScriptTag: HTMLScriptElement = this.renderer.createElement('script');
    gtmScriptTag.type = 'text/javascript';
    gtmScriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${this.GTM_ID}`;
    gtmScriptTag.async = true;
    this.renderer.appendChild(this._document.head, gtmScriptTag);

    const gtagUserConsent: string = userConsentStatus === this.COOKIE_CONSENT_ALLOW ? this.GTAG_CONSENT_ALLOW : this.GTAG_CONSENT_DENY;
    const gtagInitScript: HTMLScriptElement = this.renderer.createElement('script');
    
    gtagInitScript.type = 'text/javascript';
    gtagInitScript.text = `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('consent', 'default', {
        'ad_storage': '${gtagUserConsent}',
        'analytics_storage': '${gtagUserConsent}',
        'wait_for_update': 500,
      });
      gtag('config', '${this.GTM_ID}', { 'send_page_view': false });
    `;
    this.renderer.appendChild(this._document.head, gtagInitScript);

    this.handleRouteEventsForGoogleTag();
  }

  private hideCookieConsentForAdmins(): void {
    this.loginSvc.isAdmin
    .pipe(
      takeUntil(this.destroy$))
        .subscribe(isAdmin => {
          this.userIsAdmin = isAdmin;
          if (this.userIsAdmin) {
            this.cookieSvc.fadeOut();
            this.setCookieConsent(false);
            this.dealWithCookies(false);
            this.updateGtagConsent(false);
          }
      });
  }

  public ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      // Unsubscribe from all angular observables
      this.destroy$.next();
      this.destroy$.complete();

      // Unsubscribe from CookieConsent observables to prevent memory leaks
      this.popupOpenSubscription.unsubscribe();
      this.popupCloseSubscription.unsubscribe();
      this.initializingSubscription.unsubscribe();
      this.initializedSubscription.unsubscribe();
      this.initializationErrorSubscription.unsubscribe();
      this.statusChangeSubscription.unsubscribe();
      this.revokeChoiceSubscription.unsubscribe();
      this.noCookieLawSubscription.unsubscribe();
    }
  }

  //--------------------------------------------------------------------------------
  // HELPER FUNCTIONS
  //--------------------------------------------------------------------------------
  private getCookieValue(cookieName: string): string {
    for (let i = 0; i < document.cookie.split(';').length; i++) {
      
      const cookie: string = document.cookie.split(';')[i];
      const currCookieName: string = cookie.split('=')[0].trim();
      
      if (cookieName == currCookieName)  {
        return cookie.split('=')[1].trim();
      }
    }
    return '';
  }

  private setCookieConsent(userAcceptedCookies: boolean, userRevokedCookie: boolean = false): void {
    if (userRevokedCookie) {
      // Remove the user's preference for cookies
      document.cookie = `${this.COOKIE_CONSENT_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_CONSENT_DOMAIN};`;
      console.log(document.cookie);
      return;
    }
    if (userAcceptedCookies) {
      // Set user's preference to allow cookies
      document.cookie = `${this.COOKIE_CONSENT_NAME}=${this.COOKIE_CONSENT_ALLOW}; path=/; domain=${this.COOKIE_CONSENT_DOMAIN};`;
    } else {
      // Set user's preference to deny cookies
      document.cookie = `${this.COOKIE_CONSENT_NAME}=${this.COOKIE_CONSENT_DENY}; path=/; domain=${this.COOKIE_CONSENT_DOMAIN};`;
    }
  }

  private dealWithCookies(userAcceptedCookies: boolean): void {
    if (!userAcceptedCookies) {
      this.removeCookies();
    }
  }

  private updateGtagConsent(userAcceptedCookies: boolean): void {
    gtag('consent', 'update', {
      'ad_storage': userAcceptedCookies ? this.GTAG_CONSENT_ALLOW : this.GTAG_CONSENT_DENY,
      'analytics_storage': userAcceptedCookies ? this.GTAG_CONSENT_ALLOW : this.GTAG_CONSENT_DENY,
      'wait_for_update': 500,
    });
  }

  private removeCookies(): void {
    // Delete all Google Analytics cookies
    const cookiePrefixes: string[] = ['_ga', '_gid', '_e_gcip', 'e_gcip'];
    document.cookie.split(';').forEach(cookie => {
      const cookieName: string = cookie.split('=')[0].trim();
      if (cookiePrefixes.some(prefix => cookieName.startsWith(prefix)))  {
        // Just try and remove cookies from all possible domains for our website.
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_CONSENT_DOMAIN};`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_LOCAL_DOMAIN};`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_DOMAIN};`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_STAGE_DOMAIN}`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_PROD_DOMAIN}`;
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${this.COOKIE_GOOGLE_DOMAIN}`;       
      }
    });  
  }

  private handleRouteEventsForGoogleTag(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const p_path: string = event.urlAfterRedirects;
        const p_location: string = this._document.location.href;
        const p_title: string = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
        gtag('event', 'page_view', { 
          'page_title': p_title ? p_title : p_path,
          'page_path': p_path,
          'page_location': p_location
        });
        // Store the current page path in 'previous_page_path' for future tracking events
        localStorage.setItem('previous_page_path', p_path);
      }
    });
  }

  private getTitle(state: RouterState, parent: ActivatedRoute): string[] {
    const data: string[] = [];
    
    if (parent && parent.snapshot.data && this.titleStrategy.getResolvedTitleForRoute(parent.snapshot)) {
      data.push(this.titleStrategy.getResolvedTitleForRoute(parent.snapshot));
    }
    if (state && parent && parent.firstChild) {
      data.push(...this.getTitle(state, parent.firstChild));
    }
    return data;
  }
}
