import {asyncScheduler, SchedulerAction, SchedulerLike, Subscription} from 'rxjs';

// TODO This is needed until the next release of AngularFire.
//  Fixed in https://github.com/angular/angularfire/pull/2522/files#diff-e7e0d8817dc489d9fb79a1433858d66dR55
export class ZoneScheduler implements SchedulerLike {
  constructor(private zone: any) {
  }
  now(): number {
    return asyncScheduler.now();
  }
  schedule<T>(work: (this: SchedulerAction<T>, state?: T) => void, delay?: number, state?: T): Subscription {
    const targetZone = this.zone
    const workInZone = function(this: SchedulerAction<any>, state?: any) {
      targetZone.runGuarded(() => {
        work.apply(this, [state])
      })
    }
    return asyncScheduler.schedule(workInZone, delay, state);
  }
}
