import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PostsService } from '../../services/posts.service';
import { LegacyPost } from '../../model/post';

@Component({
  selector: 'app-post-thumb-detail',
  templateUrl: './post-thumb-detail.component.html',
  styleUrls: ['./post-thumb-detail.component.scss']
})
export class PostThumbDetailComponent {

  @Input() post: LegacyPost;

  @Output() selected = new EventEmitter<LegacyPost>()

  constructor() { }
}
