import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommentsService } from '../../services/comments.service';
import { PostsService } from '../../services/posts.service';

import { LegacyComment } from '../../model/comment';
import { LegacyPost } from '../../model/post';
import { EmailComposeComponent } from '../../../admin/components/email-compose/email-compose.component';
import { MagicImagePipe } from '../../pipes/magic-image.pipe'
import StringUtilities from 'app/shared/utils/string-utils';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss']
})
export class CommentComponent implements OnInit, OnChanges {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private commentSvc: CommentsService,
    private postSvc: PostsService
  ) {
  }

  @Input() comment: LegacyComment;
  @Input() postID: string;
  @Input() isSelected = false;
  @Input() showingAdmin = false;
  @Input() linkToPost = false;

  post: LegacyPost; // only set if this is showing admin

  showComments: boolean = false;
  allowLinksList: string[] = [];
  canPostLinks: boolean = false;
  fullComment: string;
  limitedComment: string;
  showMoreButton: boolean = false;

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.postID) {
      this.postID = this.comment.postID;
    }
    
    if (this.comment && this.comment.message) {
      this.allowLinksList = this.route.snapshot.data['postCommentsData']['allow_links_uids'];
      this.canPostLinks = this.allowLinksList.includes(this.comment.owner_user_id);
      
      const textContainsValidURL = this.canPostLinks && StringUtilities.textContainsValidURLMarkdown(this.comment.message);
      this.fullComment = textContainsValidURL ? StringUtilities.urlify(this.comment.message) : this.comment.message;
      this.limitedComment = this.fullComment;

      const max = 250;
      
      if (this.fullComment.length > max && !textContainsValidURL) {
        this.limitedComment = this.limitedComment.substring(0, max);
        this.showMoreButton = true;
      }

      if (this.showingAdmin) {
        this.postSvc.findPost(this.postID).subscribe(post => {
          this.post = post;
        });
      }
    }
  }

  userClicked() {
    this.router.navigate(['/profiles', this.comment.owner_user_id]);
  }

  expandComment() {
    this.limitedComment = this.fullComment;
    this.showMoreButton = false;
  }

  deleteComment() {
    if (this.postID == null) {
      console.log('no post id set to delete comment')
      return;
    }
    if (confirm('Are you sure to delete this comment?')) {
      this.commentSvc.deleteLegacyComment(this.postID, this.comment.$key);
    }
  }

  sendUserEmail() {
    if (this.postID == null) {
      return;
    }

    var imageURL = new MagicImagePipe().transform(this.post.image, 400);

    var data = {
      profileId: this.comment.owner_user_id,
      subject: 'Pigment Gallery - Your Comment',
      text: `Hi ${this.comment.owner_username},<br/><br/>Regarding your comment on post: <br/><a href="https://app.pigmentapp.co/posts/${this.postID}">https://app.pigmentapp.co/posts/${this.postID}<p><img src="${imageURL}"></p></a><br/><br/>${this.comment.message}`
    }

    EmailComposeComponent.openWindow(data);
  }
}
