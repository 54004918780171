import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ProfilesService } from '../../../shared/services/profiles.service';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { LegacyProfile } from '../../../shared/model/profile';


@Component({
  selector: 'app-user-blocked',
  templateUrl: './user-blocked.component.html',
  styleUrls: ['./user-blocked.component.scss']
})
export class UserBlockedComponent implements OnInit {
  profile: LegacyProfile;
  usersBlocked$: Observable<LegacyProfile[]>;


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private profileSvc: ProfilesService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.profile = this.data.profile;
    this.usersBlocked$ = this.profileSvc.getBlockedProfiles(this.profile.$key);

  }

  userClicked(userID: String) {
    this.dialog.closeAll();
    this.router.navigate(['./profiles', userID]);
  }

}
