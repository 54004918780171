import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { FirestoreDataSource } from 'app/admin/utils/firestore-data-source';
import { LoginService } from 'app/shared/services/login.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdminActivity } from '../../../model/admin-activity';
import { ActivityListRequest, PERM_VIEW_ADMIN_ACTIVITY } from '../activity.types';

@Component({
  selector: 'admin-activity-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class ActivityTableComponent implements OnInit, OnDestroy {
  @Output() activityClicked = new EventEmitter<AdminActivity>()

  @Input() source: FirestoreDataSource<ActivityListRequest, AdminActivity>
  @Input() selection: SelectionModel<AdminActivity>
  @Input() columns = ['date', 'type', 'description']
  @Input() sortColumns = ['date', 'type']

  @ViewChild('sort', { static: true }) sort: MatSort

  canViewGlobalActivity = false

  private unsubscribe = new Subject<void>()

  constructor(
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.loginService.hasPermission(PERM_VIEW_ADMIN_ACTIVITY)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: hasPermission => {
          this.canViewGlobalActivity = hasPermission
          if (hasPermission) {
            this.columns = ['date', 'user', 'type', 'description']
          } else {
            this.columns = ['date', 'type', 'description']
          }
        }
      })

    this.source.sort = this.sort
    for (const [, sortable] of this.sort.sortables.entries()) {
      if (this.sortColumns.indexOf(sortable.id) === -1) {
        (sortable as MatSortHeader).disabled = true
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

}
