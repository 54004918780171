import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { ProfilesService } from '../../../shared/services/profiles.service';

@Component({
  selector: 'app-username',
  template: `
    <ng-container *ngIf="userId; else noUser">
      <span [ngSwitch]="linkify">
        <a *ngSwitchCase="true" [routerLink]="['/', 'admin', 'profiles', userId]"><span class="at">@</span>{{ username$ | async }}</a>
        <span *ngSwitchCase="false"><span class="at">@</span>{{ username$ | async }}</span>
      </span>
    </ng-container>
    <ng-template #noUser>N/A (profile id could not be found)</ng-template>
  `,
})
export class UsernameComponent implements OnInit, OnChanges {
  @Input() userId: string
  @Input() linkify = true

  username$: Observable<string>

  private userId$ = new BehaviorSubject<string | undefined>(undefined)

  constructor(
    private profilesService: ProfilesService
  ) { }

  ngOnInit(): void {
    this.username$ = this.userId$.pipe(
      filter(id => id !== undefined),
      switchMap(id => this.profilesService.findProfile(id)),
      map(profile => profile ? profile.username : 'Unknown')
    )
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userId) {
      this.userId$.next(changes.userId.currentValue)
    }
  }
}
