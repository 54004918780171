export class Report {
  $key: string;
  report_date: Date;
  like: number;
  follow: number;
  comment: number;
  mention_comment: number;
  mention_post: number;
  user: number;
  post: number;
  au_comments_daily: number;
  au_comments_weekly: number;
  au_comments_monthly: number;
  au_posts_daily: number;
  au_posts_weekly: number;
  au_posts_monthly: number;
}
