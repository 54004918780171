import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { LoginService } from '../services/login.service'

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private loginSvc: LoginService, private router: Router) {}

  canActivate(): Observable<true | UrlTree> {
    return this.loginSvc.isAdmin.pipe(
      map((isAdmin) => isAdmin || this.router.parseUrl('/admin/login'))
    )
  }
}
