import { SelectionModel } from '@angular/cdk/collections';
import { Component, Directive, Input, OnInit } from '@angular/core';

@Directive({
  selector: `[appWithEmptySelectionToolbar]`,
})
export class WithEmptySelectionToolbarDirective {}

@Directive({
  selector: `[appWithSelectionToolbar]`,
})
export class WithSelectionToolbarDirective {}

@Component({
  selector: 'app-table-toolbar',
  template: `
    <mat-toolbar>
      <ng-container *ngIf="selection?.isEmpty(); else selectedActions">
        <ng-content select="[appWithEmptySelectionToolbar]"></ng-content>
      </ng-container>
      <ng-template #selectedActions>
        <ng-content select="[appWithSelectionToolbar]"></ng-content>
      </ng-template>
    </mat-toolbar>
  `,
  styles: [
    ':host ::ng-deep .spacer { flex: 1 1 auto; }'
  ]
})
export class TableToolbarComponent implements OnInit {

  @Input() selection: SelectionModel<any> = new SelectionModel<any>()

  constructor() { }

  ngOnInit(): void {
  }

}
