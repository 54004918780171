<div class="activity">
  <mat-card>
    <app-table-toolbar *ngIf="canViewGlobalActivity">

      <ng-container appWithEmptySelectionToolbar>
        <mat-chip-list>
          <mat-chip mat-button class="filter-picker-trigger" [matMenuTriggerFor]="filters">
            <mat-icon>filter_list</mat-icon> Filters
          </mat-chip>

          <mat-chip *ngFor="let type of (typeFilters$ | async)"
            removable="true"
            (removed)="toggleFilter(type)">
            {{ type }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngFor="let user of (userFilters$ | async)"
            removable="true"
            (removed)="toggleUserFilter(user)">
            <app-avatar-username-inline size="small" [user]="user"></app-avatar-username-inline>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <mat-menu #filters="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="users">User</button>
          <button mat-menu-item [matMenuTriggerFor]="types">Type</button>
        </mat-menu>

        <mat-menu #users="matMenu">
          <button mat-menu-item [disabled]="isUserSelected" *ngFor="let user of (users$ | async)"
            (click)="toggleUserFilter(user)">
            <app-avatar-username-inline size="small" [user]="user" [ngClass]="{ 'user-selected': isUserSelected }"></app-avatar-username-inline>
          </button>
        </mat-menu>
        <mat-menu #types="matMenu">
          <button mat-menu-item [disabled]="isTypeSelected(type.key)" *ngFor="let type of typesList | keyvalue: keyValOriginalOrder"
            (click)="toggleFilter(type.key)">{{ type.value }}
          </button>
        </mat-menu>
      </ng-container>
    </app-table-toolbar>

    <admin-activity-table
      #table
      class="table"
      [source]="dataSource"
      [selection]="selection">
    </admin-activity-table>

    <app-paginator #paginator [source]="dataSource" [selection]="selection" storageKey="activity"></app-paginator>
  </mat-card>
</div>
