import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection, DocumentData
} from '@angular/fire/compat/firestore';
import { Profile, ProfileStub } from 'app/shared/model/profile';
import { LoginService } from 'app/shared/services/login.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { from, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AdminActivity, AdminActivityType } from '../model/admin-activity';
import { CountersService } from './counters.service';

const Timestamp = firebase.firestore.Timestamp
const FieldValue = firebase.firestore.FieldValue

type Snapshot = firebase.firestore.DocumentSnapshot<DocumentData>

export const COLLECTION_NAME = 'admin_activity'

export const toFirestore = (activity: AdminActivity): DocumentData => {
  return {
    actor: activity.actor,
    type: activity.type,
    timestamp: activity.timestamp ? Timestamp.fromDate(activity.timestamp) : FieldValue.serverTimestamp(),
    data: activity.data,
  }
}

export const fromFirestore = (snapshot: Snapshot): AdminActivity | undefined => {
  if (!snapshot.exists) {
    return
  }

  const data = snapshot.data()
  return {
    id: snapshot.id,
    actor: Profile.createStub(data.actor),
    type: data.type,
    timestamp: data.timestamp.toDate(),
    data: data.data,
  }
}

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  private collection: AngularFirestoreCollection

  constructor(
    private firestore: AngularFirestore,
    private counters: CountersService,
    private loginService: LoginService,
  ) {
    this.collection = firestore.collection(COLLECTION_NAME)
  }

  createActivity(type: AdminActivityType, data: any): Observable<AdminActivity> {
    return this.loginService.user.pipe(
      take(1),
      map(user => ({
        actor: Profile.createStub({
          id: user.user.uid,
          username: user.user.displayName,
          image: user.user.photoURL,
        }),
        type: type,
        data: data,
      } as AdminActivity)),
      switchMap(activity => from(this.writeActivity(activity)))
    )
  }

  // Writes the activity record. If `activity.id` is undefined, this creates a new
  //  activity, otherwise it updates an existing one.
  async writeActivity(activity: AdminActivity): Promise<AdminActivity> {
    if (activity.id) {
      return this.collection.doc(activity.id)
        .set(toFirestore(activity), { merge: true })
        .then(() =>
          this.collection.doc(activity.id).get()
            .pipe(
              take(1),
              map(snapshot => fromFirestore(snapshot))
            )
            .toPromise()
        )
    } else {
      return this.collection.add(toFirestore(activity))
        .then((ref) => ref.get())
        .then((snapshot) => fromFirestore(snapshot))
    }
  }

  getActivity(id: string): Observable<AdminActivity | undefined> {
    return this.collection.doc(id).snapshotChanges().pipe(
      map(action => fromFirestore(action.payload))
    )
  }

  getActivityCount(actorId?: string): Observable<number> {
    if (actorId) {
      const counterRef = this.firestore
        .collection('profiles_meta')
        .doc(actorId)
        .collection('counters')
      return this.counters.getCount('admin_activity', counterRef)
    } else {
      return this.counters.getCount('admin_activity')
    }
  }

  getUsers(): Observable<ProfileStub[]> {
    return this.firestore
      .collection('counters')
      .doc('admin_activity')
      .collection('unique_users')
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(action => Profile.createStub(action.payload.doc.data()))
        )
      )
  }
}
