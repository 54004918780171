import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlagsService } from 'app/admin/services/flags.service';
import { LegacyPost } from 'app/shared/model/post';
import { CommentsService } from 'app/shared/services/comments.service';
import { PostsService } from 'app/shared/services/posts.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { Profile, ProfileStub } from '../../../shared/model/profile';
import { Flagged } from '../../model/flag';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit, OnDestroy {

  post$: Observable<LegacyPost>
  postOwner$: Observable<ProfileStub>
  commentCount$: Observable<number>
  flagged$: Observable<Flagged>
  wasRecommended = false

  private postId$: BehaviorSubject<string> = new BehaviorSubject<string>(null)
  private unsubscribe$: Subject<void> = new Subject<void>()

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private postsService: PostsService,
    private flagsService: FlagsService,
    private commentsService: CommentsService,
  ) { }

  ngOnInit(): void {

    this.route.paramMap
    .pipe(
      takeUntil(this.unsubscribe$),
      map(p => p.get('postId'))
    ).subscribe(this.postId$)

    this.post$ = this.postId$.pipe(
      switchMap(id => this.postsService.findPost(id)),
      shareReplay(1),
    )

    // create ProfileStub for post owner for use in avatar username inline component
    this.postOwner$ = this.post$.pipe(
      map(post => Profile.createStub({
        'id' : post.owner_id,
        'username' : post.owner_username,
        'image' : post.owner_thumb
      }))
    )

    this.commentCount$ = this.postId$.pipe(
      switchMap(postId => this.postsService.getCommentCount(postId)),
      shareReplay(1)
    )

    this.flagged$ = this.postId$.pipe(
      switchMap(id => this.flagsService.findFlagged(id)),
      shareReplay(1)
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  usernameClicked($event: MouseEvent, post: LegacyPost) {
    $event.stopPropagation()
    this.router.navigate(['./admin/profiles/', post.owner_id])
  }
}
