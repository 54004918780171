import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { DataSource } from 'app/admin/utils/firestore-data-source';
import { LegacyProfile } from 'app/shared/model/profile';
import { onClick } from '../../../utils/selectable-click-handler';

@Component({
  selector: 'admin-profiles-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class ProfilesTableComponent implements OnInit {
  @Output() profileClicked = new EventEmitter<LegacyProfile>()

  @Input() source: DataSource<LegacyProfile>
  @Input() selection: SelectionModel<LegacyProfile>
  @Input() columns = ['select', 'thumb', 'username', 'created_at', 'posts', 'follower_count', 'following']

  @ViewChild('sort', { static: true }) sort: MatSort

  constructor() { }

  ngOnInit(): void {
    this.source.sort = this.sort
  }

  onProfileClicked($event: MouseEvent, profile: LegacyProfile) {
    onClick($event, () => this.profileClicked.emit(profile))
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.source.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.source.data.forEach(row => this.selection.select(row))
  }
}
