import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private meta: Meta, private titleService: Title) { }

  generateTags(tags) {
    tags = {
      title: 'Pigment',
      description: 'A true coloring experience on iPhone, iPad and Android.',
      image: '/assets/img/logo.png',
      url: 'https://app.pigmentapp.co',
      image_width: '100',
      ...tags
    };

    const max_chars = 160;
    const post_description = tags.description.length > max_chars ? `${tags.description.substring(0, max_chars)}...` : tags.description;

    this.titleService.setTitle(tags.title);
    this.meta.updateTag({ name: 'description', content: post_description });

    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: '@pigment_app' });
    this.meta.updateTag({ name: 'twitter:title', content: tags.title });
    this.meta.updateTag({ name: 'twitter:description', content: post_description });
    this.meta.updateTag({ name: 'twitter:image', content: tags.image });
    this.meta.updateTag({ name: 'twitter:image:alt', content: post_description });

    this.meta.updateTag({ property: 'og:type', content: 'article' });
    this.meta.updateTag({ property: 'og:site_name', content: 'Pigment' });
    this.meta.updateTag({ property: 'og:title', content: tags.title});
    this.meta.updateTag({ property: 'og:description', content: post_description });
    this.meta.updateTag({ property: 'og:image', content: tags.image });
    this.meta.updateTag({ property: 'og:image:alt', content: post_description });
    // This needs to be in here for facebook to generate the image:
    //  https://developers.facebook.com/docs/sharing/best-practices/#precaching
    this.meta.updateTag({ property: 'og:image:width', content: tags.image_width});
    this.meta.updateTag({ property: 'og:url', content: tags.url });
    // Pigment's facebook app id
    this.meta.updateTag({ property: 'fb:app_id', content: '1658196424465589' });
  }
}
