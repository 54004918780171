<div mat-dialog-content class="user-blocked">

    <h2>{{profile.username}} is blocked by {{(usersBlocked$ | async)?.length || "No"}} users</h2>
    <div *ngIf="usersBlocked$ | async as users">

    <div *ngFor="let user of users" >
        <div class="profileInfo pointer" (click)="userClicked(user.$key)" cdkFocusInitial><a target="_blank"><img class="profileImage" src="{{ user.thumb | magicImage:100 }}" /></a>
          <div class="profileName">{{ user.username }}</div>
        </div>
    </div>
</div>
</div>