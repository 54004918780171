<ng-container *ngIf="(isModerator$ | async) && postId">
  <div class="container">
    <mat-form-field [floatLabel]="'never'">
      <textarea matInput type="text" [(ngModel)]="commentText" placeholder="Comment as Moderator..."></textarea>
    </mat-form-field>
    <button
      mat-mini-fab
      color="primary"
      [disabled]="!commentText || !commentText.length || (isLoading$ | async)"
      (click)="postComment()"
      matTooltip="Post Comment">
      <mat-icon>send</mat-icon>
    </button>
  </div>
</ng-container>
