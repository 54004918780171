import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'admin-empty-table',
  templateUrl: './empty-table.component.html',
  styleUrls: ['./empty-table.component.scss']
})
export class EmptyTableComponent implements OnInit {
  @Input() itemName: string

  constructor() { }

  ngOnInit(): void {
  }

}
