<table mat-table
  [dataSource]="source"
  multiTemplateDataRows
  matSort
  matSortActive="last_flagged"
  matSortDirection="desc"
  appPersistentTableState
  #sort="matSort">

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef>Image</th>
    <td mat-cell *matCellDef="let flagged">
      <div class="list-image">
        <img *ngIf="pluckContentImage(flagged); let image else placeholder" class="list-thumb" src="{{ image }}" />
        <mat-icon *ngIf="flagged.type === 'comment'">comment</mat-icon>
        <mat-icon class="deleted" *ngIf="flagged.deleted">delete</mat-icon>
      </div>
      <ng-template #placeholder>
        <img class="list-thumb" src="assets/img/profile_placeholder.jpg" />
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="user">
    <th mat-header-cell *matHeaderCellDef>User</th>
    <td mat-cell *matCellDef="let flagged">
      <ng-container *ngIf="pluckContentOwner(flagged); let owner else anonymous">
        <a [routerLink]="['/','admin','profiles',owner.id]">
          <app-avatar-username-inline [user]="owner" size="small"></app-avatar-username-inline>
        </a>
      </ng-container>
      <ng-template #anonymous>
        <app-avatar-username-inline [user]="{ username: 'Anonymous User' }" size="small"></app-avatar-username-inline>
      </ng-template>
    </td>
  </ng-container>

  <ng-container matColumnDef="description">
    <th mat-header-cell *matHeaderCellDef>Description</th>
    <td mat-cell *matCellDef="let flagged">{{ pluckContentDescription(flagged) }}</td>
  </ng-container>

  <ng-container matColumnDef="last_flagged">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="last_flagged">Date</th>
    <td mat-cell *matCellDef="let flagged"> {{ flagged.lastFlagged | date: 'short' }} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status" [disabled]="!canSortStatus">Status</th>
    <td mat-cell *matCellDef="let flagged"> {{ flagged.status }} </td>
  </ng-container>

  <ng-container matColumnDef="last_reason">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="last_reason">Reason</th>
    <td mat-cell *matCellDef="let flagged">
      <mat-select [ngModel]="flagged.lastReason" name="ddl_flag_reasons" placeholder="Choose Flag Reason" (ngModelChange)="onChangeFlag($event, flagged)">
        <mat-option *ngFor="let flagReason of flagReasons" [disabled]="!flagReason.isActive" [value]="flagReason.value">{{ flagReason.value }}</mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="flag_count">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="flag_count">Flags</th>
    <td mat-cell *matCellDef="let flagged"> {{ flagged.count }} </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Actions</th>
    <td mat-cell *matCellDef="let flagged">
      <button mat-icon-button matTooltip="View Content" aria-label="View content" (click)="onView($event, flagged)">
        <mat-icon>visibility</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Resolve All Flags"
        aria-label="Resolve all flags"
        [disabled]="flagged.status === 'resolved'"
        (click)="onResolve($event, flagged)">
        <mat-icon>done</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Contact User"
        aria-label="Contact user about flagged content"
        (click)="onContact($event, flagged)">
        <mat-icon>email</mat-icon>
      </button>
      <button
        mat-icon-button
        matTooltip="Delete Content"
        aria-label="Delete flagged content"
        [disabled]="!!flagged.deleted"
        (click)="onDelete($event, flagged)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded flags column - The expanded row is made up of this one row that spans across all columns -->
  <ng-container matColumnDef="expandedFlags">
    <td mat-cell *matCellDef="let flagged" [attr.colspan]="columns.length">
      <admin-child-flags-table
        [@detailExpand]="flagged.id === expandedId ? 'expanded' : 'collapsed'"
        [flaggedId]="flagged.id">
      </admin-child-flags-table>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columns" class="flag-header-row"></tr>
  <tr mat-row
    *matRowDef="let flagged; columns: columns;"
    class="flag-row"
    [class.expanded-flag-row]="expandedId === flagged.id"
    (click)="expandedId = expandedId === flagged.id ? null : flagged.id">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedFlags']" class="child-flags-row"></tr>
</table>
