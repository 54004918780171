import {flatMap, switchMap, take} from 'rxjs/operators';
import {Component, OnChanges, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {LoginService} from '../../services/login.service';
import {LegacyPost} from '../../model/post';
import {PostsService} from '../../services/posts.service';
import {LegacyProfile} from '../../model/profile';
import {ProfilesService} from '../../services/profiles.service';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: [
    './profile-detail.component.scss',
    '../gallery-page/gallery-page.component.scss'
  ]
})
export class ProfileDetailComponent implements OnInit, OnChanges {

  profile$: Observable<LegacyProfile>;
  posts: LegacyPost[];
  postSubscription: Subscription;

  deletedPosts$: Observable<LegacyPost[]>;

  validAdmin = false;
  limit = 24;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private loginSvc: LoginService,
              private profileService: ProfilesService,
              private postService: PostsService) {
  }

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.limit = 24;
    this.profile$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.profileService.findProfile(params.get('userId')))
    )

    this.profile$.subscribe(profile => {
      this.posts = [];
      this.getPosts();

      // track if we're an admin
      this.loginSvc.isAdmin.subscribe((isAdmin: boolean) => {
        this.validAdmin = isAdmin;

        if (isAdmin) {
          this.deletedPosts$ = this.profile$.pipe(
            flatMap((profile: LegacyProfile) => this.postService.findDeletedPostsByUserId(profile.$key)),
            take(1)
          )
        }
      });
    });
  }

  getPosts() {
    if (this.postSubscription != null) {
      this.postSubscription.unsubscribe();
    }

    const posts$ = this.profile$.pipe(
      flatMap((profile: LegacyProfile) => this.postService.findPostsByUserId(profile.$key, this.limit))
    );

    this.postSubscription = posts$.subscribe(posts => {

      // valid admin's get full updates
      if (this.validAdmin) {
        this.posts = posts.filter(post => post.image != null);
        return;
      }

      // this stops the post from going to zero for a split second causing the scroll to break
      if (posts.length > 0 || this.posts.length === 0) {
        this.posts = posts.filter(post => post.image != null);
      }

      // update posts and unsubscribe once we've gotten a reasonable number (gets around cached posts coming back first)
      if (posts.length >= this.limit / 2) {
        if (this.postSubscription != null) {
          this.postSubscription.unsubscribe();
        }
      }
    })

  }

  manageProfile(profile: LegacyProfile) {
    this.router.navigate(['/admin/profiles', profile.$key]);
  }

  showMore() {
    this.limit = this.limit + 100;
    this.getPosts();
  }

  postSelected(post: LegacyPost) {
    this.postService.currentDialogPost = post;
    this.router.navigate(['/posts', post.$key]);
  }
}
