import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivityService } from 'app/admin/services/activity.service';
import { Profile, ProfileStub } from 'app/shared/model/profile';
import { LoginService } from 'app/shared/services/login.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AdminActivity } from '../../model/admin-activity';
import { ActivityDataSource } from './activity.datasource';
import { PERM_VIEW_ADMIN_ACTIVITY } from './activity.types';
import { ActivityTableComponent } from './table/table.component';


@Component({
  selector: 'admin-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  providers: [ActivityDataSource]
})

export class ActivityComponent implements OnInit, OnDestroy {

  typeFilters$ = new BehaviorSubject<string[]>([]);
  userFilters$ = new BehaviorSubject<ProfileStub[]>([]);
  selection = new SelectionModel<AdminActivity>(true, []);

  users$ = new BehaviorSubject<ProfileStub[]>([]);

  isUserSelected: boolean = false;
  selectedTypes: string[] = [];

  typesList: { [key: string]: string } = {
    'email': 'Email',
    'comment': 'Comment',
    'update-note': 'Note Updated',
    'create-note': 'Note Created',
    'resolve-flag': 'Resolved Flag',
    'delete-flag': 'Deleted Flag',
    'resolve-flagged-item': 'Resolved Flag Content',
    'update-flagged-item': 'Updated Flag Content', 
    'delete-flagged-item': 'Deleted Flag Content',
    'disable-user': 'Disabled User',
    'enable-user': 'Enabled User',
    'disable-device': 'Disabled Device',
    'enable-device': 'Enabled Device',
  };
 
  @ViewChild('table', { static: true }) table: ActivityTableComponent;

  canViewGlobalActivity = false;

  private unsubscribe = new Subject<void>();

  constructor(
    private loginService: LoginService,
    private activityService: ActivityService,
    public dataSource: ActivityDataSource,
  ) { }

  ngOnInit(): void {
    this.loginService.hasPermission(PERM_VIEW_ADMIN_ACTIVITY)
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(hasPermission => {
          // Limit the activity list to the current user if they can't see other activity.
          if (!hasPermission) {
            return this.loginService.user.pipe(
              tap(user => this.userFilters$.next([Profile.createStub({
                id: user.user.uid,
                username: user.user.displayName,
                image: user.user.photoURL,
              })])),
              map(() => hasPermission)
            );
          } else {
            return of(hasPermission);
          }
        })
      )
      .subscribe({
        next: hasPermission => {
          this.canViewGlobalActivity = hasPermission;
        }
      })

    this.activityService.getUsers().pipe(takeUntil(this.unsubscribe)).subscribe(this.users$);

    this.typeFilters$.pipe(takeUntil(this.unsubscribe)).subscribe(this.dataSource.types$);
    this.userFilters$.pipe(
      takeUntil(this.unsubscribe),
      map(users => users.map(user => user.id))
    ).subscribe(this.dataSource.users$);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  toggleFilter(name: string) {
    const filters = this.typeFilters$.value;
    const index = filters.indexOf(name);
    if (index !== -1) {
      filters.splice(index, 1);
      this.selectedTypes.splice(index, 1);
    } else {
      filters.push(name);
      this.selectedTypes.push(name);
    }
    this.typeFilters$.next(filters);
  }

  toggleUserFilter(user: ProfileStub) {
    const filters = this.userFilters$.value;
    const index = filters.indexOf(user);
    if (index !== -1) {
      filters.splice(index, 1);
      this.isUserSelected = false;
    } else {
      filters.push(user);
      this.isUserSelected = true;
    }
    this.userFilters$.next(filters);
  }

  isTypeSelected(typeName: string): boolean {
    return this.selectedTypes.includes(typeName);
  }

  keyValOriginalOrder() {
    // By default, the keyvalue pipe in our html component
    // sorts the keys alphabetically. This function is used to
    // maintain the original order of the keys.
    return 0;
  }
}
