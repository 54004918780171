import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-profile-image',
  template: `
    <img src="{{ imageUrl || '/assets/img/profile_placeholder.jpg' }}">
  `,
  styles: [
    `img {
      border-radius: 50%;
      max-width: 100%;
      max-height: 100%;
    }`
  ]
})
export class ProfileImageComponent {

  @Input() imageUrl?: string

  constructor() { }

}
