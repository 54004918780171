  <div class="email-compose">
  <h2>Compose Email to {{ profile?.username }}</h2>
  <app-alert-message [successMsg]="emailSuccessMsg"></app-alert-message>
  <form class="example-form">
    <mat-form-field class="email-full-width">
      <input matInput placeholder="Subject" [(ngModel)]="subject" name="subject">
    </mat-form-field>
    <quill-editor class="email-text" placeholder="Email Text" [(ngModel)]="text" name="text" [style]="{height: '400px'}"></quill-editor>
  </form>
  <br/>
  <button mat-raised-button color="primary" (click)="sendPressed()">Send</button>
  <button mat-raised-button color="secondary" (click)="closeWindow()">Close</button>
</div>
