<div (click)="selected.emit(post)">
  <img class="post-thumb" src="{{post.image | magicImage:320:true}}"/>

  <div class="hoverThumb">
    <div class="centeredContainer">

      <img class="profileImage" src="{{ post.owner_thumb | magicImage:100 }}"
           onError="this.src='/assets/img/profile_placeholder.jpg';"/>
      <div class="profileName">{{ post.owner_username }}</div>
      <p class="counts">
        <img class="hoverIcon" src="/assets/img/comments_icon_white.png" alt="comments"/>{{post.comment_count || "0"}}
        &nbsp;
        <img class="hoverIcon" src="/assets/img/likes_icon_white.png" alt="likes"/>{{post.like_count || "0"}}
      </p>
    </div>
  </div>
</div>
