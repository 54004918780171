import { Component, OnInit } from '@angular/core';
import { OsService } from '../../services/os.service';


@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent implements OnInit {

  os:string;

  constructor(private osSvc: OsService) { }

  ngOnInit() {
    this.os = this.osSvc.os;
  }
}

